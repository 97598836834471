"use client";

import styled from "@emotion/styled";

export const VideoElement = styled.video`
  height: auto;
  max-width: 100%;
`;

export const ErrorContainer = styled.div``;
