"use client";

import styled from "@emotion/styled";
import { rem } from "polished";

import { device, sizes } from "../../../design-tokens/dimensions";
import { mobileNavBreakpoint } from "../styles";

const asideWidth = 472;

export const Root = styled.div`
  backdrop-filter: blur(30px);
  order: 1;
  overflow: hidden;
  width: 100%;

  @media (${device[mobileNavBreakpoint]}) {
    order: unset;
    width: ${rem(asideWidth)};
  }
`;

export const Wrapper = styled.div`
  padding: ${sizes.s36.rem};
  position: relative;
`;
