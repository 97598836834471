"use client";

import styled from "@emotion/styled";

import { Image } from "../../../atoms/Image";

export const Root = styled(Image)`
  object-fit: cover;
  width: 100%;
`;
