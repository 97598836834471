"use client";

import styled from "@emotion/styled";

import { colors } from "../../design-tokens/colors";
import { sizes } from "../../design-tokens/dimensions";
import { borderRadii } from "../../design-tokens/effects";
import { typography } from "../../design-tokens/typography";
import { zIndex } from "../../design-tokens/z-index";

export const Content = styled.div`
  background: ${colors.betaBase};
  border-radius: ${borderRadii.r8};
  color: ${colors.white};
  font-size: ${typography.sizes["14"]};
  justify-content: center;
  line-height: ${typography.lineHeights["20"]};
  padding: ${sizes.s16.rem};
  pointer-events: none;
  position: relative;
  width: max-content;
  z-index: ${zIndex.tooltip};
`;

export const arrowSize = sizes.s16;
export const Arrow = styled.div`
  background: ${colors.betaBase};
  height: ${arrowSize.px};
  position: absolute;
  transform: rotate(45deg);
  width: ${arrowSize.px};
`;
