import type { CSSProperties, ElementType, HTMLAttributes } from "react";

import type { ColorValue } from "../../design-tokens/colors";
import { HeadingLevels, headings } from "./styles";

export type HeadingProps = {
  /**
   * Visual style to use.
   */
  level?: HeadingLevels;
  /**
   * Markup to generate (independent of the visual style).
   */
  as?: HeadingLevels | "div" | "p" | "span";
} & StyledProps &
  HTMLAttributes<HTMLHeadingElement>;

export type StyledProps = {
  /**
   * Number of lines the text is restricted to. See {@link @jagex-pp/jds#lineClamp}.
   */
  lines?: number;
  /**
   * The font-color of the text. See {@link @jagex-pp/jds#colors}.
   */
  color?: ColorValue;
  /**
   * Sets the alignment of the Heading within the element.
   */
  align?: CSSProperties["textAlign"];
} & HTMLAttributes<HTMLHeadingElement>;

/**
 * A text heading that can be set to one of three levels.
 */
export function Heading({
  level = "h1",
  as: outputAs = level,
  lines,
  color,
  align,
  children,
  ...rest
}: HeadingProps): JSX.Element {
  const Root = headings[level];

  return (
    <Root
      data-testid={Heading.name}
      as={outputAs as keyof ElementType}
      lines={lines}
      color={color}
      align={align}
      {...rest}
    >
      {children}
    </Root>
  );
}
