{
  "footer": {
    "contact": "Contate-nos",
    "gender": "Relatório de disparidade salarial",
    "legal": "Jurídico",
    "do-not-sell": {
      "text": "Não venda ou compartilhe minhas informações pessoais",
      "title": "A Jagex não vende informações pessoais da forma como o termo é comumente compreendido. Nós permitimos que provedores de serviços usem as suas informações pessoais para fins publicitários, analíticos e de marketing. Clique neste link para acessar a nossa Política de Privacidade e saber mais sobre como não participar."
    },
    "terms": "Termos e Condições",
    "privacy": "Política de Privacidade",
    "cookie": "Preferências de Cookie",
    "copyright": "Copyright © 1999 - {{year}} Jagex Ltd. 220 Science Park, Cambridge, CB4 0WA, Reino Unido",
    "logo-nav": "Sites de empresas",
    "logo-title": "Jagex corporate website",
    "contact-title": "Contact Jagex",
    "gender-title": "Gender Pay Gap Report",
    "legal-title": "View Jagex's legal information",
    "terms-title": "View Jagex's terms & conditions",
    "privacy-title": "View Jagex's privacy policy",
    "cookie-title": "View Jagex's cookie policy"
  }
}
