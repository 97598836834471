import bagueSansBlack from "./assets/bague-sans/PFBagueSansPro-Black.woff2";
import bagueSansBold from "./assets/bague-sans/PFBagueSansPro-Bold.woff2";
import bagueSansItalic from "./assets/bague-sans/PFBagueSansPro-Italic.woff2";
import bagueSansRegular from "./assets/bague-sans/PFBagueSansPro-Regular.woff2";
import bagueSansThin from "./assets/bague-sans/PFBagueSansPro-Thin.woff2";

const bagueSansName = "BagueSans";

/**
 * @internal fallback fonts definition, which use font-face descriptors to reduce cumulative layout shift during render.
 */
export const fallbackFonts = `${bagueSansName}, Helvetica, Arial, sans-serif` as const;

/**
 * @internal definition of font setup, used during global style setup only.
 */
export const fonts = `
  @font-face {
    font-family: '${bagueSansName}';
    src: url(${bagueSansRegular}) format('woff2');
    font-weight: 500;
    font-style: normal;
    ascent-override: 80%;
    descent-override: 20%;
  }

  @font-face {
    font-family: '${bagueSansName}';
    src: url(${bagueSansItalic}) format('woff2');
    font-weight: 500;
    font-style: italic;
    ascent-override: 80%;
    descent-override: 20%;
  }

  @font-face {
    font-family: '${bagueSansName}';
    src: url(${bagueSansThin}) format('woff2');
    font-weight: 100;
    font-style: normal;
    ascent-override: 80%;
    descent-override: 20%;
  }

  @font-face {
    font-family: '${bagueSansName}';
    src: url(${bagueSansBold}) format('woff2');
    font-weight: bold;
    font-style: normal;
    ascent-override: 80%;
    descent-override: 20%;
  }

  @font-face {
    font-family: '${bagueSansName}';
    src: url(${bagueSansBlack}) format('woff2');
    font-weight: 900;
    font-style: normal;
    ascent-override: 80%;
    descent-override: 20%;
  }
  
  body {
    font-family: ${fallbackFonts};
  }
` as const;

export const bagueSansFonts = {
  bagueSansBlack,
  bagueSansBold,
  bagueSansItalic,
  bagueSansRegular,
  bagueSansThin,
};
