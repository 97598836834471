import type { HTMLAttributes } from "react";

import { AccordionEntry } from "./AccordionEntry/AccordionEntry";
import { Root } from "./styles";

type AccordionProps = HTMLAttributes<HTMLDivElement>;

/**
 * An expandable box to show more content related to the summary
 */
export function Accordion({ children, ...rest }: AccordionProps): JSX.Element {
  return (
    <Root data-testid={Accordion.name} {...rest}>
      {children}
    </Root>
  );
}

Accordion.Entry = AccordionEntry;
