import { rem } from "polished";
import type { HTMLAttributes, KeyboardEvent, MouseEvent } from "react";
import { useCallback, useEffect, useRef, useState } from "react";

import { Icon, IconProps } from "../../../atoms/Icon";
import { animationDurationJs, curves } from "../../../design-tokens/animations";
import { Content, ContentInner, PrefixIcon, Root, Summary, SummaryText } from "./styles";

type AccordionProps = HTMLAttributes<HTMLDetailsElement> & {
  heading: string;
  icon?: IconProps["icon"];
  isOpen?: boolean;
  onSummaryClick?: () => void;
};

const summaryArrowAnim = {
  transform: ["rotate(0deg)", "rotate(180deg)"],
};

const defaultAnimationOptions: KeyframeAnimationOptions = {
  duration: animationDurationJs(300),
  easing: curves.easeBoth,
  fill: "forwards",
};

/**
 * Hook for storing previous values of state
 * @param value - the value to store
 */
const usePrevious = (value: boolean) => {
  const ref = useRef<boolean>();
  // Store current value in ref
  useEffect(() => {
    ref.current = value;
  }, [value]); // Only re-run if value changes
  // Return previous value (happens before update in useEffect above)
  return ref.current;
};

/**
 * An expandable box to show more content related to the summary
 */
export function AccordionEntry({
  heading,
  icon,
  isOpen = false,
  onSummaryClick,
  children,
  ...rest
}: AccordionProps): JSX.Element {
  const [isOpenState, setIsOpenState] = useState(isOpen);
  const previousIsOpen = usePrevious(isOpen);
  const [contentHeight, setContentHeight] = useState(-1);
  const outerContentRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);
  const iconRef = useRef<HTMLDivElement>(null);

  /**
   * Shrink the content, rotate the arrow icon and then close the details element
   */
  const closeEntry = useCallback(() => {
    /* istanbul ignore else  */
    if (outerContentRef.current && iconRef.current && iconRef.current.animate) {
      const closeAnim = outerContentRef.current.animate(
        {
          height: [rem(contentHeight), rem(0)],
        },
        defaultAnimationOptions
      );
      closeAnim.onfinish = () => {
        setIsOpenState(false);
        closeAnim.cancel();
      };
      iconRef.current.animate(summaryArrowAnim, {
        ...defaultAnimationOptions,
        direction: "reverse",
      });
    } else {
      setIsOpenState(false);
    }
  }, [contentHeight]);

  /**
   * Open the details element, expand the content then rotate the arrow icon
   */
  const openEntry = useCallback(() => {
    setIsOpenState(true);
    if (outerContentRef.current && iconRef.current && iconRef.current.animate) {
      const openAnim = outerContentRef.current.animate(
        {
          height: [rem(0), rem(contentHeight)],
        },
        defaultAnimationOptions
      );
      // Clear the keyframes to allow the height to grow if the children are changed
      openAnim.onfinish = () => openAnim.cancel();
      iconRef.current.animate(summaryArrowAnim, defaultAnimationOptions);
    }
  }, [contentHeight]);

  const handleSummaryClick = (event: MouseEvent<HTMLElement> | KeyboardEvent<HTMLElement>) => {
    event.preventDefault();
    if (isOpenState) {
      closeEntry();
    } else {
      openEntry();
    }

    if (onSummaryClick) {
      onSummaryClick();
    }
  };

  /**
   * Handles parent components controlling the state via prop changes
   */
  useEffect(() => {
    if (isOpen === previousIsOpen) {
      return;
    }
    if (isOpen) {
      openEntry();
    } else {
      closeEntry();
    }
  }, [isOpen, openEntry, closeEntry, previousIsOpen]);

  /**
   * Handle measuring the distance to animate and listening for screen resizes
   */
  useEffect(() => {
    const setHeight = () => {
      if (contentRef.current) {
        setContentHeight(contentRef.current.offsetHeight);

        if (outerContentRef.current && outerContentRef.current.animate) {
          const instantOpenAnimation = outerContentRef.current.animate(
            { height: [isOpenState ? rem(contentRef.current.offsetHeight) : "0"] },
            { ...defaultAnimationOptions, duration: 0 }
          );
          instantOpenAnimation.onfinish = () => instantOpenAnimation.cancel();
        }
      }
    };

    // Only set this if we do not already have it
    if (contentHeight === -1) {
      setHeight();

      // If the Entry starts open instantly rotate the icon
      if (isOpenState && iconRef.current && iconRef.current.animate) {
        iconRef.current.animate(summaryArrowAnim, {
          ...defaultAnimationOptions,
          duration: 0,
        });
      }
    }
    if (typeof window !== "undefined") {
      window.addEventListener("resize", setHeight);
    }
  }, [isOpenState, contentHeight]);

  return (
    <Root data-testid={AccordionEntry.name} open={isOpenState} {...rest}>
      <Summary
        aria-expanded={isOpenState}
        onClick={(event) => handleSummaryClick(event)}
        onKeyDown={(event) => {
          switch (event.key) {
            case "Escape":
              if (isOpenState) {
                event.preventDefault();
                closeEntry();

                if (onSummaryClick) {
                  onSummaryClick();
                }
              }
              break;
            case "Enter":
              handleSummaryClick(event);
              break;
            default:
              break;
          }
        }}
        data-testid={Summary.displayName}
      >
        {icon && <PrefixIcon icon={icon} />}
        <SummaryText>{heading}</SummaryText>
        <Icon icon={"arrow-down"} ref={iconRef} />
      </Summary>
      <Content ref={outerContentRef} isOpen={isOpenState}>
        <ContentInner ref={contentRef}>{children}</ContentInner>
      </Content>
    </Root>
  );
}

Summary.displayName = `${AccordionEntry.name}Summary`;
