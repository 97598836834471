import type { HTMLAttributes } from "react";
import React from "react";

import { useTranslate } from "../../../utils/translate";
import { logos } from "../../design-tokens/logos";
import { Root } from "./styles";
import { translations } from "./translations";

export type LogoProps = {
  /**
   * Logo name from the allowed list.
   */
  logo: keyof typeof logos;
  /**
   * Height of the logo
   */
  height?: number;
  /**
   * Width of the logo
   */
  width?: number;
} & HTMLAttributes<HTMLImageElement>;

/**
 * Renders a company/product logo.
 */
export function Logo({ logo, ...rest }: LogoProps): JSX.Element {
  const t = useTranslate(translations);
  const { alt, src, title } = logos[logo];

  return <Root data-testid={Logo.name} title={t(title)} alt={t(alt)} src={src} {...rest} />;
}
