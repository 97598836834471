"use client";
import { createContext, Dispatch, ReactNode, SetStateAction, useContext, useState } from "react";

import type { Locale } from "../locales/types";
import { GlobalStyle } from "./GlobalStyle";

type JdsContext = {
  locale: Locale;
  setLocale: Dispatch<SetStateAction<Locale>>;
  domainAllowlist: string[];
};

type JdsProviderProps = {
  /**
   * Locale to use for initial JDS render
   */
  defaultLocale?: Locale;
  /**
   * Whether the {@link @jagex-pp/jds#GlobalStyle} component should be applied by the provider. You
   * should not disable this unless you use the global styles component manually.
   */
  withGlobalStyles?: boolean;
  /**
   * Whether the default BagueSans fonts should be applied. If you need to override fonts,
   * disable this so that the default fonts are not loaded.
   */
  withFonts?: boolean;
} & { children: ReactNode };

export const defaultDomainAllowlist = [
  "gamepires.com",
  "runefest.com",
  "*.runefest.com",
  "runescape.com",
  "*.runescape.com",
  "jagex.com",
  "*.jagex.com",
  "playspacepunks.com",
  "*.playspacepunks.com",
];

// Prepare the underlying context, but don't export this directly
const JdsContext = createContext<JdsContext | undefined>(undefined);

/**
 * Context provider which must wrap any use of JDS.
 *
 * Applies the required global styles, and also gives access to locale and other globals.
 * The component that uses this JdsProvider does not need to be marked as a client component
 * as the "use client" directive has been applied to this file. This will allow server components
 * to be rendered within the wrapped components.
 */
function JdsProvider({
  children,
  defaultLocale = "en-GB",
  withGlobalStyles = true,
  withFonts = true,
}: JdsProviderProps): JSX.Element {
  const [locale, setLocale] = useState(defaultLocale);

  return (
    <JdsContext.Provider value={{ locale, setLocale, domainAllowlist: defaultDomainAllowlist }}>
      {withGlobalStyles && <GlobalStyle withFonts={withFonts} />}
      {children}
    </JdsContext.Provider>
  );
}

/**
 * Safely access JDS context. Used internally, but can also be used in your own
 * application code (for example, to update the locale at runtime).
 *
 * Must be used within a {@link @jagex-pp/jds#JdsProvider}.
 */
function useJds(): JdsContext {
  const context = useContext(JdsContext);
  if (context === undefined) {
    throw new Error("JDS components can only be used within a JdsProvider");
  }

  return context;
}

export { JdsProvider, useJds };
