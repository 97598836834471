import type { SelectHTMLAttributes } from "react";
import { forwardRef } from "react";

import { Option } from "./Option";
import { Spacer } from "./Spacer";
import { LabelText, OuterContainer, SelectContainer, SelectElement } from "./styles";

/**
 * Defines the props for the `Select` component.
 */
export type SelectProps = Omit<SelectHTMLAttributes<HTMLSelectElement>, "multiple"> & {
  /**
   * The label for the select.
   */
  label: string;
  /**
   * The selected value for the `Select`
   */
  value?: string;
  /**
   * Whether the `Select` should take up the full width of its container
   */
  fullWidth?: boolean;
  /**
   * Whether the current entry is in an invalid state.
   */
  isInvalid?: boolean;
};

type SelectComponentType = React.ForwardRefExoticComponent<SelectProps> & {
  Option: typeof Option;
  Spacer: typeof Spacer;
};

export const Select = forwardRef<HTMLSelectElement, SelectProps>(function Select(
  {
    label,
    value,
    fullWidth,
    isInvalid = false,
    disabled = false,
    required = false,
    children,
    ...rest
  },
  ref
): JSX.Element {
  return (
    <OuterContainer fullWidth={fullWidth} data-testid={`${Select.name}-Container`}>
      <LabelText data-testid={`${Select.name}-LabelText`} isInvalid={isInvalid}>
        {label}
      </LabelText>
      <SelectContainer>
        <SelectElement
          data-testid={Select.name}
          value={value}
          aria-invalid={isInvalid}
          aria-disabled={disabled}
          disabled={disabled}
          required={required}
          aria-required={required}
          ref={ref}
          {...rest}
        >
          {children}
        </SelectElement>
      </SelectContainer>
    </OuterContainer>
  );
}) as SelectComponentType;

Select.Option = Option;
Select.Spacer = Spacer;
