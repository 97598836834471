import { defaultDomainAllowlist } from "../../global/jds-context";

/**
 * Checks if a URL matches a URL pattern.
 *
 * @param urlToCheck - URL that needs to be validated
 * @param pattern - URL pattern that the URL is checked against
 */
const isWithinDomain = (urlToCheck: string, pattern: string): boolean => {
  const { hostname } = new URL(urlToCheck);

  const hostnameParts = hostname.split(".");
  const patternParts = pattern.split(".");

  const patternLength = patternParts.length - 1;
  const hostnameLength = hostnameParts.length - 1;

  // Fail early if there are more parts in the pattern than in the hostname.
  // If there are no wildcards in the pattern, the lengths must be exactly the same.
  // And if there is a wildcard in the pattern, it can only match a longer hostname, e.g.
  // *.example.com can only match sub.example.com and sub.domain.example.com, but not just "com" and so on
  if (hostnameLength < patternLength) {
    return false;
  }

  // Verify the parts of the URL starting with the end
  let index = 0;
  while (index <= patternParts.length) {
    const currentPatternPart = patternParts[patternLength - index];

    // Wildcard matches everything, but it can only be in the beginning of the pattern
    if (currentPatternPart === "*") {
      if (patternLength - index !== 0) {
        throw new Error(
          "Wildcards can only be used in the beginning of the pattern (e.g. *.example.com)"
        );
      }

      return true;
    }

    // If there is no wildcard, check that the part of the URL exactly matches the pattern
    if (currentPatternPart !== hostnameParts[hostnameLength - index]) {
      return false;
    }
    index++;
  }

  return true;
};

/**
 * Validate that the URL is matching at least one of the allowed domains.
 * Relative URLs are always allowed.
 *
 * @param url - URL to verify
 * @param domainAllowlist - a list of allowed domains
 */
const isUrlAllowed = (url: string, domainAllowlist: string[]) => {
  // source: https://stackoverflow.com/questions/10687099/how-to-test-if-a-url-string-is-absolute-or-relative
  // eslint-disable-next-line security/detect-unsafe-regex
  const isAbsoluteUrl = /^(?:[a-z+]+:)?\/\//i.test(url);
  const isLauncherLink = url.startsWith("jagex:");

  if (!isAbsoluteUrl || isLauncherLink) {
    // Relative URLs and launcher links are always allowed
    return true;
  }

  // Find if at least some allowed domains match the URL
  return domainAllowlist.some((pattern) => isWithinDomain(url, pattern));
};

/**
 * Validate that the URL matches a jagex scheme.
 * Relative URLs are always allowed.
 *
 * @param url - URL to verify
 */
const isJagexURL = (url: string) => {
  return isUrlAllowed(url, defaultDomainAllowlist);
};
export { isJagexURL, isUrlAllowed, isWithinDomain };
