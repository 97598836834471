import { AnchorHTMLAttributes, forwardRef, ReactNode, Ref } from "react";

import type { ButtonVariant } from "../Button/Button";
import { PositiveLinkButton, PrimaryLinkButton, SecondaryLinkButton, TextLink } from "./styles";

export type LinkVariant = Exclude<ButtonVariant, "tertiary">;

export type LinkSize = "m" | "l";

export type LinkProps = {
  /**
   * Style variation of the link.
   */
  variant?: LinkVariant;
  /**
   * Size variant to use (does not alter inline Link style).
   */
  entitySize?: LinkSize;
  /**
   * URL destination of the link.
   */
  href: string;
  /**
   * Icon to put before the text.
   */
  before?: ReactNode;
  /**
   * Icon to put after the text.
   */
  after?: ReactNode;
  /**
   * Non-standard attribute indicating that the link is disabled.
   */
  disabled?: boolean;
} & AnchorHTMLAttributes<HTMLAnchorElement>;

/**
 * Shared base for the Link Component.
 */
export const Base = forwardRef(
  (
    { href, variant = "inline", entitySize = "m", before, after, children, ...rest }: LinkProps,
    ref: Ref<HTMLAnchorElement>
  ): JSX.Element => {
    const Root = {
      primary: PrimaryLinkButton,
      positive: PositiveLinkButton,
      secondary: SecondaryLinkButton,
      inline: TextLink,
    }[variant];

    return (
      <Root entitySize={entitySize} href={href} ref={ref} {...rest}>
        {before}
        {children}
        {after}
      </Root>
    );
  }
);

Base.displayName = "Base";
