import type { HTMLAttributes } from "react";

import { Icon } from "../../../atoms/Icon";
import { sizes } from "../../../design-tokens/dimensions";
import { Root } from "./styles";

type CloseButtonProps = HTMLAttributes<HTMLButtonElement> & {
  /**
   * Function to call when the modal is closed.
   */
  onClose: () => void;
};

export const closeButtonWidth = sizes.s16.rem;

/**
 * Renders the close icon for a {@link Modal} interface.
 */
export function CloseButton({ onClose, ...rest }: CloseButtonProps): JSX.Element {
  return (
    <Root
      onClick={onClose}
      variant="inline"
      data-testid={`Modal${CloseButton.name}`}
      before={<Icon icon="close" height={sizes.s16.rem} />}
      {...rest}
    />
  );
}
