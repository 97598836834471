"use client";

import { css, keyframes } from "@emotion/react";
import styled from "@emotion/styled";
import { rem, rgba } from "polished";

import { colors } from "../../design-tokens/colors";
import { sizes } from "../../design-tokens/dimensions";
import type { LoaderVariant } from "./Loader";

const trackWidth = sizes.s8.rem;
const trackLength = 125;
export const baseSize = sizes.s48.raw;
const loaderColors: Record<LoaderVariant, { track: string; train: string }> = {
  blue: { track: colors.blue, train: colors.white },
  "semi-transparent": { track: rgba(colors.white, 0.3), train: colors.white },
};

/**
 * Alters the stroke dasharray to create the rotating train animation
 */
const trainKeyFrames = keyframes`
  0% {
    stroke-dasharray: 0, ${trackLength};
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: ${trackLength}, ${trackLength};
    stroke-dashoffset: 0;
  }
  100% {
    stroke-dasharray: ${trackLength}, ${trackLength};
    stroke-dashoffset: -${trackLength};
  }
`;

/**
 * Default SVG css properties for the circles
 */
const circleBaseStyles = css`
  cx: 50%;
  cy: 50%;
  r: calc(50% - ${trackWidth} / 2);
`;

export const Root = styled.div`
  aspect-ratio: 1;
  align-items: center;
  display: flex;
  height: ${rem(baseSize)};
  justify-content: center;
`;

export const LoaderSvg = styled.svg`
  aspect-ratio: 1;
  cursor: wait;
  display: block;
  fill: transparent;
  height: ${rem(baseSize)};
  margin: 0 auto;
  overflow: hidden;
  stroke-width: ${sizes.s8.rem};
  transform: rotate(-90deg);
`;

export const Track = styled.circle<{ variant: LoaderVariant }>`
  ${circleBaseStyles};
  stroke: ${({ variant }) => loaderColors[variant].track};
`;

export const Train = styled.circle<{ variant: LoaderVariant }>`
  ${circleBaseStyles};
  animation: ${trainKeyFrames} 2s infinite;
  stroke: ${({ variant }) => loaderColors[variant].train};
  stroke-linecap: round;
`;
