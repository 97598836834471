"use client";

import styled from "@emotion/styled";

import { colors } from "../../../design-tokens/colors";

export const Root = styled.span`
  overflow: hidden;
  word-break: break-word;
`;

export const Suffix = styled.span`
  color: ${colors.baseLightest};
`;
