import { HTMLAttributes } from "react";

import { pegiAgeIcons, pegiContentIcons } from "../../../design-tokens/icons";
import { PEGIIcon, PEGIWrapper as Root } from "../styles";

export type FooterPEGIProps = {
  /**
   * URL to PEGI classification
   */
  pegiUrl: string;
  /**
   * PEGI age icon to display
   */
  ageIcon: keyof typeof pegiAgeIcons;
  /**
   * PEGI content icons to display
   */
  contentIcons?: (keyof typeof pegiContentIcons)[];
} & HTMLAttributes<HTMLAnchorElement>;
export function FooterPEGI({ pegiUrl, ageIcon, contentIcons, ...rest }: FooterPEGIProps) {
  return (
    <Root
      href={pegiUrl}
      target="_blank"
      rel="noopener nofollower"
      data-testid={FooterPEGI.name}
      {...rest}
    >
      <PEGIIcon icon={ageIcon} $isAgeIcon={true} />
      {contentIcons &&
        contentIcons.map((contentIcon) => {
          return <PEGIIcon key={contentIcon} icon={contentIcon} />;
        })}
    </Root>
  );
}
