import type { HTMLAttributes } from "react";

import { SubMenuRoot as Root } from "./styles";

/**
 * Header.VerticalSubmenu - submenu list for Vertical menu.
 */
export const VerticalSubmenu = ({ children, ...rest }: HTMLAttributes<HTMLMenuElement>) => {
  return (
    <Root data-testid={VerticalSubmenu.name} {...rest}>
      {children}
    </Root>
  );
};
