import { ImageProps } from "../../../../../../atoms/Image/Image";
import { Root } from "./styles";

export type ThumbnailImageProps = {
  /**
   * Alt text for the images.
   */
  alt: string;
  /**
   * Determines if the active border should show on the image.
   */
  isActive: boolean;
  /**
   * Src for the images.
   */
  src: string;
} & ImageProps;

export const ThumbnailImage = ({ isActive, alt, src, ...rest }: ThumbnailImageProps) => {
  return (
    <Root
      role="button"
      isActive={isActive}
      data-testid={ThumbnailImage.name}
      alt={alt}
      src={src}
      tabIndex={0}
      {...rest}
    />
  );
};
