"use client";

import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { rem, rgba } from "polished";

import { curves, prefersReducedMotion } from "../../design-tokens/animations";
import { colors } from "../../design-tokens/colors";
import { globalContentMaxWidth, sizes } from "../../design-tokens/dimensions";

export const containerCss = css`
  align-items: center;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  gap: ${sizes.s8.rem};
`;

export const Root = styled.div``;

export const Content = styled.div`
  height: auto;
  position: relative;
`;

export const Wrapper = styled.div<{
  isExpanded: boolean;
  maxHeight?: number;
}>`
  ${containerCss};
  ${({ isExpanded }) =>
    // Fade gradient shown at the bottom of the collapsed container
    css`
      &::after {
        background: linear-gradient(0deg, ${colors.blueDeep} 11%, ${rgba(colors.blueDeep, 0)} 100%);
        bottom: 0;
        content: "";
        height: ${sizes.s48.rem};
        opacity: ${isExpanded ? 0 : 1};
        position: absolute;
        transition: opacity 0.5s ${curves.easeBoth};
        width: 100%;
      }
    `}
  ${() =>
    !prefersReducedMotion &&
    css`
      transition: max-height 0.5s ${curves.easeBoth};
    `}
  background-color: transparent;
  margin: 0 auto;
  max-height: ${({ maxHeight }) => (maxHeight ? rem(maxHeight) : "none")};
  max-width: ${rem(globalContentMaxWidth)};
  overflow: hidden;
  position: relative;
`;

export const ButtonContainer = styled.div`
  ${containerCss}
  margin: ${sizes.s8.rem};
`;
