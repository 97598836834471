"use client";

import styled from "@emotion/styled";
import { rem } from "polished";

import { Image } from "../../../../../../atoms/Image";
import { colors } from "../../../../../../design-tokens/colors";
import { device, sizes } from "../../../../../../design-tokens/dimensions";
import { thumbnailImageDimensions } from "../../../../constants";

export const Root = styled(Image)<{
  isActive: boolean;
  tabIndex: number;
}>`
  border: ${({ isActive }) =>
    `${sizes.s2.rem} solid ${isActive ? `${colors.blue}` : `transparent`}`};
  cursor: pointer;
  object-fit: cover;

  @media (${device.base}) {
    width: ${rem(thumbnailImageDimensions.base.width)};
    height: ${rem(thumbnailImageDimensions.base.height)};
  }

  @media (${device.xsmall}) {
    width: ${rem(thumbnailImageDimensions.xsmall.width)};
    height: ${rem(thumbnailImageDimensions.xsmall.height)};
  }

  @media (${device.small}) {
    width: ${rem(thumbnailImageDimensions.small.width)};
    height: ${rem(thumbnailImageDimensions.small.height)};
  }

  @media (${device.medium}) {
    width: ${rem(thumbnailImageDimensions.medium.width)};
    height: ${rem(thumbnailImageDimensions.medium.height)};
  }
`;
