"use client";

import { keyframes } from "@emotion/react";
import { css } from "@emotion/react";
import styled from "@emotion/styled";

import { curves, prefersReducedMotion } from "../../design-tokens/animations";
import { colors } from "../../design-tokens/colors";
import { sizes } from "../../design-tokens/dimensions";

const fadeIn = keyframes`
  0% {
    opacity: 0
  }
  100% {
    opacity: 1
  }
`;

export const Root = styled.section`
  margin: 0 auto;
  max-width: ${sizes.s1024.rem};
`;

export const MainViewContainer = styled.div<{
  isActive: boolean;
}>`
  aspect-ratio: 16/9;
  background: linear-gradient(120deg, ${colors.baseMid} 40%, ${colors.universalLight} 40%);
  display: ${({ isActive }) => (isActive ? "block" : "none")};
  ${() => {
    return css`
      animation: ${fadeIn} ${!prefersReducedMotion && "1s"} ${curves.default};
    `;
  }}
  overflow: hidden;
`;

MainViewContainer.displayName = "MainViewContainer";
