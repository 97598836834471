import type { HTMLAttributes } from "react";

import { AccountName } from "./AccountName/AccountName";
import { Brand } from "./Brand/Brand";
import { Button } from "./Button/Button";
import { Dropdown } from "./Dropdown/Dropdown";
import { DropdownItem } from "./Dropdown/DropdownItem";
import { Horizontal } from "./Horizontal/Horizontal";
import { HorizontalItem } from "./Horizontal/HorizontalItem";
import { JagexLogo } from "./JagexLogo/JagexLogo";
import { Link } from "./Link/Link";
import { Inner, Root } from "./styles";
import { Vertical } from "./Vertical/Vertical";
import { VerticalItem } from "./Vertical/VerticalItem/VerticalItem";
import { VerticalSubmenu } from "./Vertical/VerticalSubmenu";

type HeaderProps = {
  /**
   * Whether default branding {@link Header.JagexLogo} should be included
   */
  defaultBranding?: boolean;
} & HTMLAttributes<HTMLElement>;

/**
 * Navigation header with menu items, account settings and the Jagex branding.
 */
export function Header({ defaultBranding = true, children, ...rest }: HeaderProps): JSX.Element {
  return (
    <Root data-testid={Header.name} {...rest}>
      <Inner>
        {defaultBranding ? (
          <Brand>
            <JagexLogo />
          </Brand>
        ) : null}
        {children}
      </Inner>
    </Root>
  );
}

Header.AccountName = AccountName;
Header.Brand = Brand;
Header.Button = Button;
Header.Dropdown = Dropdown;
Header.DropdownItem = DropdownItem;
Header.Horizontal = Horizontal;
Header.HorizontalItem = HorizontalItem;
Header.JagexLogo = JagexLogo;
Header.Link = Link;
Header.Vertical = Vertical;
Header.VerticalItem = VerticalItem;
Header.VerticalSubmenu = VerticalSubmenu;
