"use client";
import { css, Global } from "@emotion/react";

import { motionStyles } from "../entities/design-tokens/animations";
import { focus } from "../entities/design-tokens/effects";
import { fonts } from "../entities/design-tokens/typography/fonts";
import { resets } from "./resets";

/**
 * Global style setup for JDS. This is not usually used directly, since the {@link @jagex-pp/jds#JdsProvider} will do this automatically.
 */
export function GlobalStyle({ withFonts }: { withFonts: boolean }): JSX.Element {
  return (
    <Global
      styles={css`
        ${resets}
        ${withFonts ? fonts : null}
        ${motionStyles}
        *${focus}
      `}
    />
  );
}
