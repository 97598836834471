import type { HTMLAttributes } from "react";

import { Heading as BoxHeading } from "../../../atoms/Heading";

/**
 * Renders the standard heading for a {@link BoxFlow} interface
 */
export function Heading({ children, ...rest }: HTMLAttributes<HTMLHeadingElement>): JSX.Element {
  return (
    <BoxHeading data-testid={`BoxFlow${Heading.name}`} level="h2" as="h1" align="center" {...rest}>
      {children}
    </BoxHeading>
  );
}
