"use client";

import styled from "@emotion/styled";

import { Link as LinkComponent } from "../../../atoms/Link";
import { clickableItemBaseStyles } from "../styles";
import { LinkProps } from "./types";

export const Root = styled(LinkComponent)<LinkProps>`
  ${(props) => clickableItemBaseStyles(props)}
`;
