"use client";

import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { rem } from "polished";

import { colors } from "../../../design-tokens/colors";
import { device } from "../../../design-tokens/dimensions";
import { mobileNavBreakpoint } from "../styles";
import type { HorizontalItemProps, HorizontalProps } from "./types";

export const Root = styled.ul<HorizontalProps>`
  display: none;
  list-style: none;
  height: 100%;
  margin: 0;
  padding: 0;

  @media (${device[mobileNavBreakpoint]}) {
    display: flex;
  }

  ${({ position }) => {
    switch (position) {
      case "left":
        return "margin-right: auto";
      case "right":
        return "margin-left: auto";
      default:
        return "";
    }
  }}
`;

export const ItemRoot = styled.li<HorizontalItemProps>`
  align-items: center;
  display: flex;
  height: 100%;
  position: relative;

  ${({ isActive }) =>
    isActive &&
    css`
      &::after {
        background-color: ${colors.blueLight};
        bottom: 0;
        content: "";
        display: block;
        height: ${rem(4)};
        position: absolute;
        width: 100%;
      }
    `}
`;
