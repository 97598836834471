import { rem } from "polished";
import { CSSProperties } from "react";

import { fallbackFonts } from "./fonts";

const percentToEM = (percent: number): string => `${percent / 100}em`;

/**
 * Typography JDS presets.
 *
 * Alternatively use {@link @jagex-pp/jds#paragraph}, {@link @jagex-pp/jds#button} or {@link @jagex-pp/jds#heading} respectively for your use case.
 */
export const typography = {
  /**
   * Font families advised by JDS.
   */
  families: {
    body: fallbackFonts,
    heading: fallbackFonts,
    cta: fallbackFonts,
  } as const,

  /**
   * Font size increments advised by JDS.
   */
  sizes: {
    inherit: "1em",
    14: rem(14),
    16: rem(16),
    18: rem(18),
    20: rem(20),
    24: rem(24),
    32: rem(32),
    40: rem(40),
    44: rem(44),
    48: rem(48),
  } as const,

  /**
   * Font weights supported by JDS.
   */
  weights: {
    normal: 400,
    medium: 500,
    bold: 700,
  } as const,

  /**
   * Font line heights supported by JDS.
   */
  lineHeights: {
    20: rem(20),
    24: rem(24),
    28: rem(28),
    32: rem(32),
    44: rem(44),
    48: rem(48),
    56: rem(56),
  } as const,

  /**
   * Font letter spacing supported by JDS.
   */
  letterSpacing: {
    2: percentToEM(2),
    3: percentToEM(3),
    5: percentToEM(5),
    12: percentToEM(12),
  },
} as const;

export type FontValues = (typeof typography.families)[keyof typeof typography.families];

/**
 * Common styles applied to elements for styling fonts.
 * These are exported to allow objects to be created that can be displayed in the typography design tokens story
 */
export type FontStyles = {
  fontSize: Exclude<CSSProperties["fontSize"], undefined>;
  fontWeight: Exclude<CSSProperties["fontWeight"], undefined>;
  letterSpacing: Exclude<CSSProperties["letterSpacing"], undefined>;
  lineHeight: Exclude<CSSProperties["lineHeight"], undefined>;
};
