"use client";

import styled from "@emotion/styled";

import { Container } from "../../atoms/Container";
import { Breakpoint, device } from "../../design-tokens/dimensions";

export const mobileNavBreakpoint: Breakpoint = "medium";

export const Root = styled.div`
  height: 100vh;
  padding: 0;
  position: relative;
  width: 100%;
`;

export const MultiplexContainer = styled(Container)`
  @media (${device[mobileNavBreakpoint]}) {
    height: 100%;
  }
`;

export const MultiplexBackground = styled.div`
  bottom: 0;
  left: 0;
  min-height: 100%;
  padding: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
`;

export const MultiplexContent = styled.div`
  display: flex;
  flex-direction: column;

  @media (${device[mobileNavBreakpoint]}) {
    flex-direction: row;
    flex: 1 0 100%;
    height: 100%;
  }
`;
