"use client";

import { css, SerializedStyles } from "@emotion/react";
import styled from "@emotion/styled";

import { breakpoints, device } from "../../design-tokens/dimensions";
import type { ImageProps, ObjectPositionConfig, SrcSetConfig } from "./Image";

type ImageElementProps = Omit<ImageProps, "src">;

/**
 * Calculates the object position of the image for the given breakpoint.
 * @param objectPositionProps - a current object position value for the given breakpoint.
 * @param breakpoint - the given breakpoint value.
 */
const setObjectPosition = (
  objectPositionProps: string | number | undefined,
  breakpointKey: keyof ObjectPositionConfig
) => {
  const objectPosition = objectPositionProps ?? "50% 50%";

  return breakpoints[breakpointKey] !== 0
    ? `@media(${device[breakpointKey]}) {object-position: ${objectPosition}; } `
    : `object-position: ${objectPosition};`;
};

/**
 * Calculates the object position of an item at each breakpoint based on the given config.
 * @param config - {@link ObjectPositionConfig} which defines srcset at different breakpoints for this item.
 */
export const getObjectPosition = (config: ObjectPositionConfig): SerializedStyles => {
  const objectPosition = (
    Object.entries(config) as Array<[keyof typeof config, (typeof config)[keyof typeof config]]>
  )
    .map(([breakpoint, position]) => setObjectPosition(position, breakpoint))
    .join("");

  return css`
    ${objectPosition}
  `;
};

/**
 * Calculates the srcset of an item at each breakpoint based on the given config.
 * @param config - {@link SrcSetConfig} which defines srcset at different breakpoints for this item.
 */
export const getSrcSet = (config: SrcSetConfig): string => {
  return (
    Object.entries(config) as Array<[keyof typeof config, (typeof config)[keyof typeof config]]>
  )
    .map(([breakpointKey, src]) => (src ? `${src} ${breakpoints[breakpointKey]}w, ` : ""))
    .join("");
};

export const ImageElement = styled.img<ImageElementProps>`
  object-fit: cover;
  ${({ objectPosition }) => (objectPosition ? getObjectPosition(objectPosition) : "")};
`;
