"use client";

import styled from "@emotion/styled";
import type { CSSProperties } from "react";

import { lineClamp } from "../../../utils/line-clamp";
import type { ColorValue } from "../../design-tokens/colors";
import { colors } from "../../design-tokens/colors";
import { globalTextMaxWidth, sizes } from "../../design-tokens/dimensions";
import { FontStyles, typography } from "../../design-tokens/typography";

export const smallTextStyles: FontStyles = {
  fontSize: typography.sizes["14"],
  fontWeight: typography.weights.normal,
  letterSpacing: typography.letterSpacing["2"],
  lineHeight: typography.lineHeights[20],
};
export const Root = styled.small<{
  lines?: number;
  align?: CSSProperties["textAlign"];
  color?: ColorValue;
}>`
  color: ${({ color }) => color ?? colors.white};
  display: block;
  font-size: ${smallTextStyles.fontSize};
  font-weight: ${smallTextStyles.fontWeight};
  letter-spacing: ${smallTextStyles.letterSpacing};
  margin: ${sizes.s8.rem} auto;
  max-width: ${globalTextMaxWidth};
  text-align: ${({ align }) => align};
  ${({ lines }) => lineClamp(`${smallTextStyles.lineHeight}`, lines)};

  a {
    font-size: inherit;
  }
`;
