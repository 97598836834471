import de from "../../../locales/de-DE/footer.json";
import en from "../../../locales/en-GB/footer.json";
import fr from "../../../locales/fr-FR/footer.json";
import pt from "../../../locales/pt-BR/footer.json";
import type { Locale, TranslationDef } from "../../../locales/types";

export const translations: Record<Locale, Pick<TranslationDef, "footer">> = {
  "en-GB": en,
  "de-DE": de,
  "fr-FR": fr,
  "pt-BR": pt,
};
