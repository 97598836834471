"use client";

import styled from "@emotion/styled";
import { rem } from "polished";

import { lineClamp } from "../../../utils/line-clamp";
import { Heading } from "../../atoms/Heading";
import { HeadingLevels, headingStyles } from "../../atoms/Heading/styles";
import { globalTextMaxWidth } from "../../design-tokens/dimensions";
import { HeadingAlignment } from "./DoubleHeading";

export const Root = styled.div<{ align: HeadingAlignment }>`
  display: flex;
  justify-content: ${({ align }) => align};
  margin: 0 auto;
  max-width: ${rem(globalTextMaxWidth)};
  position: relative;
  text-align: ${({ align }) => align};
  width: 100%;
`;

export const HeadingWrapper = styled(Heading)<{ align: HeadingAlignment }>`
  margin: 0;
  text-align: ${({ align }) => align};
  width: fit-content;
`;

export const FirstHeading = styled(Heading)<{
  level: HeadingLevels;
  lines: number;
}>`
  margin: 0;
  width: auto;
  ${({ lines, level }) => lineClamp(`${headingStyles[level].lineHeight}`, lines)};
`;

export const SecondHeading = styled(Heading)<{ lines: number; level: HeadingLevels }>`
  margin: 0;
  width: auto;
  ${({ lines, level }) => lineClamp(`${headingStyles[level].lineHeight}`, lines)};
`;
