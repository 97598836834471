import type { CSSProperties, ElementType, HTMLAttributes } from "react";

import type { ColorValue } from "../../design-tokens/colors";
import { Root } from "./styles";

export type ParagraphSize = "m" | "l";

export type ParagraphProps = {
  /**
   * Number of lines the text is restricted to. See {@link @jagex-pp/jds#lineClamp}.
   */
  lines?: number;
  /**
   * The size variant to use. Note this only applies to {@link device.large} screens and above. The size is unchanged on smaller screens.
   */
  entitySize?: ParagraphSize;
  /**
   * The alignment of text within the paragraph.
   */
  align?: CSSProperties["textAlign"];
  /**
   * The font-color of the text. See {@link @jagex-pp/jds#colors}.
   */
  color?: ColorValue;
  /**
   * Markup to generate (independent of the visual style).
   */
  as?: ElementType;
} & HTMLAttributes<HTMLParagraphElement>;

/**
 * Paragraph of body text.
 */
export function Paragraph({
  color,
  lines,
  align = "inherit",
  entitySize = "m",
  children,
  ...rest
}: ParagraphProps): JSX.Element {
  return (
    <Root
      lines={lines}
      align={align}
      entitySize={entitySize}
      color={color}
      data-testid={Paragraph.name}
      {...rest}
    >
      {children}
    </Root>
  );
}
