"use client";

import styled from "@emotion/styled";
import { FloatingOverlay } from "@floating-ui/react";
import { rgba } from "polished";

import { Heading } from "../../atoms/Heading";
import { colors } from "../../design-tokens/colors";
import { device, sizes } from "../../design-tokens/dimensions";
import { borderRadii, boxShadows } from "../../design-tokens/effects";
import { scrollbar } from "../../design-tokens/scrollbar";
import { zIndex } from "../../design-tokens/z-index";
import { closeButtonWidth } from "./CloseButton";
import { ModalSize } from "./Modal";

const modalWidths: Record<ModalSize, string> = {
  s: "360px",
  m: "670px",
  l: "900px",
  "fit-content": "fit-content",
};

const modalHeights: Record<ModalSize, string> = {
  s: "360px",
  m: "550px",
  l: "740px",
  "fit-content": "fit-content",
};

export const modalMaxWidth = sizes.s512.rem;

export const Dialog = styled.dialog<{ width: ModalSize; height: ModalSize }>`
  background: ${colors.baseDark};
  border: none;
  box-shadow: ${boxShadows.frame};
  height: 100vh;
  margin: 0;
  max-height: none;
  max-width: none;
  padding: ${sizes.s24.rem};
  width: 100%;
  word-break: break-word;

  ${scrollbar};

  @media (${device.xsmall}) {
    border-radius: ${borderRadii.r8};
    height: ${({ height }) => modalHeights[height]};
    margin: auto;
    max-height: 90%;
    max-width: 100%;
    width: ${({ width }) => modalWidths[width]};
  }
`;

export const DialogWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const Overlay = styled(FloatingOverlay)`
  background: ${rgba(colors.blueDeep, 0.6)};
  z-index: ${zIndex.modal};
`;

export const ModalHeading = styled(Heading)`
  margin: 0;
  width: calc(100% - ${closeButtonWidth});
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
`;
