import { Breakpoint } from "../../design-tokens/dimensions";

type AcceptedBreakpoints = Extract<Breakpoint, "base" | "xsmall" | "small" | "medium">;

export const thumbnailPadding = 6;

export const thumbnailsShown: Record<AcceptedBreakpoints, number> = {
  base: 2,
  xsmall: 3,
  small: 4,
  medium: 5,
};

export const thumbnailImageDimensions: Record<
  AcceptedBreakpoints,
  { width: number; height: number }
> = {
  base: {
    width: 108,
    height: 62,
  },
  xsmall: {
    width: 138,
    height: 86,
  },
  small: {
    width: 154,
    height: 95,
  },
  medium: {
    width: 168,
    height: 95,
  },
};

const totalThumbnailPadding = thumbnailPadding * 2;

export const thumbnailWidths: Record<AcceptedBreakpoints, number> = {
  base: thumbnailImageDimensions.base.width + totalThumbnailPadding,
  xsmall: thumbnailImageDimensions.xsmall.width + totalThumbnailPadding,
  small: thumbnailImageDimensions.small.width + totalThumbnailPadding,
  medium: thumbnailImageDimensions.medium.width + totalThumbnailPadding,
};

export const NUMBER_OF_ITEMS_TO_PREFETCH = 1;
