{
  "logos" : {
    "flat-logos": {
      "epic-store-flat": {
        "title": "Epic Store",
        "alt": "Logo da Epic Store."
      },
      "flying-wild-hog-flat": {
        "title": "Flying Wild Hog",
        "alt": "Logo da Flying Wild Hog."
      },
      "gamepires-flat": {
        "title": "Gamepires",
        "alt": "Logo da Gamepires"
      },
      "jagex-full-flat": {
        "title": "Jagex Games Ltd",
        "alt": "Um logo de X branco seguido por Jagex."
      },
      "old-school-runescape-flat": {
        "title": "Old School RuneScape",
        "alt": "Logo do Old School RuneScape."
      },
      "runescape-flat": {
        "title": "RuneScape",
        "alt": "Logo do RuneScape."
      }
    },
    "color-logos": {
      "old-school-runescape-color": {
        "title": "Old School RuneScape",
        "alt": "Logo do Old School RuneScape."
      },
      "runescape-color": {
        "title": "RuneScape",
        "alt": "Logo do RuneScape."
      },
      "melvor-idle-color": {
        "title": "Melvor Idle",
        "alt": "Logo do Melvor Idle."
      }
    }
  }
}
