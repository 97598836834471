import { HTMLAttributes, ReactElement, RefObject, useCallback, useState } from "react";

import { TabNavContext } from "./context";
import { TabNavList } from "./styles";
import { TabNavItem, TabNavItemProps } from "./TabNavItem";

export type TabNavProps = {
  /**
   * `TabNav.Item(s)` to pass to display
   */
  children: ReactElement<TabNavItemProps>[];
  /**
   * Currently active tab Id
   */
  activeTab: string;
  /**
   * Function called when the active tab is changed
   */
  onTabChange?: (id: string) => void;
} & Omit<HTMLAttributes<HTMLUListElement>, "children">;

export const TabNav = ({ children, activeTab, onTabChange, ...rest }: TabNavProps) => {
  const [localActiveTab, setLocalActiveTab] = useState(activeTab ?? "");
  const [tabRefs, setTabRefs] = useState<Record<string, RefObject<HTMLLIElement>>>({});

  const registerItem = useCallback((id: string, ref: RefObject<HTMLLIElement>) => {
    setTabRefs((previousState) => {
      const newState = { ...previousState };
      newState[id] = ref;

      return newState;
    });
  }, []);

  const unregisterItem = useCallback((id: string) => {
    setTabRefs((previousState) => {
      const newState = { ...previousState };
      delete newState[id];

      return newState;
    });
  }, []);

  const setActive = useCallback(
    (id: string) => {
      if (localActiveTab !== id && typeof onTabChange === "function") {
        onTabChange(id);
      }
      setLocalActiveTab(id);
    },
    [localActiveTab, onTabChange]
  );

  const focusPrevious = useCallback(
    (id: string) => {
      const index = children.findIndex((el) => el.props.id === id);
      if (index > 0) {
        tabRefs[children[index - 1].props.id].current?.focus();
      }
    },
    [children, tabRefs]
  );

  const focusNext = useCallback(
    (id: string) => {
      const index = children.findIndex((el) => el.props.id === id);
      if (children.length > index + 1) {
        tabRefs[children[index + 1].props.id].current?.focus();
      }
    },
    [children, tabRefs]
  );

  return (
    <TabNavContext.Provider
      value={{
        activeTab: localActiveTab,
        setActive,
        focusPrevious,
        focusNext,
        registerItem,
        unregisterItem,
      }}
    >
      <TabNavList tabIndex={-1} role="tablist" data-testid={TabNav.displayName} {...rest}>
        {children}
      </TabNavList>
    </TabNavContext.Provider>
  );
};

TabNav.displayName = "TabNav";

/**
 * Composite component structure
 */
TabNav.Item = TabNavItem;
