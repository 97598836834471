{
  "logos" : {
    "flat-logos": {
      "epic-store-flat": {
        "title": "Epic Store",
        "alt": "Epic Store logo"
      },
      "flying-wild-hog-flat": {
        "title": "Flying Wild Hog",
        "alt": "Flying Wild Hog logo"
      },
      "gamepires-flat": {
        "title": "Gamepires",
        "alt": "Gamepires logo"
      },
      "jagex-full-flat": {
        "title": "Jagex Games Ltd",
        "alt": "A white X logo followed by the name Jagex"
      },
      "old-school-runescape-flat": {
        "title": "Old School RuneScape",
        "alt": "OldSchool RuneScape logo"
      },
      "runescape-flat": {
        "title": "RuneScape",
        "alt": "RuneScape logo"
      }
    },
    "color-logos": {
      "old-school-runescape-color": {
        "title": "Old School RuneScape",
        "alt": "OldSchool RuneScape logo"
      },
      "runescape-color": {
        "title": "RuneScape",
        "alt": "RuneScape logo"
      },
      "melvor-idle-color": {
        "title": "Melvor Idle",
        "alt": "Melvor Idle logo"
      }
    }
  }
}
