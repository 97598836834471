"use client";

import styled from "@emotion/styled";
import { rem } from "polished";
import type { CSSProperties } from "react";

export const Content = styled.div<{
  position: CSSProperties["position"];
  top: CSSProperties["top"];
  left: CSSProperties["left"];
}>`
  left: ${({ left }) => (left ? /* istanbul ignore next */ rem(left) : "auto")};
  overflow: hidden;
  overflow-wrap: break-word;
  max-width: max-content;
  position: ${({ position }) => position};
  top: ${({ top }) => (top ? /* istanbul ignore next */ rem(top) : "auto")};
  word-break: break-word;
  width: 100%;
`;
