"use client";

import styled from "@emotion/styled";
import { rem } from "polished";

import { Icon } from "../../atoms/Icon";
import { Paragraph } from "../../atoms/Paragraph";
import { colors } from "../../design-tokens/colors";
import { sizes } from "../../design-tokens/dimensions";
import { boxShadows } from "../../design-tokens/effects";
import { Card } from "../Card";
import type { OrientationProps } from "./NewsCard";

const baseSize = rem(240);

export const CardContentClassName = "CardContent";

export const Root = styled(Card)<OrientationProps>`
  aspect-ratio: ${({ orientation }) => (orientation === "horizontal" ? "auto" : "1 / 1")};
  box-shadow: ${boxShadows.float};
  cursor: pointer;
  height: ${({ orientation }) => (orientation === "horizontal" ? baseSize : "100%")};
  min-height: ${baseSize};
  min-width: ${baseSize};
  width: 100%;

  .${CardContentClassName} {
    transition: background-color 0.2s;
  }

  &:hover .${CardContentClassName} {
    background-color: ${colors.betaBase};
  }
`;

export const CardImage = styled(Card.Image)<OrientationProps>`
  flex: 1 1 auto;
  height: ${({ orientation }) => (orientation === "horizontal" ? "100%" : "auto")};
  min-height: 0;
  width: 100%;
`;

export const CardContent = styled(Card.Content)<OrientationProps>`
  box-sizing: ${({ orientation }) => (orientation === "horizontal" ? "border-box" : "content-box")};
  height: ${({ orientation }) => (orientation === "horizontal" ? "100%" : "3em")};
  justify-content: space-between;
`;

export const TitleLine = styled(Paragraph)`
  margin: 0;
`;

export const StickyIcon = styled(Icon)`
  margin-right: ${sizes.s8.rem};
  min-height: ${sizes.s12.rem};
  min-width: ${sizes.s12.rem};
`;

export const DateLine = styled(Paragraph)`
  color: ${colors.baseLightest};
  margin: 0;
`;
