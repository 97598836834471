import type { HTMLAttributes } from "react";

import { Root, Suffix } from "./styles";

type AccountNameProps = {
  /**
   * Jagex account displayName
   */
  displayName: string;
  /**
   * Jagex account suffix
   */
  suffix: string;
} & HTMLAttributes<HTMLSpanElement>;

/**
 * Header.AccountName - displays jagex account name with suffix
 */
export function AccountName({ displayName, suffix, ...rest }: AccountNameProps) {
  return (
    <Root data-testid={AccountName.name} {...rest}>
      {displayName}
      {suffix ? <Suffix>#{suffix}</Suffix> : ""}
    </Root>
  );
}
