import { forwardRef, HTMLAttributes } from "react";

import { ItemRoot as Root } from "./styles";
import { HorizontalItemProps } from "./types";

/**
 * Header.HorizontalItem - host for the Link, Button or any navigation element must be child of Horizontal component.
 */
export const HorizontalItem = forwardRef<
  HTMLLIElement,
  HorizontalItemProps & HTMLAttributes<HTMLLIElement>
>(({ children, ...props }, propRef) => {
  return (
    <Root data-testid={`Header${HorizontalItem.displayName}`} ref={propRef} {...props}>
      {children}
    </Root>
  );
});

HorizontalItem.displayName = "HorizontalItem";
