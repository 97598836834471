"use client";

import styled from "@emotion/styled";
import { rem, rgba } from "polished";

import { colors } from "../../design-tokens/colors";
import { buttonBorderRadius } from "../Button/styles";

export const Root = styled.div`
  align-items: center;
  display: inline-flex;
  justify-content: center;

  button {
    border-right: ${rem(1)} solid ${rgba(colors.baseBlack, 0.3)};

    &:focus {
      z-index: 1; // Allows the outline to be in front of the siblings
    }

    &:first-child {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }

    &:not(:first-child):not(:last-child) {
      border-radius: 0;
    }

    &:last-child {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      border-right: 0;
    }

    &:only-child {
      border-radius: ${buttonBorderRadius};
    }
  }
`;
