"use client";

import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { rem, transparentize } from "polished";

import { colors } from "../../design-tokens/colors";
import { Breakpoint, globalContentMaxWidth, sizes } from "../../design-tokens/dimensions";
import { zIndex } from "../../design-tokens/z-index";

export const mobileNavBreakpoint: Breakpoint = "medium";
export const clickableItemHorizontalPadding = sizes.s16.rem;
export const navbarHeight = sizes.s64.rem;
export const verticalItemHeight = sizes.s56.rem;
export const verticalSubmenuItemHeight = sizes.s48.rem;

export const Root = styled.nav`
  background: ${colors.blueDeep};
  border-bottom: 1px solid ${colors.baseMid};
  height: ${navbarHeight};
  padding: 0 ${sizes.s24.rem};
  position: relative;
  width: 100%;
  z-index: ${zIndex.header};
`;

export const Inner = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  margin: 0 auto;
  max-width: ${rem(globalContentMaxWidth)};
  position: relative;
  width: 100%;
`;

/**
 * Shared type and styles for clickable nav items Button and Link
 */
export type ClickableItemProps = {
  /**
   * Define if element contains only {@link Icon} as children
   */
  iconOnly?: boolean;
};

export const clickableItemBaseStyles = ({ iconOnly }: ClickableItemProps) => css`
  --mask-icon-size: ${iconOnly && sizes.s24.rem};
  --mask-icon-color: ${colors.white};

  align-items: center;
  border-radius: 0;
  color: ${colors.white};
  height: 100%;
  padding: 0 ${clickableItemHorizontalPadding};
  text-decoration: none;

  &:focus,
  &:focus-visible {
    outline-offset: -1px;
  }

  &:hover {
    background-color: ${transparentize(0.9, colors.white)};
    text-decoration: none;
  }
`;
