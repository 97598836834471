import { colors } from "../entities/design-tokens/colors/colors";

/**
 * @internal CSS reset, used setting up {@link @jagex-pp/jds#GlobalStyle}.
 */
export const resets = `
    html, body {
      background: ${colors.blueDeep};
      margin: 0;
      padding: 0;
    }

    html {
      box-sizing: border-box;
      color: ${colors.white};
    }

    *, *:before, *:after {
      box-sizing: inherit;
    }
  `;
