import { css } from "@emotion/react";
import { rem, rgba, transparentize } from "polished";
import type { CSSProperties } from "react";

import { colors } from "../colors";

/**
 * Border radius values
 */
export const borderRadii: Record<string, CSSProperties["borderRadius"]> = {
  r4: rem(4),
  r8: rem(8),
  round: "50%",
};

export const boxShadows: Record<string, CSSProperties["boxShadow"]> = {
  deboss: `inset 0 0 ${rem(12)} ${rem(12)} ${rgba(colors.baseBlack, 0.2)}`,
  frame: `0 ${rem(24)} ${rem(38)} ${rgba(colors.baseBlack, 0.34)}`,
  float: `0 ${rem(11)} ${rem(15)} ${rgba(colors.baseBlack, 0.2)}`,
  inset: `inset 0 ${rem(8)} ${rem(8)} ${transparentize(0.8, colors.baseBlack)}`,
};

export const filters: Record<string, CSSProperties["filter"]> = {
  disabled: `opacity(50%)`,
};

export const focusValue = `${rem(1)} dashed ${colors.white}`;

export const focus = css`
  &:focus-visible {
    outline: ${focusValue};
    outline-offset: 4px;
  }
`;

export const glows: Record<string, (color: string) => CSSProperties["filter"]> = {
  primary: (color: string) => `drop-shadow(0 ${rem(6)} ${rem(10)} ${color})`,
};

export const glazes = {
  left: `linear-gradient(90deg, ${rgba(colors.baseBlack, 0.75)} 43.23%, transparent 68.23%)`,
  right: `linear-gradient(90deg, transparent 31.77%, ${rgba(colors.baseBlack, 0.75)} 56.77%)`,
  blackVertical1: `linear-gradient(180deg, ${rgba(colors.baseDark, 0.12)} 0%, ${rgba(
    colors.baseDark,
    0.58
  )} 34.9%)`,
};
