"use client";

import styled from "@emotion/styled";

import { sizes } from "../../../design-tokens/dimensions";

export const Root = styled.div`
  align-items: center;
  display: inline-flex;
  margin-right: ${sizes.s72.rem};
`;
