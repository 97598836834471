import type React from "react";
import type { HTMLAttributes } from "react";

import { Content } from "./Content";
import { Image } from "./Image";
import { Root } from "./styles";

export type CardOrientation = "vertical" | "horizontal";

export type CardProps = {
  /**
   * The component orientation.
   */
  orientation?: CardOrientation;
} & HTMLAttributes<HTMLElement>;

/**
 *  A box used to display a small piece of content using text, image, video etc
 */
export function Card({ children, orientation = "vertical", ...rest }: CardProps) {
  return (
    <Root data-testid={Card.name} orientation={orientation} {...rest}>
      {children}
    </Root>
  );
}

/**
 * Composite component structure
 */
Card.Image = Image;
Card.Content = Content;
