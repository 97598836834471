import type { HTMLAttributes } from "react";
import { ReactNode } from "react";

import { ImageBox as Root, imageBoxClassName } from "../styles";

export type ImageBoxProps = HTMLAttributes<HTMLDivElement> & {
  /**
   * The images to display
   */
  children: ReactNode;
};

/**
 * Used to display images inside a {@link ContentPlate}
 */
export function ImageBox({ children, ...rest }: ImageBoxProps) {
  return (
    <Root data-testid={ImageBox.name} className={imageBoxClassName} {...rest}>
      {children}
    </Root>
  );
}
