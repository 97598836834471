import { ClassNames } from "@emotion/react";
import type { ButtonHTMLAttributes, PointerEvent, ReactNode } from "react";
import { forwardRef } from "react";

import {
  ButtonLoader,
  LinkButton,
  PositiveButton,
  PrimaryButton,
  SecondaryButton,
  TertiaryButton,
} from "./styles";

export type ButtonVariant = "primary" | "positive" | "secondary" | "tertiary" | "inline";

export type ButtonSize = "s" | "m";

export type ButtonProps = {
  /**
   * Action to perform when the button is clicked.
   */
  onClick?: (e: PointerEvent<HTMLButtonElement>) => void;
  /**
   * Icon to put before the text.
   */
  before?: ReactNode;
  /**
   * Icon to put after the text.
   */
  after?: ReactNode;
  /**
   * Style variation of the button.
   */
  variant?: ButtonVariant;
  /**
   * Size variant to use (does not alter inline Link style).
   */
  entitySize?: ButtonSize;
  /**
   * Whether the Button should take up the full width of its container.
   */
  fullWidth?: boolean;
  /**
   * Is the entity in the loading state.
   */
  isLoading?: boolean;
  /**
   * Percentage of background to fill horizontally to indicate progress.
   */
  progress?: number;
} & ButtonHTMLAttributes<HTMLButtonElement>;

export const ButtonClassName = "Button";

/**
 * Defines whether the component should display loaders for the supplied variant.
 * @param variant - component's style variation
 */
function supportsLoading(variant: ButtonVariant) {
  return ["primary", "positive", "secondary"].includes(variant);
}

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(function (
  {
    onClick,
    variant = "primary",
    entitySize = "m",
    fullWidth = false,
    before,
    after,
    children,
    isLoading = false,
    progress,
    disabled,
    className,
    ...rest
  },
  propRef
): JSX.Element {
  const Root = {
    primary: PrimaryButton,
    positive: PositiveButton,
    secondary: SecondaryButton,
    tertiary: TertiaryButton,
    inline: LinkButton,
  }[variant];

  const showLoader = isLoading && supportsLoading(variant);
  const progressPercentage =
    typeof progress === "number" ? Math.min(Math.max(progress, 0), 100) : undefined;
  const isDisabled = isLoading || progress !== undefined || disabled;

  return (
    <ClassNames>
      {({ cx }) => (
        <Root
          ref={propRef}
          entitySize={entitySize}
          fullWidth={fullWidth}
          onClick={onClick}
          data-testid={Button.displayName}
          disabled={isDisabled}
          isLoading={isLoading}
          progress={progressPercentage}
          aria-busy={isLoading}
          className={cx(ButtonClassName, className)}
          {...rest}
        >
          {!showLoader ? (
            <>
              {before}
              {children}
              {after}
            </>
          ) : (
            <ButtonLoader entitySize={entitySize} variant="semi-transparent" />
          )}
        </Root>
      )}
    </ClassNames>
  );
});

Button.displayName = "Button";
