"use client";

import styled from "@emotion/styled";
import { rem } from "polished";

import { prefersReducedMotion } from "../../../../design-tokens/animations";
import { device, sizes } from "../../../../design-tokens/dimensions";
import { scrollbar } from "../../../../design-tokens/scrollbar";
import { thumbnailsShown, thumbnailWidths } from "../../constants";

export const Root = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-top: ${sizes.s12.rem};
  overflow-y: hidden;
`;

export const ScrollableContent = styled.div`
  overflow-x: scroll;
  overflow-y: hidden;
  padding-bottom: ${sizes.s4.rem};
  position: relative;
  scroll-behavior: ${prefersReducedMotion ? "auto" : "smooth"};
  white-space: nowrap;

  ${scrollbar};

  @media (${device.base}) {
    max-width: ${rem(thumbnailsShown.base * thumbnailWidths.base)};
  }

  @media (${device.xsmall}) {
    max-width: ${rem(thumbnailsShown.xsmall * thumbnailWidths.xsmall)};
  }

  @media (${device.small}) {
    max-width: ${rem(thumbnailsShown.small * thumbnailWidths.small)};
  }

  @media (${device.medium}) {
    max-width: ${rem(thumbnailsShown.medium * thumbnailWidths.medium)};
  }
`;
