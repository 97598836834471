import { ClassNames } from "@emotion/react";
import { forwardRef } from "react";

import type { ButtonProps as ButtonComponentProps } from "../../../atoms/Button/Button";
import type { ClickableItemProps } from "../styles";
import { Root } from "./styles";

type ButtonProps = ClickableItemProps & ButtonComponentProps;

export const HeaderButtonClassName = "HeaderButton";

/**
 * Header.Button - renders a {@link Button} element for the Header
 */
export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  ({ children, className, ...props }, propRef) => {
    return (
      <ClassNames>
        {({ cx }) => (
          <Root
            data-testid={`Header${Button.displayName}`}
            ref={propRef}
            variant="inline"
            className={cx(HeaderButtonClassName, className)}
            {...props}
          >
            {children}
          </Root>
        )}
      </ClassNames>
    );
  }
);

Button.displayName = "Button";
