import type { HTMLAttributes, ReactNode } from "react";

import { Heading } from "./Heading";
import { Logo } from "./Logo";
import { SocialLink } from "./SocialLink";
import { BackgroundContainer, Box, Root } from "./styles";
import { Toast } from "./Toast";

type BoxFlowProps = HTMLAttributes<HTMLDivElement> & {
  /**
   * Container for background graphics such as images and video.
   */
  backgroundAssets?: ReactNode;
};

/**
 * Renders a {@link ContentFrame} in front of a full screen background
 */
export function BoxFlow({ backgroundAssets, children, ...rest }: BoxFlowProps): JSX.Element {
  return (
    <Root data-testid={BoxFlow.name} {...rest}>
      {backgroundAssets && (
        <BackgroundContainer data-testid={`${BoxFlow.name}Background`}>
          {backgroundAssets}
        </BackgroundContainer>
      )}
      <Box>{children}</Box>
    </Root>
  );
}

/**
 * Composite component structure
 */
BoxFlow.Heading = Heading;
BoxFlow.Logo = Logo;
BoxFlow.SocialLink = SocialLink;
BoxFlow.Toast = Toast;
