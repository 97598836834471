import type { HTMLAttributes } from "react";
import { forwardRef } from "react";

import { baseSize, LoaderSvg, Root, Track, Train } from "./styles";

export type LoaderVariant = "blue" | "semi-transparent";

export type LoaderProps = {
  /**
   * The style variation of the loader
   */
  variant?: LoaderVariant;
} & HTMLAttributes<HTMLDivElement>;

/**
 * Circular icon to indicate content is loading.
 */
export const Loader = forwardRef<HTMLDivElement, LoaderProps>(function (
  { variant = "blue", ...rest },
  propRef
): JSX.Element {
  return (
    <Root
      ref={propRef}
      data-testid={Loader.displayName}
      role="progressbar"
      aria-label="Loading"
      {...rest}
    >
      <LoaderSvg
        viewBox={`0 0 ${baseSize} ${baseSize}`}
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
      >
        <Track variant={variant} />
        <Train variant={variant} />
      </LoaderSvg>
    </Root>
  );
});

Loader.displayName = "Loader";
