import type { HTMLAttributes } from "react";
import { useState } from "react";

import { Icon } from "../../../atoms/Icon";
import { navbarHeight } from "../styles";
import { List, Menu, ToggleButton } from "./styles";

type VerticalProps = {
  /**
   * Allows controlling open state for the vertical menu.
   */
  isOpen?: boolean;
  /**
   * Function to control/set open value.
   */
  onOpenChange?: (open: boolean) => void;
} & HTMLAttributes<HTMLDivElement>;

/**
 * Header.Vertical - Menu displays for the mobile devices.
 */
export function Vertical({
  children,
  isOpen: controlledOpen,
  onOpenChange: setControlledOpen,
}: VerticalProps) {
  const [uncontrolledOpen, setUncontrolledOpen] = useState(controlledOpen);

  const isOpen = controlledOpen ?? uncontrolledOpen;
  const setIsOpen = setControlledOpen ?? setUncontrolledOpen;

  return (
    <>
      {!isOpen ? (
        <ToggleButton
          variant="inline"
          after={<Icon icon="menu" />}
          onClick={() => setIsOpen(true)}
          aria-expanded={false}
          aria-controls="HeaderVerticalMenu"
        />
      ) : (
        <ToggleButton
          variant="inline"
          before={<Icon icon="close" />}
          onClick={() => setIsOpen(false)}
          aria-expanded={true}
          aria-controls="HeaderVerticalMenu"
        />
      )}
      {isOpen && (
        // Every position property needs to be added using style prop so FloatingOverlay can calculate position
        <Menu
          id="HeaderVerticalMenu"
          data-testid="HeaderVerticalMenu"
          lockScroll
          style={{ top: navbarHeight }}
        >
          <List>{children}</List>
        </Menu>
      )}
    </>
  );
}
