import type { HTMLAttributes } from "react";
import React from "react";

import type { pegiIcons } from "../../../design-tokens/icons";
import { PegiDescription, PegiDescriptionBlock, PegiIcon, PegiTitle, Root } from "./styles";

export type PegiProps = HTMLAttributes<HTMLDivElement> & {
  icon: keyof typeof pegiIcons;
  title: string;
};

export function Pegi({ icon, title, children, ...args }: PegiProps) {
  return (
    <Root data-testid={`Multiplex${Pegi.name}`} {...args}>
      <PegiIcon icon={icon} />
      <PegiDescriptionBlock>
        <PegiTitle>{title}</PegiTitle>
        <PegiDescription>{children}</PegiDescription>
      </PegiDescriptionBlock>
    </Root>
  );
}
