import { createContext, RefObject } from "react";

export const TabNavContext = createContext<{
  activeTab: string;
  setActive: (id: string) => void;
  focusPrevious: (id: string) => void;
  focusNext: (id: string) => void;
  registerItem: (id: string, ref: RefObject<HTMLLIElement>) => void;
  unregisterItem: (id: string) => void;
} | null>(null);
