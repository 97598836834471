import { Root } from "./styles";
import type { HorizontalProps } from "./types";

/**
 * Header.Horizontal - hosts Header.HorizontalItem elements
 */
export function Horizontal({ children, position, ...args }: HorizontalProps) {
  return (
    <Root data-testid={`Header${Horizontal.name}`} position={position} {...args}>
      {children}
    </Root>
  );
}
