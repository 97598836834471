"use client";

import styled from "@emotion/styled";
import { rem } from "polished";

import { Icon } from "../../atoms/Icon";
import { colors } from "../../design-tokens/colors";
import { device, globalDecorationMaxWidth, sizes } from "../../design-tokens/dimensions";
import { ContentFrame } from "../../molecules/ContentFrame";
import { contentFrameMaxWidth } from "../../molecules/ContentFrame/styles";

export const Root = styled.div`
  align-items: center;
  background: ${colors.blueDeep};
  display: flex;
  justify-content: center;
  max-width: 100%;
  min-height: 100vh;
  padding: 0;
  position: relative;
  width: 100%;

  @media (${device.xsmall}) {
    height: 100%;
    max-width: ${rem(globalDecorationMaxWidth)};
    min-width: 100%;
    padding: ${sizes.s24.rem} 0;
  }
`;

export const BackgroundContainer = styled.div`
  bottom: 0;
  left: 0;
  min-height: 100%;
  padding: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;

  @media (${device.xsmall}) {
    padding: ${sizes.s48.rem} 0;
  }
`;

export const Box = styled(ContentFrame)`
  margin: 0 auto;
  position: relative;
  text-align: center;
`;

export const BoxLogos = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  margin: ${sizes.s8.rem} auto;
  max-width: ${rem(contentFrameMaxWidth)};
  width: 100%;
`;

export const BoxJagexLogo = styled(Icon)`
  background-size: auto;
  margin: 0 ${sizes.s24.rem};

  @supports (mask: var(--mask-icon-image)) {
    mask-size: auto;
  }
`;
