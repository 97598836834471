{
  "footer": {
    "contact": "Kontakt",
    "gender": "Bericht zum geschlechtsspezifischen Lohngefälle",
    "legal": "Rechtliches",
    "do-not-sell": {
      "text": "Dem Verkauf und Teilen meiner personenbezogenen Daten widersprechen",
      "title": "Jagex verkauft keine personenbezogenen Daten, wie der Begriff 'verkaufen' allgemein verstanden wird. Wir erlauben unseren Partnern nicht, Ihre personenbezogenen Daten für Werbe-, Marketing- und Analysezwecke zu verwenden. Über diesen Link gelangen Sie zu unserer Datenschutzrichtlinie mit mehr Informationen darüber, wie Sie sich abmelden können."
    },
    "terms": "Allgemeine Geschäftsbedingungen",
    "privacy": "Datenschutzrichtlinie",
    "cookie": "Cookie-Einstellungen",
    "copyright": "Copyright © 1999 - {{year}} Jagex Ltd. 220 Science Park, Cambridge, CB4 0WA, Großbritannien",
    "logo-nav": "Unternehmenswebsites",
    "logo-title": "Jagex corporate website",
    "contact-title": "Contact Jagex",
    "gender-title": "Gender Pay Gap Report",
    "legal-title": "View Jagex's legal information",
    "terms-title": "View Jagex's terms & conditions",
    "privacy-title": "View Jagex's privacy policy",
    "cookie-title": "View Jagex's cookie policy"
  }
}
