import type React from "react";
import type { OptionHTMLAttributes } from "react";

import { SpacerElement } from "./styles";

export type SpacerProps = OptionHTMLAttributes<HTMLOptionElement>;

export const Spacer = (props: SpacerProps): JSX.Element => (
  <SpacerElement disabled {...props} data-testid={Spacer.name} />
);
