import { forwardRef } from "react";

import { Icon } from "../../../atoms/Icon";
import { sizes } from "../../../design-tokens/dimensions";
import type { flatIcons } from "../../../design-tokens/icons";
import { Root } from "./styles";

type SocialLinkProps = {
  /**
   * Icon name from the allowed list.
   */
  icon: keyof typeof flatIcons;
  /**
   * URL destination of the link.
   */
  href: string;
};

/**
 * Renders a compact {@link SocialLink} for use in the {@link BoxFlow} interface
 */
export const SocialLink = forwardRef<HTMLAnchorElement, SocialLinkProps>(function (
  { icon, href, ...rest },
  propRef
): JSX.Element {
  return (
    <Root
      ref={propRef}
      href={href}
      variant="secondary"
      data-testid={`BoxFlow${SocialLink.displayName}`}
      {...rest}
    >
      <Icon icon={icon} height={sizes.s32.rem} />
    </Root>
  );
});

SocialLink.displayName = "SocialLink";
