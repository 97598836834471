"use client";

import styled from "@emotion/styled";
import { rem } from "polished";

import { colors, ColorValue } from "../../design-tokens/colors";
import { sizes } from "../../design-tokens/dimensions";
import { borderRadii } from "../../design-tokens/effects";
import type { MenuProps, MenuVariant } from "./Menu";

const variants = {
  default: colors.blueDeep,
  light: colors.baseMid,
} satisfies Record<MenuVariant, ColorValue>;

export const MenuList = styled.ul<Pick<MenuProps, "width" | "variant">>`
  background: ${({ variant }) => variants[variant || "default"]};
  border: ${rem(1)} solid ${colors.baseMid};
  border-radius: ${borderRadii.r8};
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  list-style: none;
  margin: 0;
  padding: ${sizes.s8.rem} 0;
  width: ${({ width }) => (width ? rem(width) : "100%")};

  li {
    &:last-child {
      border-bottom: 0;
    }
  }
`;
