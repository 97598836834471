import type React from "react";
import type { HTMLAttributes } from "react";

import { Root } from "./styles";

export type ContentProps = {
  /**
   * Whether the Content should take up the full height of its container
   */
  fullHeight?: boolean;
} & HTMLAttributes<HTMLDivElement>;

/**
 *  Entity that displays a title, a line of text and an image
 */
export function Content({ children, fullHeight = false, ...rest }: ContentProps) {
  return (
    <Root data-testid={Content.name} fullHeight={fullHeight} {...rest}>
      {children}
    </Root>
  );
}
