import { HTMLAttributes, PointerEvent, ReactElement } from "react";

import { Root } from "./styles";
import { ThumbnailImage, ThumbnailImageProps } from "./ThumbnailImage/ThumbnailImage";

export type ThumbnailProps = {
  /**
   * Single `Thumbnail.Image` to pass to display.
   */
  children: ReactElement<ThumbnailImageProps>;
  /**
   * Determines the state of the aria-current label.
   */
  isActive: boolean;
  /**
   * Action to perform when the thumbnail is clicked.
   */
  onClick: (e: PointerEvent<HTMLDivElement>) => void;
  /**
   * Action to perform when the thumbnail is focussed and receives a key down event.
   */
  onKeyDown: (e: KeyboardEvent) => void;
} & HTMLAttributes<HTMLDivElement>;

export function Thumbnail({ isActive, onClick, onKeyDown, children, ...rest }: ThumbnailProps) {
  return (
    <Root
      data-testid={Thumbnail.name}
      aria-current={isActive}
      onClick={onClick}
      onKeyDown={onKeyDown}
      {...rest}
    >
      {children}
    </Root>
  );
}

/**
 * Composite component structure
 */
Thumbnail.Image = ThumbnailImage;
