import type React from "react";
import type { HTMLAttributes } from "react";

import { Root } from "./styles";

export type ImageProps = {
  /**
   * Specifies the path to the image
   */
  src: string;
  /**
   * Specifies an alternate text for an image
   */
  alt: string;
} & HTMLAttributes<HTMLImageElement>;

export function Image({ src, alt, ...rest }: ImageProps) {
  return <Root data-testid={Image.name} alt={alt} src={src} {...rest} />;
}
