import { colors } from "../../../design-tokens/colors";
import { sizes } from "../../../design-tokens/dimensions";
import { StyledJagexIcon, StyledJagexLogo } from "./styles";

/**
 * Header.JagexLogo - displays jagex logo on desktop and jagex icon on mobile
 */
export function JagexLogo() {
  return (
    <>
      <StyledJagexIcon icon="jagex-flat" color={colors.white} />
      <StyledJagexLogo logo="jagex-full-flat" height={sizes.s32.raw} width={110} />
    </>
  );
}
