import { ClassNames } from "@emotion/react";
import type { HTMLAttributes, ReactElement } from "react";
import React, { useState } from "react";

import { LabelIcon, Root } from "./styles";

export const VerticalItemClassName = "VerticalItem";

export type VerticalItemProps = {
  /**
   * Whether item is active.
   */
  isActive?: boolean;
  /**
   * Accepts max two children first the label element Header.Button or Header.Link, second for the submenu Header.VerticalSubmenu.
   */
  children: ReactElement | [ReactElement, ReactElement | undefined | null];
  /**
   * Whether submenu is permanently expanded and cannot be collapsed.
   */
  alwaysExpanded?: boolean;
};

/**
 * Header.VerticalItem - host for the Link, Button or any navigation element must be child of Vertical component.
 */
export function VerticalItem({
  children,
  className,
  alwaysExpanded,
  ...args
}: VerticalItemProps & Omit<HTMLAttributes<HTMLLIElement>, "children">) {
  const [open, setOpen] = useState(!!alwaysExpanded);
  const [label, submenu] = React.Children.toArray(children) as [ReactElement, ReactElement];

  function toggleSubmenu() {
    if (!alwaysExpanded) {
      setOpen((o) => !o);
    }
  }

  return (
    <ClassNames>
      {({ cx }) => (
        <Root
          data-testid={`Header${VerticalItem.name}`}
          className={cx(VerticalItemClassName, className)}
          hasSubmenu={!!submenu}
          {...args}
        >
          {React.cloneElement(
            label,
            {
              "aria-expanded": open || undefined,
              onClick: toggleSubmenu,
              ...label.props,
            },
            label.props.children,
            submenu && !alwaysExpanded ? <LabelIcon isOpen={open} icon={"arrow-down"} /> : null
          )}

          {submenu && open
            ? React.cloneElement(submenu, {
                ...submenu.props,
                "aria-hidden": open || undefined,
              })
            : null}
        </Root>
      )}
    </ClassNames>
  );
}
