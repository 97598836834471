"use client";

import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { rem, transparentize } from "polished";

import { Icon } from "../../../../atoms/Icon";
import { colors } from "../../../../design-tokens/colors";
import { typography } from "../../../../design-tokens/typography";
import { HeaderButtonClassName } from "../../Button/Button";
import { HeaderLinkClassName } from "../../Link/Link";
import { clickableItemHorizontalPadding, verticalItemHeight } from "../../styles";
import type { VerticalItemProps } from "./VerticalItem";

export const Root = styled.li<VerticalItemProps & { hasSubmenu?: boolean }>`
  border-bottom: 1px solid ${transparentize(0.9, colors.white)};
  font-size: ${typography.sizes[18]};
  position: relative;
  width: 100%;

  ${({ isActive }) =>
    isActive &&
    css`
      &::after {
        background-color: ${colors.blueLight};
        content: "";
        display: block;
        height: ${verticalItemHeight};
        position: absolute;
        top: 0;
        width: ${rem(4)};
      }
    `}

  .${HeaderLinkClassName}, .${HeaderButtonClassName} {
    justify-content: flex-start;
    width: 100%;
    height: ${verticalItemHeight};
  }
`;

export const LabelIcon = styled(Icon)<{ isOpen?: boolean }>`
  cursor: pointer;
  position: absolute;
  right: ${clickableItemHorizontalPadding};
  ${({ isOpen }) => (isOpen ? "transform: rotate(180deg)" : "")};
  transition: 0.2s;
`;
