"use client";

import styled from "@emotion/styled";
import { rem } from "polished";

import { lineClamp } from "../../../utils/line-clamp";
import { colors } from "../../design-tokens/colors";
import { sizes } from "../../design-tokens/dimensions";
import { borderRadii } from "../../design-tokens/effects";
import { typography } from "../../design-tokens/typography";

export const Root = styled.span<{ entitySize: "m" | "l" }>`
  align-items: center;
  background: ${colors.baseMid};
  border-radius: ${borderRadii.r4};
  display: inline-flex;
  font-size: ${({ entitySize }) =>
    entitySize === "m" ? typography.sizes["14"] : typography.sizes["16"]};
  gap: ${rem(10)};
  height: ${({ entitySize }) => (entitySize === "m" ? sizes.s24.rem : sizes.s40.rem)};
  justify-content: center;
  letter-spacing: ${({ entitySize }) =>
    entitySize === "m" ? typography.letterSpacing["2"] : typography.letterSpacing["5"]};
  line-height: ${({ entitySize }) =>
    entitySize === "m" ? typography.lineHeights["20"] : typography.lineHeights["24"]};
  margin: 0 ${sizes.s8.rem};
  max-width: ${sizes.s512.rem};
  padding: ${({ entitySize }) =>
    entitySize === "m" ? `${sizes.s4.rem} ${sizes.s8.rem}` : `${sizes.s8.rem} ${sizes.s16.rem}`};
  text-align: center;
`;

export const Text = styled.span`
  color: ${colors.white};
  font-weight: ${typography.weights.normal};

  ${lineClamp(typography.lineHeights["32"], 1, sizes.s8.rem)};
`;
