"use client";

import styled from "@emotion/styled";
import { rem } from "polished";

import { Button } from "../../atoms/Button";
import { Heading } from "../../atoms/Heading";
import { Paragraph } from "../../atoms/Paragraph";
import { SmallText } from "../../atoms/SmallText";
import { Tag } from "../../atoms/Tag";
import { colors } from "../../design-tokens/colors";
import { device, globalContentMaxWidth, sizes } from "../../design-tokens/dimensions";
import { borderRadii, boxShadows, glazes } from "../../design-tokens/effects";
import { typography } from "../../design-tokens/typography";
import { ProductCardVariant } from "./ProductCard";

export const Root = styled.article`
  align-items: flex-start;
  align-self: stretch;
  background-color: ${colors.baseDark};
  border-radius: ${borderRadii.r8};
  box-shadow: ${boxShadows.float};
  display: grid;
  gap: ${sizes.s16.rem};
  margin: 0 auto;
  max-width: ${rem(globalContentMaxWidth)};
  overflow: hidden;
  padding: ${sizes.s24.rem};
  width: 100%;

  @media (${device.small}) {
    grid-template-columns: auto 1fr;
    gap: ${sizes.s24.rem};
  }
`;

export const ImageWrapper = styled.figure<{ variant: ProductCardVariant }>`
  aspect-ratio: 4/3;
  flex-shrink: 0;
  margin: 0;
  overflow: hidden;
  position: relative;
  width: 100%;

  @media (${device.small}) {
    width: ${({ variant }) => (variant == "compact" ? rem(152) : rem(264))};
  }
`;

export const Overlay = styled.div`
  background: ${glazes.blackVertical1};
  height: 100%;
  position: absolute;
  top: 0;
  width: 100%;
`;

export const ContentWrapper = styled.div<{ variant: ProductCardVariant }>`
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: ${sizes.s24.rem};
  height: 100%;
  width: 100%;

  @media (${device.small}) {
    flex-direction: ${({ variant }) => (variant == "compact" ? "row" : "column")};
  }
`;

export const ProductDetails = styled.div<{ variant: ProductCardVariant }>`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: ${({ variant }) => (variant == "compact" ? sizes.s16.rem : sizes.s24.rem)};
  width: 100%;
`;

export const ProductFooter = styled.footer<{ variant: ProductCardVariant }>`
  align-items: center;
  display: flex;
  flex: 0 0;
  flex-direction: column;
  justify-content: flex-end;
  margin-top: auto;
  width: 100%;

  @media (${device.small}) {
    flex-direction: ${({ variant }) => (variant == "compact" ? "column" : "row")};
  }
`;

export const Actions = styled.div`
  align-items: center;
  display: flex;
  flex: 0 0;
  flex-direction: column-reverse;
  gap: ${sizes.s8.rem};
  width: 100%;

  @media (${device.small}) {
    flex-direction: row-reverse;
    gap: ${sizes.s16.rem};
  }
`;

export const CardHeading = styled(Heading)`
  font-size: ${typography.sizes["20"]};
  line-height: ${typography.sizes["32"]};
  max-width: none;
`;

export const CardTag = styled(Tag)`
  &:first-of-type {
    margin-left: 0;
  }
`;

export const PriceLabel = styled.span`
  color: ${colors.white};
  font-size: ${typography.sizes["20"]};
  letter-spacing: ${typography.letterSpacing["5"]};
  line-height: ${typography.lineHeights["32"]};
  margin: ${sizes.s4.rem} 0;
  white-space: nowrap;
`;

export const Note = styled(SmallText)`
  align-items: center;
  display: flex;
  flex-grow: 1;
  gap: ${sizes.s8.rem};
  order: 1;

  @media (${device.small}) {
    flex-basis: 100%;
    order: 0;
  }
`;

export const ProductContent = styled(Paragraph)`
  margin: 0;
`;

export const BuyButton = styled(Button)`
  width: 100%;

  @media (${device.small}) {
    width: unset;
  }
`;
