"use client";

import styled from "@emotion/styled";

import { Container } from "../../../atoms/Container";
import { Paragraph } from "../../../atoms/Paragraph";
import { device, sizes } from "../../../design-tokens/dimensions";
import { breakpoints } from "../../../design-tokens/dimensions";
import { boxShadows } from "../../../design-tokens/effects";

export const Root = styled(Container)`
  align-items: center;
  box-shadow: ${boxShadows.deboss};
  display: flex;
  flex-direction: column;
  gap: ${sizes.s8.rem};
  height: 100%;
  justify-content: center;
  margin: 0 auto;
  max-width ${sizes.globalContentMaxWidth};
  position: relative;
  text-align: center;
  width: 100%;
  @media (${device.min}) {
    line-height: ${sizes.s16.rem};
  }
`;

export const ModalParagraph = styled(Paragraph)`
  margin: 0;
  @media (max-width: ${breakpoints.min}px) {
    line-height: ${sizes.s16.rem};
  }
`;
