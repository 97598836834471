import type { HTMLAttributes } from "react";
import { ReactNode } from "react";

import { Aside } from "./Aside/Aside";
import { Main } from "./Main/Main";
import { Pegi } from "./Pegi";
import { ProductDetails } from "./ProductDetails";
import { MultiplexBackground, MultiplexContainer, MultiplexContent, Root } from "./styles";

type MultiplexProps = HTMLAttributes<HTMLDivElement> & {
  /**
   * Container for background graphics such as images and video.
   */
  backgroundAssets?: ReactNode;
};

/**
 * A large container with an optional background image and Aside component.
 */
export function Multiplex({ backgroundAssets, children, ...rest }: MultiplexProps): JSX.Element {
  return (
    <Root data-testid={Multiplex.name} {...rest}>
      {backgroundAssets && (
        <MultiplexBackground data-testid={`${Multiplex.name}Background`}>
          {backgroundAssets}
        </MultiplexBackground>
      )}
      <MultiplexContainer>
        <MultiplexContent>{children}</MultiplexContent>
      </MultiplexContainer>
    </Root>
  );
}

Multiplex.Aside = Aside;
Multiplex.Main = Main;
Multiplex.Pegi = Pegi;
Multiplex.ProductDetails = ProductDetails;
