import type { HTMLAttributes, ReactNode } from "react";

import { CopyBox as Root, CopyBoxFrame, CopyBoxInner, copyBoxNoFrameClassName } from "../styles";

export type CopyBoxProps = HTMLAttributes<HTMLDivElement> & {
  /**
   * Does the copy have a ContentFrame behind it
   */
  hasFrame?: boolean;
  /**
   * The text and buttons to be shown
   */
  children: ReactNode;
};

/**
 * Used to display text and buttons inside a {@link ContentPlate}
 */
export function CopyBox({ hasFrame = false, children, ...rest }: CopyBoxProps) {
  return (
    <Root
      className={hasFrame ? undefined : copyBoxNoFrameClassName}
      data-testid={CopyBox.name}
      {...rest}
    >
      <CopyBoxInner>
        <CopyBoxFrame hasFrame={hasFrame}>{children}</CopyBoxFrame>
      </CopyBoxInner>
    </Root>
  );
}
