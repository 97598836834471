import type React from "react";
import type { OptionHTMLAttributes } from "react";

import { OptionElement } from "./styles";

export type OptionProps = {
  /**
   * Whether this option is a placeholder. If so, it will be hidden from the dropdown list
   */
  isPlaceholder?: boolean;
} & OptionHTMLAttributes<HTMLOptionElement>;

export const Option = ({ isPlaceholder, ...props }: OptionProps): JSX.Element => {
  if (isPlaceholder) {
    return <OptionElement {...props} disabled hidden />;
  }

  return <OptionElement {...props} />;
};
