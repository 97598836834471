{
  "icons": {
    "regular-icons": {
      "add": {
        "title": "Ajouter",
        "alt": "Le signe plus"
      },
      "add-character": {
        "title": "Ajouter un personnage",
        "alt": "Un portrait en buste avec un signe plus"
      },
      "app-code": {
        "title": "Code via appli",
        "alt": "Un téléphone avec un bouclier"
      },
      "arrow-down": {
        "title": "Flèche vers le bas",
        "alt": "Une flèche pointant vers le bas"
      },
      "arrow-left": {
        "title": "Flèche vers la gauche",
        "alt": "Une flèche pointant vers la gauche"
      },
      "arrow-right": {
        "title": "Flèche vers la droite",
        "alt": "Une flèche pointant vers la droite"
      },
      "arrow-up": {
        "title": "Flèche vers le haut",
        "alt": "Une flèche pointant vers le haut"
      },
      "backup-code": {
        "title": "Code de secours",
        "alt": "Un bouclier avec une clé"
      },
      "bank-card": {
        "title": "Carte bancaire",
        "alt": "Une carte bancaire"
      },
      "close": {
        "title": "Fermer",
        "alt": "Une croix"
      },
      "character": {
        "title": "Personnage",
        "alt": "Un personnage avec une épée et un bouclier"
      },
      "checkmark": {
        "title": "Coche",
        "alt": "Une coche"
      },
      "cookie": {
        "title": "",
        "alt": ""
      },
      "data": {
        "title": "Données",
        "alt": "Une empreinte digitale"
      },
      "edit": {
        "title": "Modifier",
        "alt": "Un crayon"
      },
      "email": {
        "title": "Adresse électronique",
        "alt": "Une enveloppe fermée"
      },
      "email-code": {
        "title": "Code via courriel",
        "alt": "Une enveloppe avec un bouclier"
      },
      "error": {
        "title": "Erreur",
        "alt": "Un point d'exclamation dans un cercle"
      },
      "external-link": {
        "title": "Icône de lien externe",
        "alt": "Une flèche sortant d'un carré"
      },
      "favourite": {
        "title": "Favori",
        "alt": "Une étoile"
      },
      "feedback": {
        "title": "Avis et commentaires",
        "alt": "Une bulle et un stylo"
      },
      "globe": {
        "title": "Mappemonde",
        "alt": "Une mappemonde"
      },
      "hidden": {
        "title": "Masqué",
        "alt": "Un œil barré d'une ligne"
      },
      "info": {
        "title": "Infos",
        "alt": "La lettre i dans un cercle"
      },
      "invalid": {
        "title": "Invalide",
        "alt": "La lettre x dans un cercle"
      },
      "legal": {
        "title": "Documents juridiques",
        "alt": "Une balance"
      },
      "link": {
        "title": "Associer",
        "alt": "Un chaînon"
      },
      "logout": {
        "title": "Se déconnecter",
        "alt": "Une flèche indiquant une porte"
      },
      "menu": {
        "title": "Menu",
        "alt": "Trois lignes horizontales empilées"
      },
      "minus": {
        "title": "Moins",
        "alt": "Le signe moins"
      },
      "options": {
        "title": "Options",
        "alt": "Trois points"
      },
      "pause": {
        "title": "Pause",
        "alt": "Deux lignes parallèles verticales"
      },
      "phone": {
        "title": "Téléphone",
        "alt": "Un smartphone"
      },
      "pin": {
        "title": "Épingler",
        "alt": "Une épingle"
      },
      "play": {
        "title": "Lire",
        "alt": "Triangle pointant vers la droite"
      },
      "plus": {
        "title": "Plus",
        "alt": "Le signe plus"
      },
      "profile": {
        "title": "Profil",
        "alt": "Un portrait"
      },
      "safety": {
        "title": "Sécurité",
        "alt": ""
      },
      "search": {
        "title": "Chercher",
        "alt": "Une loupe"
      },
      "settings": {
        "title": "Options",
        "alt": "Un engrenage"
      },
      "store": {
        "title": "Boutique",
        "alt": "Un sac à main"
      },
      "success": {
        "title": "Succès",
        "alt": "Une coche dans un cercle"
      },
      "visible": {
        "title": "Visible",
        "alt": "Un œil"
      },
      "uninstall": {
        "title": "Désinstaller",
        "alt": "Une poubelle"
      },
      "web": {
        "title": "Internet",
        "alt": "Une mappemonde"
      }
    },
    "social-media-icons": {
      "amazon-flat": {
        "title": "Amazon",
        "alt": "Icône d'Amazon"
      },
      "apple-flat": {
        "title": "Apple",
        "alt": "Icône d'Apple"
      },
      "facebook-flat": {
        "title": "Facebook",
        "alt": "Icône de Facebook"
      },
      "google-flat": {
        "title": "Google",
        "alt": "Icône de Google"
      },
      "instagram-flat": {
        "title": "Instagram",
        "alt": "Icône d'Instagram"
      },
      "steam-flat": {
        "title": "Steam",
        "alt": "Icône de Steam"
      },
      "twitter-flat": {
        "title": "Twitter",
        "alt": "Icône de Twitter"
      }
    },
    "brand-flat-icons": {
      "android-flat": {
        "title": "Android",
        "alt": "Icône de Android"
      },
      "axeso5-flat": {
        "alt": "",
        "title": "Axeso5"
      },
      "boa-compra-flat": {
        "alt": "",
        "title": "Boa Compra"
      },
      "epic-games-flat": {
        "title": "Epic Games",
        "alt": "Icône d'Epic Games"
      },
      "ios-flat": {
        "title": "iOS",
        "alt": "Icône de iOS"
      },
      "jagex-flat": {
        "title": "Jagex",
        "alt": "Icône de Jagex"
      },
      "jagex-pin-flat": {
        "title": "Jagex",
        "alt": "Icône de Jagex"
      },
      "linux-flat": {
        "title": "Linux",
        "alt": "Icône de Linux"
      },
      "steam-round-flat": {
        "title": "Steam",
        "alt": "Icône de Steam"
      },
      "melvor-idle-flat": {
        "title": "Melvor Idle",
        "alt": "Icône de Melvor Idle"
      },
      "old-school-runescape-flat": {
        "title": "Old School RuneScape",
        "alt": "Icône d'Old School RuneScape"
      },
      "runescape-flat": {
        "title": "RuneScape",
        "alt": "Icône de RuneScape"
      },
      "runescape-lite-flat": {
        "title": "RuneScape Lite",
        "alt": "Icône de RuneScape Lite"
      },
      "space-punks-flat": {
        "title": "Space Punks",
        "alt": "Icône de Space Punks"
      },
      "this-means-warp-flat": {
        "title": "This Means Warp",
        "alt": "Icône de This Means Warp"
      },
      "windows-flat": {
        "title": "Windows",
        "alt": "Icône de Windows"
      }
    },
    "brand-icons-color": {
      "old-school-runescape-color": {
        "title": "Old School RuneScape",
        "alt": "Icône d'Old School RuneScape"
      },
      "runescape-color": {
        "title": "RuneScape",
        "alt": "Icône de RuneScape"
      }
    },
    "pegi-icons": {
      "pegi-age-03": {
        "title": "Icône PEGI 3",
        "alt": "Icône PEGI 3"
      },
      "pegi-age-07": {
        "title": "Icône PEGI 7",
        "alt": "Icône PEGI 7"
      },
      "pegi-age-12": {
        "title": "Icône PEGI 12",
        "alt": "Icône PEGI 12"
      },
      "pegi-age-16": {
        "title": "Icône PEGI 16",
        "alt": "Icône PEGI 16"
      },
      "pegi-age-18": {
        "title": "Icône PEGI 18",
        "alt": "Icône PEGI 18"
      },
      "pegi-content-discrimination": {
        "title": "Icône PEGI avertissant de la présence d'un contenu discriminatoire",
        "alt": "Icône PEGI avertissant de la présence d'un contenu discriminatoire"
      },
      "pegi-content-drugs": {
        "title": "Icône PEGI avertissant d'un contenu qui mentionne ou décrit l'utilisation de drogue",
        "alt": "Icône PEGI avertissant d'un contenu qui mentionne ou décrit l'utilisation de drogue"
      },
      "pegi-content-fear": {
        "title": "Icône PEGI avertissant d'un contenu susceptible de faire peur",
        "alt": "Icône PEGI avertissant d'un contenu susceptible de faire peur"
      },
      "pegi-content-gambling": {
        "title": "Icône PEGI avertissant d'un contenu lié aux jeux d'argent",
        "alt": "Icône PEGI avertissant d'un contenu lié aux jeux d'argent"
      },
      "pegi-content-language": {
        "title": "Icône PEGI avertissant de la présence d'un langage grossier",
        "alt": "Icône PEGI avertissant de la présence d'un langage grossier"
      },
      "pegi-content-purchases": {
        "title": "Icône PEGI avertissant de la présence d'achats",
        "alt": "Icône PEGI avertissant de la présence d'achats"
      },
      "pegi-content-sex": {
        "title": "Icône PEGI avertissant d'un contenu lié au sexe",
        "alt": "Icône PEGI avertissant d'un contenu lié au sexe"
      },
      "pegi-content-violence": {
        "title": "Icône PEGI avertissant de la présence de scènes de violence",
        "alt": "Icône PEGI avertissant de la présence de scènes de violence"
      }
    }
  }
}
