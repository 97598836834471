import type { HTMLAttributes } from "react";
import { forwardRef } from "react";

import { useTranslate } from "../../../utils/translate";
import type { Dimension } from "../../design-tokens/dimensions";
import { allIcons, flatIcons } from "../../design-tokens/icons";
import { Root } from "./styles";
import { translations } from "./translations";

export type IconProps = {
  /**
   * Icon name from the allowed list.
   */
  icon: keyof typeof allIcons;
  /**
   * Colour of the icon. See {@link @jagex-pp/jds#colors}. Coloured icons can't change their colour.
   */
  color?: string;
  /**
   * Height of the icon.
   */
  height?: Dimension;
} & HTMLAttributes<HTMLSpanElement>;

/**
 * Renders an icon with a customisable colour.
 */
export const Icon = forwardRef<HTMLSpanElement, IconProps>(function Icon(
  { icon, color, height, ...rest },
  propRef
): JSX.Element {
  const t = useTranslate(translations);

  const { alt, src, title } = allIcons[icon];

  const isFlatIcon = icon in flatIcons;

  return (
    <Root
      ref={propRef}
      data-testid={Icon.name}
      role="img"
      title={t(title)}
      aria-label={t(alt)}
      src={src}
      color={color}
      isFlatIcon={isFlatIcon}
      height={height}
      {...rest}
    />
  );
});

Icon.displayName = "Icon";
