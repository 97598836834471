import { forwardRef, Ref } from "react";

import { Base, LinkProps } from "../Base";

/**
 * Link that is not restricted to Jagex-only domains. Needs to be wrapped inside JdsProvider in order to work.
 * This entity should only be used when the consumer has made it clear to the customer that they proceed at their own risk.
 */
export const LinkExternal = forwardRef(
  ({ href, ...rest }: LinkProps, ref: Ref<HTMLAnchorElement>): JSX.Element => (
    <Base href={href} ref={ref} data-testid={LinkExternal.displayName} {...rest} />
  )
);

LinkExternal.displayName = "LinkExternal";
