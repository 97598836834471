"use client";

import styled from "@emotion/styled";

import { Button } from "../../../atoms/Button";
import { sizes } from "../../../design-tokens/dimensions";

export const Root = styled(Button)`
  --mask-icon-color: inherit;
  padding: ${sizes.s16.rem};
  position: absolute;
  right: ${sizes.s24.rem};
  top: ${sizes.s16.rem};
`;
