import type { HTMLAttributes } from "react";
import { useContext, useEffect, useMemo, useRef } from "react";

import { MenuContext } from "../MenuContext";
import { Root } from "./styles";

export type ItemProps = {
  /**
   * Unique id
   */
  uid: string;
} & HTMLAttributes<HTMLLIElement>;

export const Item = ({ uid, ...rest }: ItemProps) => {
  const ref = useRef<HTMLLIElement>(null);
  const { registerItem, unregisterItem, getItemProps } = useContext(MenuContext)!;

  useEffect(() => {
    if (uid === undefined) {
      return undefined;
    }

    registerItem(uid, { ref });

    return () => unregisterItem(uid);
  }, [uid, registerItem, unregisterItem]);

  const contextProps = useMemo(() => getItemProps(uid), [uid, getItemProps]);

  return (
    <Root ref={ref} role="menuitem" data-testid={Item.displayName} {...rest} {...contextProps} />
  );
};

Item.displayName = "Item";
