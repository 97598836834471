const main = {
  white: "#ffffff",
  blueDeep: "#0b111a",
  blue: "#0c8ae6",
  blueLight: "#39acff",
};

const universal = {
  universalBase: "#1c1c1c",
  universalLight: "#ffffff05",
  universalDark: "#00000033",
  universalDeep: "#00000066",
};

const supporting = {
  red: "#e94d69",
  redLight: "#ff7790",
  orange: "#F5A52C",
  green: "#1ce5b6",
  greenLight: "#45f8cd",
};

const base = {
  baseBlack: "#000000",
  baseDark: "#0f1722",
  baseMid: "#212736",
  betaBase: "#3e4759",
  baseLightest: "#9ba0ab",
};

/**
 * @internal grouping of colours, for use in `colors.stories.mdx` only.
 */
export const _storybookColorGroupings = {
  main,
  universal,
  supporting,
  base,
};

/**
 * All colors supported by JDS.
 */
export const colors = {
  ...main,
  ...universal,
  ...supporting,
  ...base,
} as const;

export type ColorValue = (typeof colors)[keyof typeof colors];
