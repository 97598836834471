"use client";

import styled from "@emotion/styled";
import { FloatingOverlay } from "@floating-ui/react";

import { Button } from "../../../atoms/Button";
import { colors } from "../../../design-tokens/colors";
import { device, sizes } from "../../../design-tokens/dimensions";
import { boxShadows } from "../../../design-tokens/effects";
import { typography } from "../../../design-tokens/typography";
import { HeaderButtonClassName } from "../Button/Button";
import { HeaderLinkClassName } from "../Link/Link";
import { mobileNavBreakpoint, verticalSubmenuItemHeight } from "../styles";
import { VerticalItemClassName } from "./VerticalItem/VerticalItem";

export const ToggleButton = styled(Button)`
  --mask-icon-color: inherit;
  --mask-icon-size: ${sizes.s24.rem};

  margin-left: auto;

  @media (${device[mobileNavBreakpoint]}) {
    display: none;
  }
`;

export const Menu = styled(FloatingOverlay)`
  background: ${colors.blueDeep};

  @media (${device[mobileNavBreakpoint]}) {
    display: none;
  }
`;

export const List = styled.ul`
  display: flex;
  flex-direction: column;
  list-style: none;
  margin: 0;
  padding: 0;

  @media (${device[mobileNavBreakpoint]}) {
    display: none;
  }
`;

export const SubMenuRoot = styled.ul`
  box-shadow: ${boxShadows.inset};
  list-style: none;
  margin: 0;
  width: 100%;

  .${VerticalItemClassName} {
    border: none;
    font-size: ${typography.sizes[16]};

    .${HeaderLinkClassName}, .${HeaderButtonClassName} {
      height: ${verticalSubmenuItemHeight};
    }

    &:after {
      height: ${verticalSubmenuItemHeight};
    }
  }
`;
