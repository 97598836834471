{
  "logos" : {
    "flat-logos": {
      "epic-store-flat": {
        "title": "Epic Store",
        "alt": "Logo d'Epic Store"
      },
      "flying-wild-hog-flat": {
        "title": "Flying Wild Hog",
        "alt": "Logo de Flying Wild Hog"
      },
      "gamepires-flat": {
        "title": "Gamepires",
        "alt": "Logo de Gamepires"
      },
      "jagex-full-flat": {
        "title": "Jagex Games Ltd",
        "alt": "Un logo représentant une croix blanche suivie du nom Jagex"
      },
      "old-school-runescape-flat": {
        "title": "Old School RuneScape",
        "alt": "Logo d'Old School RuneScape"
      },
      "runescape-flat": {
        "title": "RuneScape",
        "alt": "Logo de RuneScape"
      }
    },
    "color-logos": {
      "old-school-runescape-color": {
        "title": "Old School RuneScape",
        "alt": "Logo d'Old School RuneScape"
      },
      "runescape-color": {
        "title": "RuneScape",
        "alt": "Logo de RuneScape"
      },
      "melvor-idle-color": {
        "title": "Melvor Idle",
        "alt": "Logo de Melvor Idle"
      }
    }
  }
}
