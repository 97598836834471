import {
  HTMLAttributes,
  KeyboardEventHandler,
  ReactNode,
  RefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";

import { Popper as Root, PopperProps } from "../../atoms/Popper";
import type { ItemProps } from "./Item";
import { Item } from "./Item";
import { MenuContext } from "./MenuContext";
import { MenuList } from "./styles";

export type MenuVariant = "default" | "light";

export type MenuItemContextProps = {
  /**
   * A key event handler
   */
  onKeyDown: KeyboardEventHandler<HTMLLIElement>;
  /**
   * The tab index order for the Item
   */
  tabIndex: number;
  /**
   * Style variation of the menu item
   */
  variant?: MenuVariant;
};

export type MenuItemMetadata = {
  /**
   * Item ref object
   */
  ref: RefObject<HTMLLIElement>;
};

export type MenuProps = {
  /**
   * HTML id attribute
   */
  id: string;
  /**
   * Whether the menu is open
   */
  open: boolean;
  /**
   * Define closing menu function if you want to close menu on hover/click away or Escape click.
   * It's result should be changing open state to false
   */
  setClose: () => void;
  /**
   * What element to anchor the menu too
   */
  anchorEl: HTMLElement | null;
  /**
   * The `Menu.Item(s)` to pass to display
   */
  children: ReactNode;
  /**
   * Positional offset to display the Menu
   */
  floatingOffset?: number;
  /**
   * Define menu placement in relation to anchor element
   */
  initialPlacement?: PopperProps["initialPlacement"];
  /**
   * Width for the menu
   */
  width?: number;
  /**
   * Style variation of the menu
   */
  variant?: MenuVariant;
} & Omit<HTMLAttributes<HTMLUListElement>, "children">;

export const Menu = ({
  id,
  children,
  open,
  anchorEl,
  setClose,
  floatingOffset,
  initialPlacement,
  variant = "default",
  ...rest
}: MenuProps) => {
  const menuRef = useRef<HTMLUListElement>(null);

  const [menuItems, setMenuItems] = useState<Record<string, MenuItemMetadata>>({});

  const registerItem = useCallback((uid: string, metadata: MenuItemMetadata) => {
    setMenuItems((previousState) => {
      const newState = { ...previousState };
      newState[uid] = metadata;

      return newState;
    });
  }, []);

  const unregisterItem = useCallback((uid: string) => {
    setMenuItems((previousState) => {
      const newState = { ...previousState };
      delete newState[uid];

      return newState;
    });
  }, []);

  useEffect(() => {
    if (open && anchorEl === document.activeElement && Object.keys(menuItems).length > 0) {
      Object.values(menuItems)[0].ref.current?.focus();
    }
  }, [menuItems, open, anchorEl]);

  const getItemProps = useCallback(
    (uid: ItemProps["uid"]): MenuItemContextProps => {
      const first = Object.keys(menuItems)[0] === uid;
      return {
        onKeyDown: (e) => {
          if (e.key === "Escape") {
            anchorEl?.focus();
          }

          if (e.key === "Tab") {
            setClose();
          }

          if (e.key === "ArrowDown") {
            e.preventDefault();
            (e.currentTarget.nextElementSibling as HTMLElement | null)?.focus();
          }

          if (e.key === "ArrowUp") {
            e.preventDefault();
            (e.currentTarget.previousElementSibling as HTMLElement | null)?.focus();
          }
        },
        tabIndex: first ? 0 : -1,
        variant: variant,
      };
    },
    [menuItems, variant, anchorEl, setClose]
  );

  const onOpenChange = (open: boolean) => {
    if (!open) {
      setClose();
    }
  };

  return (
    <Root
      anchorRef={anchorEl}
      data-testid={Menu.displayName}
      isOpen={open}
      floatingOffset={floatingOffset}
      onOpenChange={onOpenChange}
      initialPlacement={initialPlacement}
    >
      <MenuContext.Provider value={{ registerItem, unregisterItem, getItemProps }}>
        <MenuList tabIndex={-1} role="menu" id={id} ref={menuRef} variant={variant} {...rest}>
          {children}
        </MenuList>
      </MenuContext.Provider>
    </Root>
  );
};

Menu.displayName = "Menu";

/**
 * Composite component structure
 */
Menu.Item = Item;
