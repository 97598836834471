"use client";

import styled from "@emotion/styled";
import { rem } from "polished";

import { colors } from "../../design-tokens/colors";
import { globalDecorationMaxWidth, sizes } from "../../design-tokens/dimensions";

const getMarginStyle = (margin: HorizontalRuleMarginOptions): string => {
  switch (margin) {
    case "top":
      return `${sizes.s32.rem} auto 0`;
    case "bottom":
      return `0 auto ${sizes.s32.rem}`;
    case "both":
      return `${sizes.s32.rem} auto`;
    case "none":
      return `0 auto`;
  }
};

export type HorizontalRuleMarginOptions = "top" | "bottom" | "both" | "none";
export const Root = styled.hr<{ margin: HorizontalRuleMarginOptions }>`
  border: none;
  border-bottom: ${rem(1)} solid ${colors.betaBase};
  margin: ${({ margin }) => getMarginStyle(margin)};
  max-width: ${rem(globalDecorationMaxWidth)};
  width: 100%;
`;
