"use client";

import styled from "@emotion/styled";

import { colors } from "../../design-tokens/colors";
import { sizes } from "../../design-tokens/dimensions";
import { borderRadii } from "../../design-tokens/effects";
import { typography } from "../../design-tokens/typography";

export const OuterContainer = styled.label<{ fullWidth?: boolean }>`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  position: relative;
  width: ${({ fullWidth }) => (fullWidth ? "auto" : "max-content")};
`;

export const LabelText = styled.span<{ isInvalid?: boolean }>`
  color: ${({ isInvalid }) => (isInvalid ? colors.red : colors.white)};
  font-size: ${typography.sizes["14"]};
  letter-spacing: ${typography.letterSpacing["5"]};
  line-height: ${typography.lineHeights["20"]};
  margin-bottom: ${sizes.s8.rem};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const SelectContainer = styled.div`
  display: inline-block;
  position: relative;
  width: 100%;

  &::after {
    border-bottom: ${sizes.s2.rem} solid white;
    border-right: ${sizes.s2.rem} solid white;
    content: "";
    height: ${sizes.s8.rem};
    pointer-events: none;
    position: absolute;
    right: ${sizes.s24.rem};
    top: 50%;
    transform-origin: bottom right;
    transform: translateY(-50%) rotate(45deg);
    width: ${sizes.s8.rem};
  }
`;

export const SelectElement = styled.select`
  appearance: none;
  background-color: transparent;
  border-radius: ${borderRadii.r8};
  border: 1px solid ${colors.betaBase};
  color: ${colors.white};
  display: flex;
  font-family: inherit;
  font-size: ${typography.sizes["18"]};
  letter-spacing: ${typography.letterSpacing["2"]};
  padding: ${sizes.s16.rem} ${sizes.s40.rem} ${sizes.s16.rem} ${sizes.s16.rem};
  width: 100%;

  &:not([disabled]):hover {
    border-color: ${colors.baseLightest};
  }

  &[aria-invalid="true"] {
    border-color: ${colors.red};

    &:focus,
    &:hover {
      border-color: ${colors.redLight};
    }
  }

  &[disabled] {
    cursor: not-allowed;
    opacity: 0.5;
  }
`;

export const OptionElement = styled.option`
  background-color: ${colors.universalBase};
  color: ${colors.baseLightest};
`;

export const SpacerElement = styled(OptionElement)`
  font-size: ${sizes.s8.rem};
`;
