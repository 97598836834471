import deLogos from "../../../locales/de-DE/logos.json";
import enLogos from "../../../locales/en-GB/logos.json";
import frLogos from "../../../locales/fr-FR/logos.json";
import ptLogos from "../../../locales/pt-BR/logos.json";
import type { Locale, TranslationDef } from "../../../locales/types";

export const translations: Record<Locale, Pick<TranslationDef, "logos">> = {
  "en-GB": enLogos,
  "de-DE": deLogos,
  "fr-FR": frLogos,
  "pt-BR": ptLogos,
};
