"use client";

import styled from "@emotion/styled";
import { darken, rem } from "polished";
import { CSSProperties } from "react";

import { colors } from "../../design-tokens/colors";
import {
  device,
  globalContentMaxWidth,
  globalDecorationMaxWidth,
  sizes,
} from "../../design-tokens/dimensions";
import { glazes } from "../../design-tokens/effects";
import { ContentFrame } from "../../molecules/ContentFrame";
import { ContentPlateOrientation } from "./ContentPlate";

export const contentPlateFlipPoint = device.large;
export const imageBoxClassName = "contentPlateImageBox";
export const copyBoxNoFrameClassName = "contentPlateCopyBox";
const COPY_BOX_WIDTH_PERCENT = 50;
const IMAGE_BOX_VERTICAL_ORIENTATION_HEIGHT = 360;

/**
 * Generates a gradient based on the current colour and orientation
 * @param color - the base colour for the gradient
 * @param orientation - where the gradient starts - null for centre
 */
const generatePlateBackground = (
  color: CSSProperties["backgroundColor"],
  orientation?: ContentPlateOrientation
): CSSProperties["backgroundImage"] => {
  if (!color || color === "transparent" || color === "none") {
    return "none";
  }
  return `radial-gradient(at bottom ${
    orientation ? (orientation == "left" ? "right" : "left") : ""
  }, ${color}, ${darken(0.4, color)} 55%, ${darken(0.9, color)} 90%)`;
};

export const Root = styled.section<{ color: string; orientation: ContentPlateOrientation }>`
  background: ${({ color }) => generatePlateBackground(color)};
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin: 0 auto;
  max-width: ${rem(globalDecorationMaxWidth)};
  overflow: hidden;
  position: relative;

  @media (${contentPlateFlipPoint}) {
    background: ${({ color, orientation }) => generatePlateBackground(color, orientation)};
    height: ${rem(640)};
    min-height: ${rem(640)};

    &:has(.${copyBoxNoFrameClassName})::after {
      background: ${({ orientation }) => glazes[orientation]};
      bottom: auto;
      content: "";
      height: 100%;
      ${({ orientation }) => (orientation == "left" ? "" : "left: auto; right: 0;")};
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 1;
    }
  }
`;

export const BackgroundContainer = styled.div`
  height: ${rem(IMAGE_BOX_VERTICAL_ORIENTATION_HEIGHT)};
  left: 50%;
  max-width: ${rem(globalDecorationMaxWidth)};
  position: absolute;
  top: 0;
  transform: translateX(-50%);
  width: 100%;

  @media (${contentPlateFlipPoint}) {
    height: 100%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
`;

export const PlateContainer = styled.div<{ orientation: ContentPlateOrientation }>`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin: 0 auto;
  max-width: ${rem(globalContentMaxWidth)};
  width: 100%;
  z-index: 2;

  // If an ImageBox is not provided we need to ensure the background is still visible on vertical view
  &:not(:has(.${imageBoxClassName})) {
    padding-top: ${rem(IMAGE_BOX_VERTICAL_ORIENTATION_HEIGHT)};
  }

  @media (${contentPlateFlipPoint}) {
    flex-direction: ${({ orientation }) => (orientation == "left" ? "row" : "row-reverse")};
    height: 100%;
    justify-content: flex-start;
    min-height: 0;
    padding-top: 0;

    // Reset the padding when an ImageBox is not provided for desktop
    &:not(:has(.${imageBoxClassName})) {
      padding-top: 0;
    }
  }
`;

export const CopyBox = styled.div`
  background: ${colors.blueDeep};
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  min-height: ${sizes.s192.rem};
  overflow: hidden;
  padding: 0;
  position: relative;
  word-break: break-word;
  z-index: 1;

  @media (${contentPlateFlipPoint}) {
    background: transparent;
    max-width: ${COPY_BOX_WIDTH_PERCENT}%;
    padding: ${sizes.s64.rem} ${sizes.s48.rem};
    width: ${COPY_BOX_WIDTH_PERCENT}%;
  }
`;

export const CopyBoxInner = styled.div`
  margin: 0 auto;
  max-width: ${rem(680)};

  ul {
    list-style-position: inside;
    padding: 0;
  }

  @media (${contentPlateFlipPoint}) {
    margin: 0;
    max-width: none;
  }
`;

export const CopyBoxFrame = styled(ContentFrame)<{ hasFrame: boolean }>`
  margin: 0;
  max-height: ${rem(800)};
  min-height: ${rem(280)};
  padding: ${sizes.s16.rem};
  visibility: hidden;
  width: 100%;

  @media (${device.xsmall}) {
    min-height: ${rem(280)};
    padding: ${sizes.s24.rem} ${sizes.s32.rem};
    width: 100%;
  }

  @media (${contentPlateFlipPoint}) {
    max-height: ${sizes.s512.rem};
    min-height: ${({ hasFrame }) => (hasFrame ? rem(240) : 0)};
    padding: ${sizes.s32.rem};
    visibility: ${({ hasFrame }) => (hasFrame ? "visible" : "hidden")};
  }

  * {
    visibility: visible;
  }
`;

export const ImageBox = styled.div`
  align-items: center;
  display: flex;
  height: ${rem(IMAGE_BOX_VERTICAL_ORIENTATION_HEIGHT)};
  justify-content: center;
  margin: 0;
  order: -1;
  position: relative;
  width: 100%;

  @media (${contentPlateFlipPoint}) {
    bottom: auto;
    height: 100%;
    left: auto;
    order: unset;
    width: ${100 - COPY_BOX_WIDTH_PERCENT}%;
  }
`;
