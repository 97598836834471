import { forwardRef, Ref } from "react";

import { useJds } from "../../../global/jds-context";
import { isUrlAllowed } from "../../../utils/url-helpers";
import { Base, LinkProps } from "./Base";

/**
 * Renders a standard JDS link. Needs to be wrapped inside JdsProvider in order to work.
 */
export const Link = forwardRef(
  ({ href, ...rest }: LinkProps, ref: Ref<HTMLAnchorElement>): JSX.Element => {
    const { domainAllowlist } = useJds();

    let isHrefAllowed;
    try {
      isHrefAllowed = isUrlAllowed(href, domainAllowlist);
    } catch (e) {
      throw new Error(`URL "${href}" provided to the Link atom is not a valid URL`);
    }

    if (!isHrefAllowed) {
      throw new Error(
        `Provided URL "${href}" is not listed in the allowed domains. Use JdsProvider to set a list of domains that can be used by the Link component.`
      );
    }

    return <Base href={href} ref={ref} data-testid={Link.displayName} {...rest} />;
  }
);

Link.displayName = "Link";
