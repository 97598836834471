"use client";

import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { rem } from "polished";

import { lineClamp } from "../../../utils/line-clamp";
import { colors } from "../../design-tokens/colors";
import { globalTextMaxWidth } from "../../design-tokens/dimensions";
import { FontStyles, typography } from "../../design-tokens/typography";
import type { StyledProps } from "./Heading";

const defaultColor = colors.white;

export const getHeadingStyle = (props: StyledProps) => {
  return css`
    color: ${props.color ?? defaultColor};
    font-family: ${typography.families.heading};
    font-weight: ${typography.weights.medium};
    letter-spacing: ${typography.letterSpacing[3]};
    margin: 0 auto;
    max-width: ${rem(globalTextMaxWidth)};
    text-align: ${props.align};
    width: 100%;
    ${lineClamp(typography.lineHeights[32], props.lines)}
  `;
};

export type HeadingLevels = "h1" | "h2" | "h3" | "h4" | "h5" | "h6";

export const headingStyles: Record<HeadingLevels, FontStyles> = {
  h1: {
    fontSize: typography.sizes[48],
    fontWeight: typography.weights.bold,
    letterSpacing: typography.letterSpacing[3],
    lineHeight: typography.lineHeights[56],
  },
  h2: {
    fontSize: typography.sizes[32],
    fontWeight: typography.weights.medium,
    letterSpacing: typography.letterSpacing[3],
    lineHeight: typography.lineHeights[44],
  },
  h3: {
    fontSize: typography.sizes[24],
    fontWeight: typography.weights.medium,
    letterSpacing: typography.letterSpacing[3],
    lineHeight: typography.lineHeights[32],
  },
  h4: {
    fontSize: typography.sizes[20],
    fontWeight: typography.weights.medium,
    letterSpacing: typography.letterSpacing[3],
    lineHeight: typography.lineHeights[32],
  },
  h5: {
    fontSize: typography.sizes[18],
    fontWeight: typography.weights.medium,
    letterSpacing: typography.letterSpacing[3],
    lineHeight: typography.lineHeights[28],
  },
  h6: {
    fontSize: typography.sizes[16],
    fontWeight: typography.weights.medium,
    letterSpacing: typography.letterSpacing[3],
    lineHeight: typography.lineHeights[20],
  },
};

const H1 = styled.h1<StyledProps>`
  ${getHeadingStyle}

  font-size: ${headingStyles.h1.fontSize};
  font-weight: ${headingStyles.h1.fontWeight};
  letter-spacing: ${headingStyles.h1.letterSpacing};
  ${({ lines }) => lineClamp(`${headingStyles.h1.lineHeight}`, lines)}
`;

const H2 = styled.h2<StyledProps>`
  ${getHeadingStyle}

  font-size: ${headingStyles.h2.fontSize};
  letter-spacing: ${headingStyles.h2.letterSpacing};
  line-height: ${headingStyles.h2.lineHeight};
`;

const H3 = styled.h3<StyledProps>`
  ${getHeadingStyle}

  font-size: ${headingStyles.h3.fontSize};
  letter-spacing: ${headingStyles.h3.letterSpacing};
  line-height: ${headingStyles.h3.lineHeight};
`;

const H4 = styled.h4<StyledProps>`
  ${getHeadingStyle}

  font-size: ${headingStyles.h4.fontSize};
  letter-spacing: ${headingStyles.h4.letterSpacing};
  line-height: ${headingStyles.h4.lineHeight};
`;

const H5 = styled.h5<StyledProps>`
  ${getHeadingStyle}

  font-size: ${headingStyles.h5.fontSize};
  letter-spacing: ${headingStyles.h5.letterSpacing};
  line-height: ${headingStyles.h5.lineHeight};
`;

const H6 = styled.h6<StyledProps>`
  ${getHeadingStyle}

  font-size: ${headingStyles.h6.fontSize};
  letter-spacing: ${headingStyles.h6.letterSpacing};
  line-height: ${headingStyles.h6.lineHeight};
`;

export const headings = {
  h1: H1,
  h2: H2,
  h3: H3,
  h4: H4,
  h5: H5,
  h6: H6,
};
