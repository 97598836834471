import { useJds } from "../../global/jds-context";
import type { Locale, TranslationJson } from "../../locales/types";

/**
 * HOF to load translations, returning a lookup function for the active locale.
 *
 * @param translations - per language translations to extract from
 * @param locale - the {@link Locale} to retrieve translations for.
 */
export function translate<T>(
  translations: Record<Locale, TranslationJson & T>,
  locale: Locale
): (key: string) => string {
  const json = translations[locale];

  /**
   * Translation function, which accepts a lookup key via a dot delimited path.
   *
   * @param key - Key of the requested translation (e.g. "backgrounds.battle.name").
   */
  return (key) => {
    try {
      return lookup(json, key.split("."));
    } catch (e) {
      throw new Error(`Could not locate translation for key "${key}" (${e})`);
    }
  };
}

/**
 * React hook to load translations, returning a lookup function for the context locale.
 *
 * @param translations - per language translations to extract from
 */
export function useTranslate<T>(translations: Record<Locale, TranslationJson & T>) {
  const { locale } = useJds();

  return translate(translations, locale);
}

/**
 * Traverses the translation json looking for a value at the given key.
 *
 * @param json - the {@link TranslationJson} to search
 * @param parts - an array of key parts
 */
function lookup(json: TranslationJson, parts: string[]): string {
  const [key, ...rest] = parts;
  const next = json[key];

  switch (typeof next) {
    case "undefined":
      throw new Error(`failed at part "${key}"`);
    case "string":
      return next;
    default:
      return lookup(next, rest);
  }
}
