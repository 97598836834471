import { ClassNames } from "@emotion/react";
import { forwardRef } from "react";

import { Root } from "./styles";
import { LinkProps } from "./types";

export const HeaderLinkClassName = "HeaderLink";

/**
 * Header.Link - renders a {@link Link} element for the Header
 */
export const Link = forwardRef<HTMLAnchorElement, LinkProps>(
  ({ children, className, ...rest }, propRef) => {
    return (
      <ClassNames>
        {({ cx }) => (
          <Root
            data-testid={`Header${Link.displayName}`}
            className={cx(HeaderLinkClassName, className)}
            ref={propRef}
            {...rest}
          >
            {children}
          </Root>
        )}
      </ClassNames>
    );
  }
);

Link.displayName = "Link";
