import addIcon from "./assets/add.svg";
import addCharacterIcon from "./assets/add-character.svg";
import amazonFlatIcon from "./assets/amazon-flat.svg";
import androidFlatIcon from "./assets/android-flat.svg";
import appCodeIcon from "./assets/app-code.svg";
import appleFlatIcon from "./assets/apple-flat.svg";
import arrowDownIcon from "./assets/arrow-down.svg";
import arrowLeftIcon from "./assets/arrow-left.svg";
import arrowRightIcon from "./assets/arrow-right.svg";
import arrowUpIcon from "./assets/arrow-up.svg";
import axeso5Flat from "./assets/axeso5-flat.svg";
import backupCodeIcon from "./assets/backup-code.svg";
import boaCompraFlat from "./assets/boa-compra-flat.svg";
import characterIcon from "./assets/character.svg";
import checkmarkIcon from "./assets/checkmark.svg";
import closeIcon from "./assets/close.svg";
import cookieIcon from "./assets/cookie.svg";
import bankCardIcon from "./assets/credit-card.svg";
import dataIcon from "./assets/data.svg";
import editIcon from "./assets/edit.svg";
import emailIcon from "./assets/email.svg";
import emailCodeIcon from "./assets/email-code.svg";
import epicGamesFlat from "./assets/epic-flat.svg";
import errorIcon from "./assets/error.svg";
import externalLinkIcon from "./assets/external-link.svg";
import facebookFlatIcon from "./assets/facebook-flat.svg";
import favouriteIcon from "./assets/favourite.svg";
import feedbackIcon from "./assets/feedback.svg";
import globeIcon from "./assets/globe.svg";
import googleFlatIcon from "./assets/google-flat.svg";
import hiddenIcon from "./assets/hidden.svg";
import infoIcon from "./assets/info.svg";
import instagramFlatIcon from "./assets/instagram-flat.svg";
import invalidIcon from "./assets/invalid.svg";
import iosFlatIcon from "./assets/ios-flat.svg";
import jagexPinFlat from "./assets/jagex-pin-flat.svg";
import jagexSquareFlat from "./assets/jagex-square-flat.svg";
import legalIcon from "./assets/legal.svg";
import linkIcon from "./assets/link.svg";
import linuxFlatIcon from "./assets/linux-flat.svg";
import logoutIcon from "./assets/logout.svg";
import melvorIdleFlat from "./assets/melvor-idle-flat.svg";
import menuIcon from "./assets/menu.svg";
import minusIcon from "./assets/minus.svg";
import oldSchoolRuneScapeRoundColor from "./assets/old-school-runescape-round-color.png";
import oldSchoolRuneScapeRoundFlat from "./assets/old-school-runescape-round-flat.svg";
import optionsIcon from "./assets/options.svg";
import pauseIcon from "./assets/pause.svg";
import PEGIAge03Icon from "./assets/pegi-age-03.svg";
import PEGIAge07Icon from "./assets/pegi-age-07.svg";
import PEGIAge12Icon from "./assets/pegi-age-12.svg";
import PEGIAge16Icon from "./assets/pegi-age-16.svg";
import PEGIAge18Icon from "./assets/pegi-age-18.svg";
import PEGIContentDiscriminationIcon from "./assets/pegi-content-discrimination.svg";
import PEGIContentDrugsIcon from "./assets/pegi-content-drugs.svg";
import PEGIContentFearIcon from "./assets/pegi-content-fear.svg";
import PEGIContentGamblingIcon from "./assets/pegi-content-gambling.svg";
import PEGIContentLanguageIcon from "./assets/pegi-content-language.svg";
import PEGIContentPurchasesIcon from "./assets/pegi-content-purchases.svg";
import PEGIContentSexIcon from "./assets/pegi-content-sex.svg";
import PEGIContentViolenceIcon from "./assets/pegi-content-violence.svg";
import phoneIcon from "./assets/phone.svg";
import pinIcon from "./assets/pin.svg";
import playIcon from "./assets/play.svg";
import plusIcon from "./assets/plus.svg";
import profileIcon from "./assets/profile.svg";
import runeScapeLiteFlat from "./assets/runescape-lite-flat.svg";
import runeScapeRoundColor from "./assets/runescape-round-color.png";
import runeScapeRoundFlat from "./assets/runescape-round-flat.svg";
import safetyIcon from "./assets/safety.svg";
import searchIcon from "./assets/search.svg";
import settingsIcon from "./assets/settings.svg";
import spacePunksFlat from "./assets/space-punks-flat.svg";
import steamFlatIcon from "./assets/steam-flat.svg";
import steamRoundFlatIcon from "./assets/steam-round-flat.svg";
import storeIcon from "./assets/store.svg";
import successIcon from "./assets/success.svg";
import thisMeansWarpFlat from "./assets/this-means-warp-flat.svg";
import twitterFlatIcon from "./assets/twitter-flat.svg";
import uninstallIcon from "./assets/uninstall.svg";
import visibleIcon from "./assets/visible.svg";
import webIcon from "./assets/web.svg";
import windowsFlatIcon from "./assets/windows-flat.svg";

export const regularIcons = {
  "add-character": {
    name: "Add Character",
    src: addCharacterIcon,
    title: "icons.regular-icons.add-character.title",
    alt: "icons.regular-icons.add-character.alt",
  },
  "app-code": {
    name: "App Code",
    src: appCodeIcon,
    title: "icons.regular-icons.app-code.title",
    alt: "icons.regular-icons.app-code.alt",
  },
  "arrow-down": {
    name: "Arrow Down",
    src: arrowDownIcon,
    title: "icons.regular-icons.arrow-down.title",
    alt: "icons.regular-icons.arrow-down.alt",
  },
  "arrow-left": {
    name: "Arrow Left",
    src: arrowLeftIcon,
    title: "icons.regular-icons.arrow-left.title",
    alt: "icons.regular-icons.arrow-left.alt",
  },
  "arrow-right": {
    name: "Arrow Right",
    src: arrowRightIcon,
    title: "icons.regular-icons.arrow-right.title",
    alt: "icons.regular-icons.arrow-right.alt",
  },
  "arrow-up": {
    name: "Arrow Up",
    src: arrowUpIcon,
    title: "icons.regular-icons.arrow-up.title",
    alt: "icons.regular-icons.arrow-up.alt",
  },
  "backup-code": {
    name: "Backup Code",
    src: backupCodeIcon,
    title: "icons.regular-icons.backup-code.title",
    alt: "icons.regular-icons.backup-code.alt",
  },
  "bank-card": {
    name: "Bank Card",
    src: bankCardIcon,
    title: "icons.regular-icons.bank-card.title",
    alt: "icons.regular-icons.bank-card.alt",
  },
  character: {
    name: "Character",
    src: characterIcon,
    title: "icons.regular-icons.character.title",
    alt: "icons.regular-icons.character.alt",
  },
  checkmark: {
    name: "Checkmark",
    src: checkmarkIcon,
    title: "icons.regular-icons.checkmark.title",
    alt: "icons.regular-icons.checkmark.alt",
  },
  close: {
    name: "Close",
    src: closeIcon,
    title: "icons.regular-icons.close.title",
    alt: "icons.regular-icons.close.alt",
  },
  cookie: {
    name: "Cookie",
    src: cookieIcon,
    title: "icons.regular-icons.cookie.title",
    alt: "icons.regular-icons.cookie.alt",
  },
  data: {
    name: "Data",
    src: dataIcon,
    title: "icons.regular-icons.data.title",
    alt: "icons.regular-icons.data.alt",
  },
  edit: {
    name: "Edit",
    src: editIcon,
    title: "icons.regular-icons.edit.title",
    alt: "icons.regular-icons.edit.alt",
  },
  email: {
    name: "Email",
    src: emailIcon,
    title: "icons.regular-icons.email.title",
    alt: "icons.regular-icons.email.alt",
  },
  "email-code": {
    name: "Email Code",
    src: emailCodeIcon,
    title: "icons.regular-icons.email-code.title",
    alt: "icons.regular-icons.email-code.alt",
  },
  error: {
    name: "Error",
    src: errorIcon,
    title: "icons.regular-icons.error.title",
    alt: "icons.regular-icons.error.alt",
  },
  "external-link": {
    name: "External Link",
    src: externalLinkIcon,
    title: "icons.regular-icons.external-link.title",
    alt: "icons.regular-icons.external-link.alt",
  },
  favourite: {
    name: "Favourite",
    src: favouriteIcon,
    title: "icons.regular-icons.favourite.title",
    alt: "icons.regular-icons.favourite.alt",
  },
  feedback: {
    name: "Feedback",
    src: feedbackIcon,
    title: "icons.regular-icons.feedback.title",
    alt: "icons.regular-icons.feedback.alt",
  },
  globe: {
    name: "Globe",
    src: globeIcon,
    title: "icons.regular-icons.globe.title",
    alt: "icons.regular-icons.globe.alt",
  },
  hidden: {
    name: "Hidden",
    src: hiddenIcon,
    title: "icons.regular-icons.hidden.title",
    alt: "icons.regular-icons.hidden.alt",
  },
  info: {
    name: "Info",
    src: infoIcon,
    title: "icons.regular-icons.info.title",
    alt: "icons.regular-icons.info.alt",
  },
  invalid: {
    name: "Invalid",
    src: invalidIcon,
    title: "icons.regular-icons.invalid.title",
    alt: "icons.regular-icons.invalid.alt",
  },
  legal: {
    name: "Legal",
    src: legalIcon,
    title: "icons.regular-icons.legal.title",
    alt: "icons.regular-icons.legal.alt",
  },
  link: {
    name: "Link",
    src: linkIcon,
    title: "icons.regular-icons.link.title",
    alt: "icons.regular-icons.link.alt",
  },
  logout: {
    name: "Logout",
    src: logoutIcon,
    title: "icons.regular-icons.logout.title",
    alt: "icons.regular-icons.logout.alt",
  },
  menu: {
    name: "Menu",
    src: menuIcon,
    title: "icons.regular-icons.menu.title",
    alt: "icons.regular-icons.menu.alt",
  },
  minus: {
    name: "Minus",
    src: minusIcon,
    title: "icons.regular-icons.minus.title",
    alt: "icons.regular-icons.minus.alt",
  },
  options: {
    name: "Options",
    src: optionsIcon,
    title: "icons.regular-icons.options.title",
    alt: "icons.regular-icons.options.alt",
  },
  pause: {
    name: "Pause",
    src: pauseIcon,
    title: "icons.regular-icons.pause.title",
    alt: "icons.regular-icons.pause.alt",
  },
  phone: {
    name: "Phone",
    src: phoneIcon,
    title: "icons.regular-icons.phone.title",
    alt: "icons.regular-icons.phone.alt",
  },
  play: {
    name: "Play",
    src: playIcon,
    title: "icons.regular-icons.play.title",
    alt: "icons.regular-icons.play.alt",
  },
  plus: {
    name: "Plus",
    src: plusIcon,
    title: "icons.regular-icons.plus.title",
    alt: "icons.regular-icons.plus.alt",
  },
  profile: {
    name: "Profile",
    src: profileIcon,
    title: "icons.regular-icons.profile.title",
    alt: "icons.regular-icons.profile.alt",
  },
  safety: {
    name: "Safety",
    src: safetyIcon,
    title: "icons.regular-icons.safety.title",
    alt: "icons.regular-icons.safety.alt",
  },
  search: {
    name: "Search",
    src: searchIcon,
    title: "icons.regular-icons.search.title",
    alt: "icons.regular-icons.search.alt",
  },
  settings: {
    name: "Settings",
    src: settingsIcon,
    title: "icons.regular-icons.settings.title",
    alt: "icons.regular-icons.settings.alt",
  },
  pin: {
    name: "Pin",
    src: pinIcon,
    title: "icons.regular-icons.pin.title",
    alt: "icons.regular-icons.pin.alt",
  },
  store: {
    name: "Store",
    src: storeIcon,
    title: "icons.regular-icons.store.title",
    alt: "icons.regular-icons.store.alt",
  },
  success: {
    name: "Success",
    src: successIcon,
    title: "icons.regular-icons.success.title",
    alt: "icons.regular-icons.success.alt",
  },
  uninstall: {
    name: "Uninstall",
    src: uninstallIcon,
    title: "icons.regular-icons.uninstall.title",
    alt: "icons.regular-icons.uninstall.alt",
  },
  visible: {
    name: "Visible",
    src: visibleIcon,
    title: "icons.regular-icons.visible.title",
    alt: "icons.regular-icons.visible.alt",
  },
};

export const deprecatedIcons = {
  add: {
    name: "Add",
    src: addIcon,
    title: "icons.regular-icons.add.title",
    alt: "icons.regular-icons.add.alt",
  },
  web: {
    name: "Web",
    src: webIcon,
    title: "icons.regular-icons.web.title",
    alt: "icons.regular-icons.web.alt",
  },
};

export const socialMediaIcons = {
  "amazon-flat": {
    name: "Amazon flat Icon",
    src: amazonFlatIcon,
    title: "icons.social-media-icons.amazon-flat.title",
    alt: "icons.social-media-icons.amazon-flat.alt",
  },
  "apple-flat": {
    name: "Apple flat Icon",
    src: appleFlatIcon,
    title: "icons.social-media-icons.apple-flat.title",
    alt: "icons.social-media-icons.apple-flat.alt",
  },
  "facebook-flat": {
    name: "Facebook flat Icon",
    src: facebookFlatIcon,
    title: "icons.social-media-icons.facebook-flat.title",
    alt: "icons.social-media-icons.facebook-flat.alt",
  },
  "google-flat": {
    name: "Google flat Icon",
    src: googleFlatIcon,
    title: "icons.social-media-icons.google-flat.title",
    alt: "icons.social-media-icons.google-flat.alt",
  },
  "instagram-flat": {
    name: "Instagram flat Icon",
    src: instagramFlatIcon,
    title: "icons.social-media-icons.instagram-flat.title",
    alt: "icons.social-media-icons.instagram-flat.alt",
  },
  "steam-flat": {
    name: "Steam flat Icon",
    src: steamFlatIcon,
    title: "icons.social-media-icons.steam-flat.title",
    alt: "icons.social-media-icons.steam-flat.alt",
  },
  "twitter-flat": {
    name: "Twitter flat Icon",
    src: twitterFlatIcon,
    title: "icons.social-media-icons.twitter-flat.title",
    alt: "icons.social-media-icons.twitter-flat.alt",
  },
};

const brandIconsFlat = {
  "android-flat": {
    name: "Android flat Icon",
    src: androidFlatIcon,
    title: "icons.brand-flat-icons.android-flat.title",
    alt: "icons.brand-flat-icons.android-flat.alt",
  },
  "apple-flat": {
    ...socialMediaIcons["apple-flat"],
  },
  "axeso5-flat": {
    name: "Axeso5 flat Icon",
    src: axeso5Flat,
    title: "icons.brand-flat-icons.axeso5-flat.title",
    alt: "icons.brand-flat-icons.axeso5-flat.alt",
  },
  "boa-compra-flat": {
    name: "Boa Compra flat Icon",
    src: boaCompraFlat,
    title: "icons.brand-flat-icons.boa-compra-flat.title",
    alt: "icons.brand-flat-icons.boa-compra-flat.alt",
  },
  "epic-games-flat": {
    name: "Epic Games flat Icon",
    src: epicGamesFlat,
    title: "icons.brand-flat-icons.epic-games-flat.title",
    alt: "icons.brand-flat-icons.epic-games-flat.alt",
  },
  "ios-flat": {
    name: "iOS flat Icon",
    src: iosFlatIcon,
    title: "icons.brand-flat-icons.ios-flat.title",
    alt: "icons.brand-flat-icons.ios-flat.alt",
  },
  "jagex-flat": {
    name: "Jagex flat Icon",
    src: jagexSquareFlat,
    title: "icons.brand-flat-icons.jagex-flat.title",
    alt: "icons.brand-flat-icons.jagex-flat.alt",
  },
  "jagex-pin-flat": {
    name: "Jagex Pin flat Icon",
    src: jagexPinFlat,
    title: "icons.brand-flat-icons.jagex-pin-flat.title",
    alt: "icons.brand-flat-icons.jagex-pin-flat.alt",
  },
  "linux-flat": {
    name: "Linux flat Icon",
    src: linuxFlatIcon,
    title: "icons.brand-flat-icons.linux-flat.title",
    alt: "icons.brand-flat-icons.linux-flat.alt",
  },
  "steam-round-flat": {
    name: "Steam Round flat Icon",
    src: steamRoundFlatIcon,
    title: "icons.brand-flat-icons.steam-round-flat.title",
    alt: "icons.brand-flat-icons.steam-round-flat.alt",
  },
  "melvor-idle-flat": {
    name: "Melvor Idle flat Icon",
    src: melvorIdleFlat,
    title: "icons.brand-flat-icons.melvor-idle-flat.title",
    alt: "icons.brand-flat-icons.melvor-idle-flat.alt",
  },
  "old-school-runescape-flat": {
    name: "Old School RuneScape flat Icon",
    src: oldSchoolRuneScapeRoundFlat,
    title: "icons.brand-flat-icons.old-school-runescape-flat.title",
    alt: "icons.brand-flat-icons.old-school-runescape-flat.alt",
  },
  "runescape-flat": {
    name: "RuneScape flat Icon",
    src: runeScapeRoundFlat,
    title: "icons.brand-flat-icons.runescape-flat.title",
    alt: "icons.brand-flat-icons.runescape-flat.alt",
  },
  "runescape-lite-flat": {
    name: "RuneScape Lite flat Icon",
    src: runeScapeLiteFlat,
    title: "icons.brand-flat-icons.runescape-lite-flat.title",
    alt: "icons.brand-flat-icons.runescape-lite-flat.alt",
  },
  "space-punks-flat": {
    name: "Space Punks flat Icon",
    src: spacePunksFlat,
    title: "icons.brand-flat-icons.space-punks-flat.title",
    alt: "icons.brand-flat-icons.space-punks-flat.alt",
  },
  "this-means-warp-flat": {
    name: "This Means Warp flat Icon",
    src: thisMeansWarpFlat,
    title: "icons.brand-flat-icons.this-means-warp-flat.title",
    alt: "icons.brand-flat-icons.this-means-warp-flat.alt",
  },
  "windows-flat": {
    name: "Windows flat Icon",
    src: windowsFlatIcon,
    title: "icons.brand-flat-icons.windows-flat.title",
    alt: "icons.brand-flat-icons.windows-flat.alt",
  },
};

const brandIconsColor = {
  "old-school-runescape-color": {
    name: "Old School Runescape color Icon",
    src: oldSchoolRuneScapeRoundColor,
    title: "icons.brand-icons-color.old-school-runescape-color.title",
    alt: "icons.brand-icons-color.old-school-runescape-color.alt",
  },
  "runescape-color": {
    name: "RuneScape color Icon",
    src: runeScapeRoundColor,
    title: "icons.brand-icons-color.runescape-color.title",
    alt: "icons.brand-icons-color.runescape-color.alt",
  },
};

/**
 * Flat icons can be used in an Icon component, that's why they must be exported separately
 */
export const flatIcons = {
  ...regularIcons,
  ...deprecatedIcons,
  ...socialMediaIcons,
  ...brandIconsFlat,
};

export const brandIcons = { ...brandIconsFlat, ...brandIconsColor };

export const pegiAgeIcons = {
  "pegi-age-03": {
    name: "PEGI Age 03 Icon",
    src: PEGIAge03Icon,
    alt: "icons.pegi-icons.pegi-age-03.alt",
    title: "icons.pegi-icons.pegi-age-03.title",
  },
  "pegi-age-07": {
    name: "PEGI Age 07 Icon",
    src: PEGIAge07Icon,
    alt: "icons.pegi-icons.pegi-age-07.alt",
    title: "icons.pegi-icons.pegi-age-07.title",
  },
  "pegi-age-12": {
    name: "PEGI Age 12 Icon",
    src: PEGIAge12Icon,
    alt: "icons.pegi-icons.pegi-age-12.alt",
    title: "icons.pegi-icons.pegi-age-12.title",
  },
  "pegi-age-16": {
    name: "PEGI Age 16 Icon",
    src: PEGIAge16Icon,
    alt: "icons.pegi-icons.pegi-age-16.alt",
    title: "icons.pegi-icons.pegi-age-16.title",
  },
  "pegi-age-18": {
    name: "PEGI Age 18 Icon",
    src: PEGIAge18Icon,
    alt: "icons.pegi-icons.pegi-age-18.alt",
    title: "icons.pegi-icons.pegi-age-18.title",
  },
};

export const pegiContentIcons = {
  "pegi-content-discrimination": {
    name: "PEGI Content Discrimination Icon",
    src: PEGIContentDiscriminationIcon,
    alt: "icons.pegi-icons.pegi-content-discrimination.alt",
    title: "icons.pegi-icons.pegi-content-discrimination.title",
  },
  "pegi-content-drugs": {
    name: "PEGI Content Drugs Icon",
    src: PEGIContentDrugsIcon,
    alt: "icons.pegi-icons.pegi-content-drugs.alt",
    title: "icons.pegi-icons.pegi-content-drugs.title",
  },
  "pegi-content-fear": {
    name: "PEGI Content Fear Icon",
    src: PEGIContentFearIcon,
    alt: "icons.pegi-icons.pegi-content-fear.alt",
    title: "icons.pegi-icons.pegi-content-fear.title",
  },
  "pegi-content-gambling": {
    name: "PEGI Content Gambling Icon",
    src: PEGIContentGamblingIcon,
    alt: "icons.pegi-icons.pegi-content-gambling.alt",
    title: "icons.pegi-icons.pegi-content-gambling.title",
  },
  "pegi-content-language": {
    name: "PEGI Content Language Icon",
    src: PEGIContentLanguageIcon,
    alt: "icons.pegi-icons.pegi-content-language.alt",
    title: "icons.pegi-icons.pegi-content-language.title",
  },
  "pegi-content-purchases": {
    name: "PEGI Content Purchases Icon",
    src: PEGIContentPurchasesIcon,
    alt: "icons.pegi-icons.pegi-content-purchases.alt",
    title: "icons.pegi-icons.pegi-content-purchases.title",
  },
  "pegi-content-sex": {
    name: "PEGI Content Sex Icon",
    src: PEGIContentSexIcon,
    alt: "icons.pegi-icons.pegi-content-sex.alt",
    title: "icons.pegi-icons.pegi-content-sex.title",
  },
  "pegi-content-violence": {
    name: "PEGI Content Violence Icon",
    src: PEGIContentViolenceIcon,
    alt: "icons.pegi-icons.pegi-content-violence.alt",
    title: "icons.pegi-icons.pegi-content-violence.title",
  },
};

export const pegiIcons = {
  ...pegiAgeIcons,
  ...pegiContentIcons,
};

export const allIcons = {
  ...flatIcons,
  ...brandIconsColor,
  ...pegiIcons,
};
