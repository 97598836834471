import type { HTMLAttributes } from "react";

import { Root, Wrapper } from "./styles";

type AsideProps = HTMLAttributes<HTMLDivElement>;

/**
 * Aside container to display related content inside a Multiplex.
 */
export function Aside({ children, ...args }: AsideProps) {
  return (
    <Root data-testid={`Multiplex${Aside.name}`} {...args}>
      <Wrapper>{children}</Wrapper>
    </Root>
  );
}
