"use client";

import { css } from "@emotion/react";
import styled from "@emotion/styled";

import { swoosh } from "../../design-tokens/animations/swoosh";
import { colors } from "../../design-tokens/colors";
import { sizes } from "../../design-tokens/dimensions";
import type { PendingProps } from "./Pending";

const animationStyles = css`
  animation: ${swoosh} 1.5s infinite;
  background: linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.1), transparent);
  height: 100%;
  position: absolute;
  transform: translateX(-100%);
  width: 100%;
`;

export const Root = styled.div`
  background: ${colors.betaBase};
  height: ${sizes.s24.rem};
  overflow: hidden;
  position: relative;
  width: ${sizes.s96.rem};
`;

export const AnimatedBackground = styled.div<PendingProps>`
  display: ${({ isAnimated }) => (isAnimated ? "block" : "none")};
  ${({ isAnimated }) => isAnimated && animationStyles};
`;
