import type { InputHTMLAttributes } from "react";

import { Root } from "./styles";

type TextInputProps = {
  /**
   * Type of HTML input element to render.
   */
  type?: "email" | "number" | "password" | "search" | "tel" | "text" | "url";
  /**
   * Whether the current entry is in an invalid state.
   */
  isInvalid?: boolean;
  /**
   * Whether the current entry is in a disabled state.
   */
  disabled?: boolean;
  /**
   * Whether a value is required.
   */
  required?: boolean;
} & InputHTMLAttributes<HTMLInputElement>;

/**
 * A base input field for text and numbers.
 *
 * Please note that this should not be used without a label and is here for atomic design purposes only.
 * Accordingly, this component does not appear in Storybook - it is a reusable primitive, intended for
 * use by an implementing end user component.
 */
export function BaseInput({
  type = "text",
  isInvalid = false,
  disabled = false,
  required = false,
  ...rest
}: TextInputProps): JSX.Element {
  return (
    <Root
      data-testid={BaseInput.name}
      aria-disabled={disabled}
      disabled={disabled}
      type={type}
      aria-invalid={isInvalid}
      required={required}
      aria-required={required}
      {...rest}
    />
  );
}
