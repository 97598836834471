"use client";

import styled from "@emotion/styled";
import { rem } from "polished";

import { sizes } from "../../design-tokens/dimensions";

export const Root = styled.div<{ multiplier: number }>`
  margin-bottom: ${({ multiplier }) => rem(multiplier * sizes.s8.raw)};
`;
