import {
  arrow,
  autoUpdate,
  flip,
  offset,
  Placement,
  shift,
  Side,
  useDismiss,
  useFloating,
  useFocus,
  useHover,
  useInteractions,
  useMergeRefs,
  useRole,
  useTransitionStyles,
} from "@floating-ui/react";
import type { ReactElement } from "react";
import { cloneElement, forwardRef, HTMLAttributes, useMemo, useRef, useState } from "react";

import { Arrow, arrowSize, Content } from "./styles";

type TooltipProps = {
  /**
   * Content of the tooltip
   */
  title: string;
  /**
   * Tooltip reference element. ⚠️ Needs to be able to hold a ref
   */
  children: ReactElement;
  /**
   * Tooltip delay on hover {@link https://floating-ui.com/docs/usehover#delay}
   */
  delay?: number | Partial<{ open: number; close: number }>;
  /**
   * Tooltip initial placement {@link Placement}
   */
  initialPlacement?: Placement;
} & HTMLAttributes<HTMLDivElement>;

/**
 * Renders informative box - tooltip displayed on hover, focus, tap on an element is attached to.
 */
export const Tooltip = forwardRef<HTMLElement, TooltipProps>(function (
  { children, title, style, delay, initialPlacement, ...rest },
  propRef
) {
  const [isOpen, setIsOpen] = useState(false);
  const arrowRef = useRef<HTMLDivElement>(null);
  const arrowLen = arrowRef.current?.offsetWidth || arrowSize.raw;
  const floatingOffset = 20;
  const {
    x,
    y,
    strategy,
    refs,
    context,
    placement,
    middlewareData: { arrow: { x: arrowX, y: arrowY } = {} },
  } = useFloating({
    placement: initialPlacement || "bottom",
    open: isOpen,
    onOpenChange: setIsOpen,
    middleware: [offset(floatingOffset), flip(), shift(), arrow({ element: arrowRef })],
    whileElementsMounted: autoUpdate,
  });
  const hover = useHover(context, { delay });
  const focus = useFocus(context);
  const dismiss = useDismiss(context);
  const role = useRole(context, { role: "tooltip" });
  // children element type does not come with ref therefore cast to any
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const ref = useMergeRefs([refs.setReference, (children as any).ref, propRef]);
  const { getReferenceProps, getFloatingProps } = useInteractions([hover, focus, dismiss, role]);

  // Extracting Side from Placement
  const staticSide: Side = useMemo(
    () =>
      ({
        top: "bottom",
        right: "left",
        bottom: "top",
        left: "right",
      }[placement.split("-")[0]] as Side),
    [placement]
  );

  const { isMounted, styles: transitionStyles } = useTransitionStyles(context, {
    duration: 150,
    initial: ({ side }) => {
      const translateVal = 5;
      return {
        opacity: 0,
        transform: {
          top: `translateY(-${translateVal}px)`,
          bottom: `translateY(${translateVal}px)`,
          right: `translateX(${translateVal}px)`,
          left: `translateX(-${translateVal}px)`,
        }[side],
      };
    },
  });

  return (
    <>
      {cloneElement(
        children,
        getReferenceProps({ ref, ...children.props, "data-state": isMounted ? "open" : "closed" })
      )}
      {isMounted && (
        <Content
          data-testid={Tooltip.displayName}
          ref={refs.setFloating}
          style={{
            position: strategy,
            top: y ?? 0,
            left: x ?? 0,
            visibility: x == null ? "hidden" : "visible",
            ...transitionStyles,
            ...style,
          }}
          {...getFloatingProps()}
          {...rest}
        >
          {title}
          <Arrow
            ref={arrowRef}
            style={{
              left: arrowX != null ? `${arrowX}px` : "",
              top: arrowY != null ? `${arrowY}px` : "",
              right: "",
              bottom: "",
              [staticSide ?? ""]: `${-arrowLen / 2}px`,
            }}
          />
        </Content>
      )}
    </>
  );
});

Tooltip.displayName = "Tooltip";
