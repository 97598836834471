"use client";

import styled from "@emotion/styled";
import { rem } from "polished";

import { device } from "../../../../../design-tokens/dimensions";
import { thumbnailPadding, thumbnailWidths } from "../../../constants";

export const Root = styled.div`
  display: inline-block;
  padding: ${rem(thumbnailPadding)};
  position: relative;

  @media (${device.base}) {
    max-width: ${rem(thumbnailWidths.base)};
  }

  @media (${device.xsmall}) {
    max-width: ${rem(thumbnailWidths.xsmall)};
  }

  @media (${device.small}) {
    max-width: ${rem(thumbnailWidths.small)};
  }

  @media (${device.medium}) {
    max-width: ${rem(thumbnailWidths.medium)};
  }
`;
