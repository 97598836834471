"use client";

import styled from "@emotion/styled";
import type { HTMLAttributes } from "react";

import { ButtonClassName } from "../../../atoms/Button/Button";
import { breakpoints, sizes } from "../../../design-tokens/dimensions";

const defaultAlignment = "right";

type CloseButtonProps = HTMLAttributes<HTMLButtonElement> & {
  /**
   * Horizontal positioning of Buttons
   */
  align?: "left" | "center" | typeof defaultAlignment;
};

const RootContainerName = "ButtonGroup";

const Root = styled.menu`
  container: ${RootContainerName} / inline-size;
  margin: 0 auto;
  padding: 0;
  width: 100%;
  display: flex;
  flex: 1 1 auto;
  align-items: flex-end;
`;

const Inner = styled.div<{ align: CloseButtonProps["align"] }>`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  gap: ${sizes.s12.rem} ${sizes.s12.rem};
  justify-content: center;
  margin: 0 auto;
  width: 100%;

  @container ${RootContainerName} (min-width: ${breakpoints.xsmall}px) {
    justify-content: ${({ align }) => align};
  }

  @container ${RootContainerName}  (max-width: ${breakpoints.min}px) {
    .${ButtonClassName} {
      width: 100%;
    }
  }
`;

/**
 * Aligns buttons horizontally inside a Modal
 */
export function ButtonGroup({
  align = defaultAlignment,
  children,
  ...rest
}: CloseButtonProps): JSX.Element {
  return (
    <Root data-testid={`Modal${ButtonGroup.name}`} {...rest}>
      <Inner align={align}>{children}</Inner>
    </Root>
  );
}
