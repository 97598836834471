"use client";

import styled from "@emotion/styled";
import { lighten, rem, rgba } from "polished";

import { colors } from "../../design-tokens/colors";
import { sizes } from "../../design-tokens/dimensions";
import { borderRadii, filters } from "../../design-tokens/effects";
import checkIcon from "./check.svg";

export const Root = styled.input<{ isInvalid: boolean }>`
  appearance: none;
  aspect-ratio: 1;
  background: ${rgba(colors.baseBlack, 0.2)};
  border: ${sizes.s2.rem} solid ${({ isInvalid }) => (isInvalid ? colors.red : colors.betaBase)};
  border-radius: ${borderRadii.r4};
  cursor: pointer;
  margin: 0;
  width: ${sizes.s24.rem};

  &:hover {
    border-color: ${({ isInvalid }) =>
      isInvalid ? lighten(0.1, colors.red) : colors.baseLightest};
  }

  &:checked {
    background: ${colors.blue} url(${checkIcon}) no-repeat center;
    background-size: 75%;
    border-color: ${colors.blue};
    box-shadow: 0 ${rem(1)} ${rem(10)} ${colors.blue};

    &:hover {
      background-color: ${colors.blueLight};
      border-color: ${colors.blueLight};
    }
  }

  &:disabled {
    cursor: not-allowed;
    filter: ${filters.disabled};
  }

  &:indeterminate {
    background: ${colors.baseBlack};
    box-shadow: inset 0 0 0 ${rem(6)} ${colors.blue};
  }
`;
