"use client";

import { css, keyframes } from "@emotion/react";
import styled from "@emotion/styled";
import { rem, transparentize } from "polished";
import type { CSSProperties } from "react";

import { colors } from "../../design-tokens/colors";
import { sizes } from "../../design-tokens/dimensions";
import { borderRadii, filters } from "../../design-tokens/effects";
import { typography } from "../../design-tokens/typography";
import { Loader } from "../Loader";
import type { ButtonProps, ButtonSize } from "./Button";

const pulseAnim = keyframes`
  from {
    background-position-x: -80%;
  }
  to {
    background-position-x: 180%;
  }
`;

export const buttonBorderRadius = borderRadii.r8;

const BaseButton = styled.button<{
  isLoading: boolean;
  progress: number | undefined;
  fullWidth: boolean;
}>`
  align-items: center;
  border: none;
  border-radius: ${buttonBorderRadius};
  cursor: ${({ isLoading }) => (!isLoading ? "pointer" : "wait")};
  display: inline-flex;
  font-family: ${typography.families.cta};
  font-weight: ${typography.weights.medium};
  gap: ${sizes.s16.rem};
  justify-content: center;
  position: relative;
  text-decoration: none;
  transition: 150ms background;
  width: ${({ fullWidth }) => (fullWidth ? "100%" : "auto")};

  &:disabled {
    cursor: ${({ isLoading, progress }) =>
      !isLoading && progress === undefined ? "not-allowed" : "wait"};
    filter: ${({ isLoading, progress }) =>
      !isLoading && progress === undefined ? filters.disabled : "initial"};
  }

  ${({ progress }) =>
    progress !== undefined &&
    css`
      &::before {
        animation: ${pulseAnim} 1.2s linear infinite;
        background: left / 40% no-repeat
          linear-gradient(to right, transparent, ${colors.white}, transparent);
        border-radius: ${buttonBorderRadius} 0 0 ${buttonBorderRadius};
        content: "";
        height: 100%;
        left: 0;
        opacity: 0.15;
        position: absolute;
        top: 0;
        width: ${progress}%;
      }
    `}
`;

const getSizeStyles = (entitySize: ButtonSize) => `
  --mask-icon-size: ${entitySize === "m" ? sizes.s16.rem : sizes.s12.rem};
  font-size: ${entitySize === "m" ? typography.sizes[18] : typography.sizes[16]};
  gap: ${entitySize === "m" ? sizes.s12.rem : sizes.s8.rem};
  height: ${entitySize === "m" ? sizes.s56.rem : sizes.s40.rem};
  min-width: ${entitySize === "m" ? rem(240) : rem(200)};
  padding: ${entitySize === "m" ? sizes.s16.rem : sizes.s12.rem} ${
  entitySize === "m" ? sizes.s24.rem : sizes.s16.rem
};

`;

const getFillStyles = (
  progress: number | undefined,
  isLoading: boolean,
  mainColour: Exclude<CSSProperties["backgroundColor"], undefined>,
  hoverColour: Exclude<CSSProperties["backgroundColor"], undefined>,
  progressColour: Exclude<CSSProperties["backgroundColor"], undefined>
) => {
  return css`
    background-color: ${mainColour};

    ${progress !== undefined &&
    css`
      background-image: linear-gradient(
        to right,
        ${mainColour} ${progress}%,
        ${progressColour} ${progress}%
      );
    `}

    ${!isLoading &&
    css`
      &:hover {
        background-color: ${hoverColour};
      }

      &:active {
        background-color: ${transparentize(0.2, mainColour)};
      }

      &:disabled {
        background-color: ${transparentize(0.2, mainColour)};
      }
    `}
  `;
};

export const PrimaryButton = styled(BaseButton)<{
  entitySize: ButtonSize;
  progress: number | undefined;
}>`
  --mask-icon-color: ${colors.blueDeep};

  ${({ entitySize }) => getSizeStyles(entitySize)};
  color: ${colors.blueDeep};

  ${({ progress, isLoading }) =>
    getFillStyles(progress, isLoading, colors.blue, colors.blueLight, colors.baseLightest)};
`;

export const PositiveButton = styled(BaseButton)<{
  entitySize: ButtonSize;
  progress: number | undefined;
}>`
  --mask-icon-color: ${colors.blueDeep};

  ${({ entitySize }) => getSizeStyles(entitySize)};
  color: ${colors.blueDeep};

  ${({ progress, isLoading }) =>
    getFillStyles(progress, isLoading, colors.green, colors.greenLight, colors.baseLightest)};
`;

export const SecondaryButton = styled(BaseButton)<{
  entitySize: ButtonSize;
  progress: number | undefined;
}>`
  --mask-icon-color: ${colors.white};

  ${({ entitySize }) => getSizeStyles(entitySize)};
  color: ${colors.white};

  ${({ progress, isLoading }) =>
    getFillStyles(progress, isLoading, colors.baseMid, colors.betaBase, colors.baseBlack)};
`;

export const TertiaryButton = styled(BaseButton)<{ entitySize: ButtonSize }>`
  --mask-icon-color: ${colors.white};

  ${({ entitySize }) => getSizeStyles(entitySize)};
  background-color: transparent;
  border: 1px solid ${transparentize(0.7, colors.white)};
  color: ${colors.white};
  justify-content: flex-start;

  &:hover {
    background-color: ${transparentize(0.9, colors.white)};
  }

  &:active {
    background-color: ${transparentize(0.95, colors.white)};
  }

  &:disabled {
    background-color: transparent;
  }
`;

export const LinkButton = styled(BaseButton)<ButtonProps>`
  --mask-icon-color: ${colors.blueLight};
  --mask-icon-size: ${sizes.s16.rem};

  align-items: flex-start;
  background-color: transparent;
  border-radius: ${borderRadii.r4};
  color: ${colors.blueLight};
  font-size: ${typography.sizes.inherit};
  gap: ${sizes.s8.rem};
  padding: 0;

  &:hover {
    text-decoration: underline;
  }

  &:active {
    filter: opacity(70%);
    text-decoration: underline;
  }

  &:disabled {
    text-decoration: none;
  }
`;

export const ButtonLoader = styled(Loader)<{ entitySize: ButtonProps["entitySize"] }>`
  width: ${({ entitySize }) => (entitySize === "m" ? rem(28) : rem(20))};
`;
