{
  "icons": {
    "regular-icons": {
      "add": {
        "title": "Hinzufügen",
        "alt": "Ein Pluszeichen"
      },
      "add-character": {
        "title": "Charakter hinzufügen",
        "alt": "Ein Schulterprofil mit einem Pluszeichen"
      },
      "app-code": {
        "title": "App-Code",
        "alt": "Ein Smartphone mit einem Schild"
      },
      "arrow-down": {
        "title": "Abwärtspfeil",
        "alt": "Ein Pfeil, der nach unten zeigt"
      },
      "arrow-left": {
        "title": "Linkspfeil",
        "alt": "Ein Pfeil, der nach links zeigt"
      },
      "arrow-right": {
        "title": "Rechtspfeil",
        "alt": "Ein Pfeil, der nach rechts zeigt"
      },
      "arrow-up": {
        "title": "Aufwärtspfeil",
        "alt": "Ein Pfeil, der nach oben zeigt"
      },
      "backup-code": {
        "title": "Backup-Code",
        "alt": "Ein Schild mit einem Schlüssel"
      },
      "bank-card": {
        "title": "Bankkarte",
        "alt": "Eine Bankkarte"
      },
      "close": {
        "title": "Schließen",
        "alt": "Ein X"
      },
      "character": {
        "title": "Charakter",
        "alt": "Ein Charakter mit einem Schwert und einem Schild"
      },
      "checkmark": {
        "title": "Häkchen",
        "alt": "Ein Häkchen"
      },
      "cookie": {
        "title": "",
        "alt": ""
      },
      "data": {
        "title": "Daten",
        "alt": "Ein Fingerabdruck"
      },
      "edit": {
        "title": "Bearbeiten",
        "alt": "Ein Stift"
      },
      "email": {
        "title": "E-Mail",
        "alt": "Ein geschlossener Umschlag"
      },
      "email-code": {
        "title": "E-Mail-Code",
        "alt": "Ein Umschlag mit einem Schild"
      },
      "error": {
        "title": "Fehler",
        "alt": "Ein Ausrufezeichen in einem Kreis"
      },
      "external-link": {
        "title": "Symbol für einen externen Link",
        "alt": "Ein Pfeil, der aus einem Quadrat herauszeigt"
      },
      "favourite": {
        "title": "Favorisieren",
        "alt": "Ein Stern"
      },
      "feedback": {
        "title": "Feedback",
        "alt": "Eine Sprechblase und ein Stift"
      },
      "globe": {
        "title": "Globus",
        "alt": "Ein Globus"
      },
      "hidden": {
        "title": "Versteckt",
        "alt": "Ein Auge mit einem Strich darüber"
      },
      "info": {
        "title": "Infos",
        "alt": "Ein I in einem Kreis"
      },
      "invalid": {
        "title": "Ungültig",
        "alt": "Ein X in einem Kreis"
      },
      "legal": {
        "title": "Rechtliches",
        "alt": "Eine Waage"
      },
      "link": {
        "title": "Verlinkung",
        "alt": "Ein Kettenglied"
      },
      "logout": {
        "title": "Ausloggen",
        "alt": "Ein Pfeil, der aus einer Tür herauszeigt"
      },
      "menu": {
        "title": "Menü",
        "alt": "Drei horizontale, übereinanderliegende Linien"
      },
      "minus": {
        "title": "Minus",
        "alt": "Ein Minuszeichen"
      },
      "options": {
        "title": "Optionen",
        "alt": "Drei Punkte"
      },
      "pause": {
        "title": "Pause",
        "alt": "Zwei vertikale parallele Linien"
      },
      "phone": {
        "title": "Smartphone",
        "alt": "Ein Smartphone"
      },
      "pin": {
        "title": "Anpinnen",
        "alt": "Eine Pinnwandnadel"
      },
      "play": {
        "title": "Abspielen",
        "alt": "Dreieck zeigt nach rechts"
      },
      "plus": {
        "title": "Plus",
        "alt": "Ein Pluszeichen"
      },
      "profile": {
        "title": "Profil",
        "alt": "Ein Schulterprofil"
      },
      "safety": {
        "title": "Sicherheit",
        "alt": ""
      },
      "search": {
        "title": "Suche",
        "alt": "Eine Lupe"
      },
      "settings": {
        "title": "Einstellungen",
        "alt": "Ein Zahnrad"
      },
      "store": {
        "title": "Laden",
        "alt": "Eine Handtasche"
      },
      "success": {
        "title": "Erfolgreich",
        "alt": "Ein Häkchen in einem Kreis"
      },
      "visible": {
        "title": "Sichtbar",
        "alt": "Ein Auge"
      },
      "uninstall": {
        "title": "Deinstallieren",
        "alt": "Ein Mülleimer"
      },
      "web": {
        "title": "Internet",
        "alt": "Ein Globus"
      }
    },
    "social-media-icons": {
      "amazon-flat": {
        "title": "Amazon",
        "alt": "Amazon-Symbol"
      },
      "apple-flat": {
        "title": "Apple",
        "alt": "Apple-Symbol"
      },
      "facebook-flat": {
        "title": "Facebook",
        "alt": "Facebook-Symbol"
      },
      "google-flat": {
        "title": "Google",
        "alt": "Google-Symbol"
      },
      "instagram-flat": {
        "title": "Instagram",
        "alt": "Instagram-Symbol"
      },
      "steam-flat": {
        "title": "Steam",
        "alt": "Steam-Symbol"
      },
      "twitter-flat": {
        "title": "Twitter",
        "alt": "Twitter-Symbol"
      }
    },
    "brand-flat-icons": {
      "android-flat": {
        "title": "Android",
        "alt": "Android-Symbol"
      },
      "axeso5-flat": {
        "title": "",
        "alt": ""
      },
      "boa-compra-flat": {
        "title": "",
        "alt": ""
      },
      "epic-games-flat": {
        "title": "Epic Games",
        "alt": "Symbol von Epic Games"
      },
      "ios-flat": {
        "title": "iOS",
        "alt": "iOS-Symbol"
      },
      "jagex-flat": {
        "title": "Jagex",
        "alt": "Jagex-Symbol"
      },
      "jagex-pin-flat": {
        "title": "Jagex",
        "alt": "Jagex-Symbol"
      },
      "linux-flat": {
        "title": "Linux",
        "alt": "Linux-Symbol"
      },
      "steam-round-flat": {
        "title": "Steam",
        "alt": "Steam-Symbol"
      },
      "melvor-idle-flat": {
        "title": "Melvor Idle",
        "alt": "Symbol von Melvor Idle"
      },
      "old-school-runescape-flat": {
        "title": "Old School RuneScape",
        "alt": "Symbol von Old School RuneScape"
      },
      "runescape-flat": {
        "title": "RuneScape",
        "alt": "RuneScape-Symbol"
      },
      "runescape-lite-flat": {
        "title": "RuneScape Lite",
        "alt": "Symbol von RuneScape Lite"
      },
      "space-punks-flat": {
        "title": "Space Punks",
        "alt": "Symbol von Space Punks"
      },
      "this-means-warp-flat": {
        "title": "This Means Warp",
        "alt": "Symbol von This Means Warp"
      },
      "windows-flat": {
        "title": "Windows",
        "alt": "Windows-Symbol"
      }
    },
    "brand-icons-color": {
      "old-school-runescape-color": {
        "title": "Old School RuneScape",
        "alt": "Symbol von Old School RuneScape"
      },
      "runescape-color": {
        "title": "RuneScape",
        "alt": "RuneScape-Symbol"
      }
    },
    "pegi-icons": {
      "pegi-age-03": {
        "title": "'PEGI 3'-Symbol",
        "alt": "'PEGI 3'-Symbol"
      },
      "pegi-age-07": {
        "title": "'PEGI 7'-Symbol",
        "alt": "'PEGI 7'-Symbol"
      },
      "pegi-age-12": {
        "title": "'PEGI 12'-Symbol",
        "alt": "'PEGI 12'-Symbol"
      },
      "pegi-age-16": {
        "title": "'PEGI 16'-Symbol",
        "alt": "'PEGI 16'-Symbol"
      },
      "pegi-age-18": {
        "title": "'PEGI 18'-Symbol",
        "alt": "'PEGI 18'-Symbol"
      },
      "pegi-content-discrimination": {
        "title": "PEGI-Inhaltssymbol für Diskriminierung",
        "alt": "PEGI-Inhaltssymbol für Diskriminierung"
      },
      "pegi-content-drugs": {
        "title": "PEGI-Inhaltssymbol für Drogen",
        "alt": "PEGI-Inhaltssymbol für Drogen"
      },
      "pegi-content-fear": {
        "title": "PEGI-Inhaltssymbol für Ängste",
        "alt": "PEGI-Inhaltssymbol für Ängste"
      },
      "pegi-content-gambling": {
        "title": "PEGI-Inhaltssymbol für Glücksspiele",
        "alt": "PEGI-Inhaltssymbol für Glücksspiele"
      },
      "pegi-content-language": {
        "title": "PEGI-Inhaltssymbol für Sprachen",
        "alt": "PEGI-Inhaltssymbol für Sprachen"
      },
      "pegi-content-purchases": {
        "title": "PEGI-Inhaltssymbol für Käufe",
        "alt": "PEGI-Inhaltssymbol für Käufe"
      },
      "pegi-content-sex": {
        "title": "PEGI-Inhaltssymbol für Sex",
        "alt": "PEGI-Inhaltssymbol für Sex"
      },
      "pegi-content-violence": {
        "title": "PEGI-Inhaltssymbol für Gewalt",
        "alt": "PEGI-Inhaltssymbol für Gewalt"
      }
    }
  }
}
