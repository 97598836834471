import { FloatingPortal as Root } from "@floating-ui/react";
import type { CSSProperties, HTMLAttributes, MouseEvent } from "react";
import { KeyboardEvent, useCallback, useEffect, useRef } from "react";

import { Spacer } from "../../atoms/Spacer";
import { ButtonGroup } from "./ButtonGroup";
import { CloseButton } from "./CloseButton";
import { Dialog, DialogWrapper, ModalHeading, Overlay, Wrapper } from "./styles";

export type ModalSize = "s" | "m" | "l" | "fit-content";

export type ModalProps = HTMLAttributes<HTMLDialogElement> & {
  /**
   * Heading text for the modal interface.
   */
  heading: string;
  /**
   * Alignment of the modal heading within the element.
   */
  headingAlign?: CSSProperties["textAlign"];
  /**
   * HTML id attribute, referencing the modal heading.
   */
  headingId?: string;
  /**
   * Width of the modal window
   */
  width?: ModalSize;
  /**
   * Height of the modal window
   */
  height?: ModalSize;
} & (
    | {
        /**
         * The modal closes on clicking the area out of the modal and on the ESC keyboard down.
         */
        disabledCloseAction?: false;
        /**
         * Function to call when the modal is cancelled or closed on clicking outside.
         * As far disabledCloseAction is off - required.
         */
        onClose: () => void;
      }
    | {
        /**
         * Disables on clicking the area out of the modal and ESC keyboard event.
         */
        disabledCloseAction: true;
        /**
         * As far disabledCloseAction is on - optional.
         */
        onClose?: () => void;
      }
  );

/**
 * Dialog element must be placed separately from the FloatingPortal, otherwise, useEffect() will be processed
 * before the dialog is populated, and showModal will not work, since the ref will be equal to null.
 */
function DialogEntity({
  disabledCloseAction = false,
  heading,
  headingAlign,
  headingId,
  onClose,
  children,
  width = "fit-content",
  height = "fit-content",
  ...rest
}: ModalProps): JSX.Element {
  const dialogRef = useRef<HTMLDialogElement>(null);

  // Triggers onclose when cancel detected
  const onCancel = useCallback(
    (event: KeyboardEvent<HTMLDialogElement>) => {
      event.preventDefault();

      if (!disabledCloseAction && onClose) {
        onClose();
      }
    },
    [disabledCloseAction, onClose]
  );

  // Triggers onClose when click outside
  const onClick = useCallback(
    (event: MouseEvent<HTMLDialogElement>) => {
      const { target } = event;

      if (target === dialogRef.current && !disabledCloseAction && onClose) {
        onClose();
      }
    },
    [disabledCloseAction, onClose, dialogRef]
  );

  useEffect(() => {
    dialogRef?.current?.showModal?.();
  }, [dialogRef]);

  return (
    <Dialog
      role="dialog"
      aria-modal="true"
      ref={dialogRef}
      onClose={onClose}
      onCancel={onCancel}
      onClick={onClick}
      width={width}
      height={height}
      {...rest}
    >
      <DialogWrapper>
        <ModalHeading level="h3" align={headingAlign} id={headingId}>
          {heading}
        </ModalHeading>
        <Spacer multiplier={3} />
        <Wrapper>{children}</Wrapper>
      </DialogWrapper>
    </Dialog>
  );
}

/**
 * Dialogue openable in front of the current interface to either inform the user or ask for an operation.
 */
export function Modal({
  disabledCloseAction,
  heading,
  headingAlign,
  headingId,
  onClose,
  children,
  ...rest
}: ModalProps): JSX.Element {
  return (
    <Root>
      <Overlay lockScroll>
        {disabledCloseAction ? (
          <DialogEntity
            disabledCloseAction={true}
            onClose={onClose}
            heading={heading}
            headingAlign={headingAlign}
            headingId={headingId}
            data-testid={Modal.name}
            {...rest}
          >
            {children}
          </DialogEntity>
        ) : (
          <DialogEntity
            disabledCloseAction={false}
            onClose={onClose}
            heading={heading}
            headingAlign={headingAlign}
            headingId={headingId}
            data-testid={Modal.name}
            {...rest}
          >
            {children}
          </DialogEntity>
        )}
      </Overlay>
    </Root>
  );
}

Modal.ButtonGroup = ButtonGroup;
Modal.CloseButton = CloseButton;
