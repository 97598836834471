"use client";

import styled from "@emotion/styled";

import { Button } from "../../../atoms/Button";
import { colors } from "../../../design-tokens/colors";
import { sizes } from "../../../design-tokens/dimensions";

export const Root = styled(Button)`
  --mask-icon-color: ${colors.white};

  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  position: absolute;
  right: ${sizes.s24.rem};
  width: ${sizes.s24.rem};
`;
