"use client";

import styled from "@emotion/styled";

import { Button as ButtonComponent } from "../../../atoms/Button";
import { clickableItemBaseStyles, ClickableItemProps } from "../styles";

export const Root = styled(ButtonComponent)<ClickableItemProps>`
  ${(props) => clickableItemBaseStyles(props)}
`;
