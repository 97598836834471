"use client";

import styled from "@emotion/styled";
import { rem } from "polished";
import type { ComponentProps } from "react";

import { device, sizes } from "../../../design-tokens/dimensions";
import { Alert } from "../../../molecules/Alert";

type Props = { isOpen: boolean } & ComponentProps<typeof Alert>;

/**
 * Renders a positioned {@link Alert} for use in the {@link BoxFlow} interface
 */
export function Toast({ children, isOpen, ...rest }: Props) {
  if (!isOpen) {
    return null;
  }

  return (
    <Root data-testid={Toast.name} {...rest}>
      {children}
    </Root>
  );
}

const Root = styled(Alert)`
  bottom: 0;
  left: 50%;
  position: fixed;
  transform: translateX(-50%);

  @media (${device.xsmall}) {
    bottom: ${sizes.s24.rem};
    max-width: ${rem(520)};
  }
`;
