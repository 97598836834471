"use client";

import styled from "@emotion/styled";
import { transparentize } from "polished";

import { colors, ColorValue } from "../../../design-tokens/colors";
import { sizes } from "../../../design-tokens/dimensions";
import { MenuVariant } from "../Menu";

const variants = {
  default: transparentize(0.9, colors.white),
  light: colors.betaBase,
} satisfies Record<MenuVariant, ColorValue>;

export const Root = styled.li<{ variant?: MenuVariant }>`
  --mask-icon-size: ${sizes.s16.rem};

  align-items: center;
  align-self: stretch;
  border-bottom: 1px solid ${transparentize(0.9, colors.white)};
  cursor: pointer;
  display: flex;
  gap: ${sizes.s8.rem};
  height: ${sizes.s48.rem};
  padding: ${sizes.s12.rem};

  &:focus,
  &:focus-visible {
    outline-offset: -1px;
  }

  &:hover {
    background-color: ${({ variant }) => variants[variant || "default"]};
  }
`;
