"use client";

import styled from "@emotion/styled";
import { rem } from "polished";

import { colors } from "../../design-tokens/colors";
import { globalContentMaxWidth } from "../../design-tokens/dimensions";

export const TabNavList = styled.ul`
  border-bottom: 1px solid ${colors.baseMid};
  display: flex;
  flex-direction: row;
  justify-content: stretch;
  list-style: none;
  margin: 0 auto;
  max-width: ${rem(globalContentMaxWidth)};
  padding: 0;
  width: 100%;
`;
