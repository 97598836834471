import type { HTMLAttributes } from "react";

import { Menu } from "../../../molecules/Menu";
import type { ItemProps } from "../../../molecules/Menu/Item";

/**
 * Header.DropdownItem - submenu item must be in Dropdown as child element.
 */
export const DropdownItem = (props: ItemProps & HTMLAttributes<HTMLLIElement>) => {
  return <Menu.Item {...props} />;
};
