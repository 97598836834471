import type { HTMLAttributes, ReactNode } from "react";

import { useTranslate } from "../../../utils/translate";
import { FooterLogo } from "./FooterLogo";
import { FooterPEGI, FooterPEGIProps } from "./FooterPEGI/FooterPEGI";
import { Content, Copyright, FooterLink, Inner, Links, LinksRow, Logos, Root } from "./styles";
import { translations } from "./translations";

type FooterProps = {
  /**
   * Additional logos to be displayed next to the Jagex Logo. This should be a ReactNode of `Footer.Logo`.
   */
  extraLogos?: ReactNode | ReactNode[];
  /**
   * PEGI icons and link to display
   */
  pegiConfig?: FooterPEGIProps;
  /**
   * Override the default Jagex Cookie declaration url to a custom url.
   */
  cookieDeclarationUrl?: string;
  /**
   * Inserts extra Footer.Link(s) into the Footer after all links but before the CPRA link.
   * The CPRA link is last as a requirement from legal.
   */
  children?: ReactNode | ReactNode[];
} & HTMLAttributes<HTMLDivElement>;

/**
 * A basic footer tobe shown across all Jagex affiliated products
 */
export function Footer({
  extraLogos,
  pegiConfig,
  cookieDeclarationUrl = "https://www.jagex.com/terms/cookies",
  children,
  ...rest
}: FooterProps): JSX.Element {
  const t = useTranslate(translations);

  return (
    <Root {...rest} data-testid={Footer.name}>
      <Inner>
        <Logos aria-label={t("footer.logo-nav")} $inCenter={pegiConfig !== undefined}>
          <Footer.Logo
            title={t("footer.logo-title")}
            url="https://www.jagex.com"
            logo="jagex-full-flat"
          />
          {extraLogos}
        </Logos>
        {pegiConfig && <Footer.Pegi {...pegiConfig} />}
        <Content>
          <Links>
            <LinksRow>
              <FooterLink
                href="https://www.jagex.com/contact-us"
                data-testid={`${FooterLink.displayName}-contact`}
                target="_blank"
                title={t("footer.contact-title")}
              >
                {t("footer.contact")}
              </FooterLink>
              <FooterLink
                href="https://www.jagex.com/gender-pay-gap"
                data-testid={`${FooterLink.displayName}-gender`}
                target="_blank"
                title={t("footer.gender-title")}
              >
                {t("footer.gender")}
              </FooterLink>
              <FooterLink
                href={cookieDeclarationUrl}
                data-testid={`${FooterLink.displayName}-cookies`}
                target="_blank"
                title={t("footer.cookie-title")}
              >
                {t("footer.cookie")}
              </FooterLink>
            </LinksRow>
            <LinksRow>
              <FooterLink
                href="https://www.jagex.com/terms"
                data-testid={`${FooterLink.displayName}-terms`}
                target="_blank"
                title={t("footer.terms-title")}
              >
                {t("footer.terms")}
              </FooterLink>
              <FooterLink
                href="https://www.jagex.com/terms/privacy"
                data-testid={`${FooterLink.displayName}-privacy`}
                target="_blank"
                title={t("footer.privacy-title")}
              >
                {t("footer.privacy")}
              </FooterLink>
              <FooterLink
                href="https://www.jagex.com/terms/legal"
                data-testid={`${FooterLink.displayName}-legal`}
                target="_blank"
                title={t("footer.legal-title")}
              >
                {t("footer.legal")}
              </FooterLink>
              {children}
            </LinksRow>
            <LinksRow>
              <FooterLink
                href="https://www.jagex.com/terms/privacy#do-not-sell"
                data-testid={`${FooterLink.displayName}-do-not-sell`}
                target="_blank"
                title={t("footer.do-not-sell.title")}
              >
                {t("footer.do-not-sell.text")}
              </FooterLink>
            </LinksRow>
          </Links>
          <Copyright data-testid={Copyright.displayName}>
            {t("footer.copyright").replace("{{year}}", `${new Date().getFullYear()}`)}
          </Copyright>
        </Content>
      </Inner>
    </Root>
  );
}

Footer.Link = FooterLink;
Footer.Logo = FooterLogo;
Footer.Pegi = FooterPEGI;
