"use client";

import styled from "@emotion/styled";

import { device } from "../../../design-tokens/dimensions";
import { mobileNavBreakpoint } from "../styles";

export const Root = styled.div`
  order: 2;
  position: relative;
  width: 100%;

  @media (${device[mobileNavBreakpoint]}) {
    order: unset;
  }
`;
export const Content = styled.div`
  height: 100%;
  position: relative;
`;
