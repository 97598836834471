{
  "icons": {
    "regular-icons": {
      "add": {
        "title": "Adicionar",
        "alt": "Um sinal de adição."
      },
      "add-character": {
        "title": "Adicionar personagem",
        "alt": "Uma silhueta do ombro para cima com um sinal de adição"
      },
      "app-code": {
        "title": "Código do aplicativo",
        "alt": "Um celular com um escudo."
      },
      "arrow-down": {
        "title": "Seta para baixo",
        "alt": "Seta apontando para baixo."
      },
      "arrow-left": {
        "title": "Seta para a esquerda",
        "alt": "Seta apontando para a esquerda."
      },
      "arrow-right": {
        "title": "Seta para a direita",
        "alt": "Seta apontando para a direita."
      },
      "arrow-up": {
        "title": "Seta para cima",
        "alt": "Seta apontando para cima."
      },
      "backup-code": {
        "title": "Código de backup",
        "alt": "Um escudo com uma chave."
      },
      "bank-card": {
        "title": "Cartão de banco",
        "alt": "Um cartão de banco."
      },
      "close": {
        "title": "Fechar",
        "alt": "Uma cruz."
      },
      "character": {
        "title": "Personagem",
        "alt": "Um personagem com uma espada e um escudo"
      },
      "checkmark": {
        "title": "Sinal de confirmação",
        "alt": "Um sinal de confirmação."
      },
      "cookie": {
        "title": "",
        "alt": ""
      },
      "data": {
        "title": "Dados",
        "alt": "Uma impressão digital."
      },
      "edit": {
        "title": "Editar",
        "alt": "Um lápis."
      },
      "email": {
        "title": "E-mail",
        "alt": "Um envelope fechado."
      },
      "email-code": {
        "title": "Código de e-mail",
        "alt": "Um envelope com um escudo."
      },
      "error": {
        "title": "Erro",
        "alt": "Um ponto de exclamação em um círculo."
      },
      "external-link": {
        "title": "Ícone de link externo",
        "alt": "Seta apontando para um quadrado."
      },
      "favourite": {
        "title": "Favorito",
        "alt": "Uma estrela."
      },
      "feedback": {
        "title": "Feedback",
        "alt": "Um balão de mensagem e uma caneta."
      },
      "globe": {
        "title": "Globo",
        "alt": "Um globo"
      },
      "hidden": {
        "title": "Oculto",
        "alt": "Um olho com uma linha atravessando-o."
      },
      "info": {
        "title": "Informação",
        "alt": "Um i em um círculo."
      },
      "invalid": {
        "title": "Inválido",
        "alt": "Um X em um círculo."
      },
      "legal": {
        "title": "Jurídico",
        "alt": "Um par de balanças."
      },
      "link": {
        "title": "Link",
        "alt": "Uma corrente."
      },
      "logout": {
        "title": "Encerrar sessão",
        "alt": "Seta apontando para uma porta."
      },
      "menu": {
        "title": "Menu",
        "alt": "Três linhas horizontais empilhadas."
      },
      "minus": {
        "title": "Menos",
        "alt": "Um sinal de subtração"
      },
      "options": {
        "title": "Opções",
        "alt": "Três pontos."
      },
      "pause": {
        "title": "Pausa",
        "alt": "Duas linhas verticais paralelas"
      },
      "phone": {
        "title": "Celular",
        "alt": "Um smartphone."
      },
      "pin": {
        "title": "Fixar",
        "alt": "Uma tachinha para quadros."
      },
      "play": {
        "title": "Reproduzir",
        "alt": "Triângulo apontando para a direita"
      },
      "plus": {
        "title": "Mais",
        "alt": "Um sinal de adição"
      },
      "profile": {
        "title": "Perfil",
        "alt": "Um retrato de ombro."
      },
      "safety": {
        "title": "Segurança",
        "alt": ""
      },
      "search": {
        "title": "Pesquisa",
        "alt": "Uma lupa."
      },
      "settings": {
        "title": "Configurações",
        "alt": "Uma engrenagem de máquina."
      },
      "store": {
        "title": "Loja",
        "alt": "Uma bolsa de mão."
      },
      "success": {
        "title": "Sucesso",
        "alt": "Um sinal de verificação em um círculo."
      },
      "visible": {
        "title": "Visível",
        "alt": "Um olho."
      },
      "uninstall": {
        "title": "Desinstalar",
        "alt": "Uma lixeira"
      },
      "web": {
        "title": "Internet",
        "alt": "Um globo."
      }
    },
    "social-media-icons": {
      "amazon-flat": {
        "title": "Amazon",
        "alt": "Ícone da Amazon."
      },
      "apple-flat": {
        "title": "Apple",
        "alt": "Ícone da Apple."
      },
      "facebook-flat": {
        "title": "Facebook",
        "alt": "Ícone do Facebook."
      },
      "google-flat": {
        "title": "Google",
        "alt": "Ícone do Google."
      },
      "instagram-flat": {
        "title": "Instagram",
        "alt": "Ícone do Instagram."
      },
      "steam-flat": {
        "title": "Steam",
        "alt": "Ícone da Steam."
      },
      "twitter-flat": {
        "title": "Twitter",
        "alt": "Ícone do Twitter."
      }
    },
    "brand-flat-icons": {
      "android-flat": {
        "title": "Android",
        "alt": "Ícone da Android"
      },
      "axeso5-flat": {
        "alt": "",
        "title": "Axeso5"
      },
      "boa-compra-flat": {
        "alt": "",
        "title": "Boa Compra"
      },
      "epic-games-flat": {
        "title": "Epic Games",
        "alt": "Ícone da Epic Games."
      },
      "ios-flat": {
        "title": "iOS",
        "alt": "Ícone da iOS"
      },
      "jagex-flat": {
        "title": "Jagex",
        "alt": "Ícone da Jagex."
      },
      "jagex-pin-flat": {
        "title": "Jagex",
        "alt": "Ícone da Jagex."
      },
      "linux-flat": {
        "title": "Linux",
        "alt": "Ícone da Linux"
      },
      "steam-round-flat": {
        "title": "Steam",
        "alt": "Ícone da Steam."
      },
      "melvor-idle-flat": {
        "title": "Melvor Idle",
        "alt": "Ícone do Melvor Idle."
      },
      "old-school-runescape-flat": {
        "title": "Old School RuneScape",
        "alt": "Ícone do Old School RuneScape."
      },
      "runescape-flat": {
        "title": "RuneScape",
        "alt": "Ícone do RuneScape."
      },
      "runescape-lite-flat": {
        "title": "RuneScape Lite",
        "alt": "Ícone do RuneScape Lite."
      },
      "space-punks-flat": {
        "title": "Space Punks",
        "alt": "Ícone do Space Punks."
      },
      "this-means-warp-flat": {
        "title": "This Means Warp",
        "alt": "Ícone do This Means Warp."
      },
      "windows-flat": {
        "title": "Windows",
        "alt": "Ícone da Windows"
      }
    },
    "brand-icons-color": {
      "old-school-runescape-color": {
        "title": "Old School RuneScape",
        "alt": "Ícone do Old School RuneScape."
      },
      "runescape-color": {
        "title": "RuneScape",
        "alt": "Ícone do RuneScape."
      }
    },
    "pegi-icons": {
      "pegi-age-03": {
        "title": "Ícone PEGI 3.",
        "alt": "Ícone PEGI 3."
      },
      "pegi-age-07": {
        "title": "Ícone PEGI 7.",
        "alt": "Ícone PEGI 7."
      },
      "pegi-age-12": {
        "title": "Ícone PEGI 12.",
        "alt": "Ícone PEGI 12."
      },
      "pegi-age-16": {
        "title": "Ícone PEGI 16.",
        "alt": "Ícone PEGI 16."
      },
      "pegi-age-18": {
        "title": "Ícone PEGI 18.",
        "alt": "Ícone PEGI 18."
      },
      "pegi-content-discrimination": {
        "title": "Ícone PEGI de conteúdo discriminatório.",
        "alt": "Ícone PEGI de conteúdo discriminatório."
      },
      "pegi-content-drugs": {
        "title": "Ícone PEGI de conteúdo representando drogas.",
        "alt": "Ícone PEGI de conteúdo representando drogas."
      },
      "pegi-content-fear": {
        "title": "Ícone PEGI de conteúdo de terror.",
        "alt": "Ícone PEGI de conteúdo de terror."
      },
      "pegi-content-gambling": {
        "title": "Ícone PEGI de conteúdo de apostas.",
        "alt": "Ícone PEGI de conteúdo de apostas."
      },
      "pegi-content-language": {
        "title": "Ícone PEGI de conteúdo de linguagem ofensiva.",
        "alt": "Ícone PEGI de conteúdo de linguagem ofensiva."
      },
      "pegi-content-purchases": {
        "title": "Ícone PEGI de conteúdo contendo compras.",
        "alt": "Ícone PEGI de conteúdo contendo compras."
      },
      "pegi-content-sex": {
        "title": "Ícone PEGI de conteúdo sexual.",
        "alt": "Ícone PEGI de conteúdo sexual."
      },
      "pegi-content-violence": {
        "title": "Ícone PEGI de conteúdo violento.",
        "alt": "Ícone PEGI de conteúdo violento."
      }
    }
  }
}
