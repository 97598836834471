"use client";

import styled from "@emotion/styled";
import { rem } from "polished";

import { Button } from "../../../../../atoms/Button";
import { sizes } from "../../../../../design-tokens/dimensions";

export const Root = styled(Button)`
  height: ${sizes.s48.rem};
  margin-bottom: ${rem(20)};
  min-width: 0;
  width: ${sizes.s48.rem};
`;
