import {
  autoUpdate,
  flip,
  offset,
  Placement,
  useDismiss,
  useFloating,
  useInteractions,
} from "@floating-ui/react";
import type { HTMLAttributes } from "react";
import { forwardRef, useLayoutEffect } from "react";

import { Content } from "./styles";

export type PopperProps = {
  /**
   * Ref to the anchor HTML element.
   */
  anchorRef: HTMLElement | null;
  /**
   * Whether the Popper should be open.
   */
  isOpen: boolean;
  /**
   * A callback function that will be called when the dialog should be opened or closed.
   */
  onOpenChange?: (open: boolean) => void;
  /**
   * Popper content initial placement {@link Placement}.
   */
  initialPlacement?: Placement;
  /**
   * Distance between ref anchor element and Popper content.
   */
  floatingOffset?: number;
} & HTMLAttributes<HTMLDivElement>;

export const Popper = forwardRef<HTMLElement, PopperProps>(function (
  { children, initialPlacement, isOpen, anchorRef, onOpenChange, floatingOffset = 20, ...rest },
  _
) {
  const {
    x,
    y,
    strategy,
    refs: { setReference, setFloating },
    context,
  } = useFloating({
    placement: initialPlacement || "bottom",
    open: isOpen,
    onOpenChange: onOpenChange,
    middleware: [offset(floatingOffset), flip()],
    whileElementsMounted: autoUpdate,
  });

  const dismiss = useDismiss(context);
  const { getFloatingProps } = useInteractions([dismiss]);

  useLayoutEffect(() => {
    if (anchorRef) {
      setReference(anchorRef);
    }
  }, [setReference, anchorRef]);

  return (
    <>
      {isOpen && (
        <Content
          data-testid={Popper.displayName}
          ref={setFloating}
          position={strategy}
          top={y ?? 0}
          left={x ?? 0}
          {...rest}
          {...getFloatingProps()}
        >
          {children}
        </Content>
      )}
    </>
  );
});

Popper.displayName = "Popper";
