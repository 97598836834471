import type { HTMLAttributes } from "react";
import React from "react";

import { DescriptionKey, DescriptionValue, DescriptionWrapper, Root } from "./styles";

export type ProductDetailsProps = HTMLAttributes<HTMLDListElement> & {
  data: [key: string, value: string][];
};

export function ProductDetails({ data, ...args }: ProductDetailsProps) {
  return (
    <Root data-testid={`Multiplex${ProductDetails.name}`} {...args}>
      {data.map(([key, value]) => (
        <DescriptionWrapper key={key}>
          <DescriptionKey>{key}</DescriptionKey>
          <DescriptionValue>{value}</DescriptionValue>
        </DescriptionWrapper>
      ))}
    </Root>
  );
}
