{
  "icons": {
    "regular-icons": {
      "add": {
        "title": "Add",
        "alt": "A plus"
      },
      "add-character": {
        "title": "Add Character",
        "alt": "A shoulder portrait with a plus sign"
      },
      "app-code": {
        "title": "App code",
        "alt": "A phone with a shield"
      },
      "arrow-down": {
        "title": "Arrow Down",
        "alt": "An arrow pointing down"
      },
      "arrow-left": {
        "title": "Arrow Left",
        "alt": "An arrow pointing left"
      },
      "arrow-right": {
        "title": "Arrow Right",
        "alt": "An arrow pointing right"
      },
      "arrow-up": {
        "title": "Arrow Up",
        "alt": "An arrow pointing up"
      },
      "backup-code": {
        "title": "Backup Code",
        "alt": "A shield with a key"
      },
      "bank-card": {
        "title": "Bank card",
        "alt": "A bank card"
      },
      "character": {
        "title": "Character",
        "alt": "A character with a sword and a shield"
      },
      "close": {
        "title": "Close",
        "alt": "A cross"
      },
      "checkmark": {
        "title": "Checkmark",
        "alt": "A tick"
      },
      "cookie": {
        "title": "Cookie",
        "alt": "A cookie"
      },
      "data": {
        "title": "Data",
        "alt": "A fingerprint"
      },
      "edit": {
        "title": "Edit",
        "alt": "A pencil"
      },
      "email": {
        "title": "Email",
        "alt": "A closed envelope"
      },
      "email-code": {
        "title": "Email Code",
        "alt": "An envelope with a shield"
      },
      "error": {
        "title": "Error",
        "alt": "An exclamation mark in a circle"
      },
      "external-link": {
        "title": "External Link Icon",
        "alt": "An arrow pointing out a square"
      },
      "favourite": {
        "title": "Favourite",
        "alt": "A star"
      },
      "feedback": {
        "title": "Feedback",
        "alt": "A message bubble and a pen"
      },
      "globe": {
        "title": "Globe",
        "alt": "A globe"
      },
      "hidden": {
        "title": "Hidden",
        "alt": "An eye with a line across it"
      },
      "info": {
        "title": "Info",
        "alt": "An i in a circle"
      },
      "invalid": {
        "title": "Invalid",
        "alt": "An x in a circle"
      },
      "legal": {
        "title": "Legal",
        "alt": "A pair of scales"
      },
      "link": {
        "title": "Link",
        "alt": "A chain link"
      },
      "logout": {
        "title": "Logout",
        "alt": "An arrow pointing out a door"
      },
      "menu": {
        "title": "Menu",
        "alt": "Three vertically stacked horizontal lines"
      },
      "minus": {
        "title": "Minus",
        "alt": "A minus"
      },
      "options": {
        "title": "Options",
        "alt": "Three dots"
      },
      "pause": {
        "title": "Pause",
        "alt": "Two vertical parallel lines"
      },
      "phone": {
        "title": "Phone",
        "alt": "A smart phone"
      },
      "pin": {
        "title": "Pin",
        "alt": "A board pin"
      },
      "play": {
        "title": "Play",
        "alt": "Triangle pointing to the right"
      },
      "plus": {
        "title": "Plus",
        "alt": "A plus"
      },
      "profile": {
        "title": "Profile",
        "alt": "A shoulder portrait"
      },
      "safety": {
        "title": "Safety",
        "alt": ""
      },
      "search": {
        "title": "Search",
        "alt": "A magnifying glass"
      },
      "settings": {
        "title": "Settings",
        "alt": "A machine cog"
      },
      "store": {
        "title": "Store",
        "alt": "A hand bag"
      },
      "success": {
        "title": "Success",
        "alt": "A tick in a circle"
      },
      "uninstall": {
        "title": "Uninstall",
        "alt": "A trash bin"
      },
      "visible": {
        "title": "Visible",
        "alt": "An eye"
      },
      "web": {
        "title": "Web",
        "alt": "A globe"
      }
    },
    "social-media-icons": {
      "amazon-flat": {
        "title": "Amazon",
        "alt": "Amazon icon"
      },
      "apple-flat": {
        "title": "Apple",
        "alt": "Apple icon"
      },
      "facebook-flat": {
        "title": "Facebook",
        "alt": "Facebook icon"
      },
      "google-flat": {
        "title": "Google",
        "alt": "Google icon"
      },
      "instagram-flat": {
        "title": "Instagram",
        "alt": "Instagram icon"
      },
      "steam-flat": {
        "title": "Steam",
        "alt": "Steam icon"
      },
      "twitter-flat": {
        "title": "Twitter",
        "alt": "Twitter icon"
      }
    },
    "brand-flat-icons": {
      "android-flat": {
        "title": "Android",
        "alt": "Android icon"
      },
      "axeso5-flat": {
        "title": "Axeso5",
        "alt": "Axeso5 icon"
      },
      "boa-compra-flat": {
        "title": "Boa Compra",
        "alt": "Boa Compra icon"
      },
      "epic-games-flat": {
        "title": "Epic Games",
        "alt": "Epic Games icon"
      },
      "ios-flat": {
        "title": "iOS",
        "alt": "iOS icon"
      },
      "jagex-flat": {
        "title": "Jagex",
        "alt": "Jagex icon"
      },
      "jagex-pin-flat": {
        "title": "Jagex",
        "alt": "Jagex icon"
      },
      "linux-flat": {
        "title": "Linux",
        "alt": "Linux icon"
      },
      "steam-round-flat": {
        "title": "Steam",
        "alt": "Steam icon"
      },
      "melvor-idle-flat": {
        "title": "Melvor Idle",
        "alt": "Melvor Idle icon"
      },
      "old-school-runescape-flat": {
        "title": "Old School Runescape",
        "alt": "Old School RuneScape icon"
      },
      "runescape-flat": {
        "title": "RuneScape",
        "alt": "RuneScape icon"
      },
      "runescape-lite-flat": {
        "title": "RuneScape Lite",
        "alt": "RuneScape Lite icon"
      },
      "space-punks-flat": {
        "title": "Space Punks",
        "alt": "Space Punks icon"
      },
      "this-means-warp-flat": {
        "title": "This Means Warp",
        "alt": "This Means Warp icon"
      },
      "windows-flat": {
        "title": "Windows",
        "alt": "Windows icon"
      }
    },
    "brand-icons-color": {
      "old-school-runescape-color": {
        "title": "Old School RuneScape",
        "alt": "Old School RuneScape icon"
      },
      "runescape-color": {
        "title": "RuneScape",
        "alt": "RuneScape icon"
      }
    },
    "pegi-icons": {
      "pegi-age-03": {
        "title": "PEGI 3 icon",
        "alt": "PEGI 3 icon"
      },
      "pegi-age-07": {
        "title": "PEGI 7 icon",
        "alt": "PEGI 7 icon"
      },
      "pegi-age-12": {
        "title": "PEGI 12 icon",
        "alt": "PEGI 12 icon"
      },
      "pegi-age-16": {
        "title": "PEGI 16 icon",
        "alt": "PEGI 16 icon"
      },
      "pegi-age-18": {
        "title": "PEGI 18 icon",
        "alt": "PEGI 18 icon"
      },
      "pegi-content-discrimination": {
        "title": "PEGI content discrimination icon",
        "alt": "PEGI content discrimination icon"
      },
      "pegi-content-drugs": {
        "title": "PEGI content drugs icon",
        "alt": "PEGI content drugs icon"
      },
      "pegi-content-fear": {
        "title": "PEGI content fear icon",
        "alt": "PEGI content fear icon"
      },
      "pegi-content-gambling": {
        "title": "PEGI content gambling icon",
        "alt": "PEGI content gambling icon"
      },
      "pegi-content-language": {
        "title": "PEGI content language icon",
        "alt": "PEGI content language icon"
      },
      "pegi-content-purchases": {
        "title": "PEGI content purchases icon",
        "alt": "PEGI content purchases icon"
      },
      "pegi-content-sex": {
        "title": "PEGI content sex icon",
        "alt": "PEGI content sex icon"
      },
      "pegi-content-violence": {
        "title": "PEGI content violence icon",
        "alt": "PEGI content violence icon"
      }
    }
  }
}
