{
  "footer": {
    "contact": "Nous contacter",
    "gender": "Rapport sur l'écart salarial entre les genres",
    "legal": "Documents juridiques",
    "do-not-sell": {
      "text": "Ne pas vendre ou partager mes informations personnelles",
      "title": "Jagex ne vend pas d'informations personnelles au sens où on l'entend généralement. Nous autorisons les prestataires de services à utiliser vos informations personnelles à des fins de publicité, de marketing et d'analyse. Veuillez cliquer sur ce lien pour obtenir plus d'informations sur comment retirer votre consentement dans notre Charte de confidentialité."
    },
    "terms": "Conditions d'utilisation",
    "privacy": "Charte de confidentialité",
    "cookie": "Préférences relatives aux cookies",
    "copyright": "Copyright © 1999 - {{year}} Jagex Ltd. 220 Science Park, Cambridge, CB4 0WA, Royaume-Uni",
    "logo-nav": "Sites Web d'entreprises",
    "logo-title": "Jagex corporate website",
    "contact-title": "Contact Jagex",
    "gender-title": "Gender Pay Gap Report",
    "legal-title": "View Jagex's legal information",
    "terms-title": "View Jagex's terms & conditions",
    "privacy-title": "View Jagex's privacy policy",
    "cookie-title": "View Jagex's cookie policy"
  }
}
