import EpicStoreFlatLogo from "./assets/epic-store-flat.svg";
import FlyingWildHogFlatLogo from "./assets/flying-wild-hog-flat.svg";
import GamepiresFlatLogo from "./assets/gamepires-flat.svg";
import JagexFullFlatLogo from "./assets/jagex-full-flat.svg";
import MelvorIdleColorLogo from "./assets/melvor-idle-color.png";
import OldSchoolRuneScapeColorLogo from "./assets/old-school-runescape-full-color.png";
import OldSchoolRuneScapeFlatLogo from "./assets/old-school-runescape-full-flat.svg";
import RuneScapeColorLogo from "./assets/runescape-full-color.png";
import RuneScapeFlatLogo from "./assets/runescape-full-flat.svg";

type FlatLogoType =
  | "epic-store-flat"
  | "flying-wild-hog-flat"
  | "gamepires-flat"
  | "jagex-full-flat"
  | "old-school-runescape-flat"
  | "runescape-flat";

type ColorLogoType = "old-school-runescape-color" | "runescape-color" | "melvor-idle-color";

type LogoInfo = {
  name: string;
  src: string;
  title: string;
  alt: string;
};

type Logo<T extends string> = Record<T, LogoInfo>;

export const flatLogos: Logo<FlatLogoType> = {
  "epic-store-flat": {
    name: "Epic Store Flat",
    src: EpicStoreFlatLogo,
    title: "logos.flat-logos.epic-store-flat.title",
    alt: "logos.flat-logos.epic-store-flat.alt",
  },
  "flying-wild-hog-flat": {
    name: "Flying Wild Hog Flat",
    src: FlyingWildHogFlatLogo,
    title: "logos.flat-logos.flying-wild-hog-flat.title",
    alt: "logos.flat-logos.flying-wild-hog-flat.alt",
  },
  "gamepires-flat": {
    name: "Gamepires Flat",
    src: GamepiresFlatLogo,
    title: "logos.flat-logos.gamepires-flat.title",
    alt: "logos.flat-logos.gamepires-flat.alt",
  },
  "jagex-full-flat": {
    name: "Jagex Full Flat",
    src: JagexFullFlatLogo,
    title: "logos.flat-logos.jagex-full-flat.title",
    alt: "logos.flat-logos.jagex-full-flat.alt",
  },
  "old-school-runescape-flat": {
    name: "Old School RuneScape Flat",
    src: OldSchoolRuneScapeFlatLogo,
    title: "logos.flat-logos.old-school-runescape-flat.title",
    alt: "logos.flat-logos.old-school-runescape-flat.alt",
  },
  "runescape-flat": {
    name: "RuneScape Flat",
    src: RuneScapeFlatLogo,
    title: "logos.flat-logos.runescape-flat.title",
    alt: "logos.flat-logos.runescape-flat.alt",
  },
};

export const colorLogos: Logo<ColorLogoType> = {
  "old-school-runescape-color": {
    name: "Old School RuneScape Color",
    src: OldSchoolRuneScapeColorLogo,
    title: "logos.color-logos.old-school-runescape-color.title",
    alt: "logos.color-logos.old-school-runescape-color.alt",
  },
  "runescape-color": {
    name: "RuneScape Color",
    src: RuneScapeColorLogo,
    title: "logos.color-logos.runescape-color.title",
    alt: "logos.color-logos.runescape-color.alt",
  },
  "melvor-idle-color": {
    name: "Melvor Idle Color",
    src: MelvorIdleColorLogo,
    title: "logos.color-logos.melvor-idle-color.title",
    alt: "logos.color-logos.melvor-idle-color.alt",
  },
};

export const logos: Logo<FlatLogoType | ColorLogoType> = { ...flatLogos, ...colorLogos };
