{
  "logos" : {
    "flat-logos": {
      "epic-store-flat": {
        "title": "Epic Store",
        "alt": "Logo vom Epic Store"
      },
      "flying-wild-hog-flat": {
        "title": "Flying Wild Hog",
        "alt": "Logo von Flying Wild Hog"
      },
      "gamepires-flat": {
        "title": "Gamepires",
        "alt": "Logo von Gamepires"
      },
      "jagex-full-flat": {
        "title": "Jagex Games Ltd",
        "alt": "Ein weißes X-Logo, gefolgt von dem Namen Jagex"
      },
      "old-school-runescape-flat": {
        "title": "Old School RuneScape",
        "alt": "Logo von Old School RuneScape"
      },
      "runescape-flat": {
        "title": "RuneScape",
        "alt": "Logo von RuneScape"
      }
    },
    "color-logos": {
      "old-school-runescape-color": {
        "title": "Old School RuneScape",
        "alt": "Logo von Old School RuneScape"
      },
      "runescape-color": {
        "title": "RuneScape",
        "alt": "Logo von RuneScape"
      },
      "melvor-idle-color": {
        "title": "Melvor Idle",
        "alt": "Logo von Melvor Idle"
      }
    }
  }
}
