"use client";

import styled from "@emotion/styled";

import { Menu as MenuComponent } from "../../../molecules/Menu";

export const Menu = styled(MenuComponent)`
  border-radius: 0;
  padding: 0;
`;
