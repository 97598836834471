"use client";

import styled from "@emotion/styled";
import { rem } from "polished";

import { Icon } from "../../atoms/Icon";
import { Link, LinkExternal } from "../../atoms/Link";
import { colors } from "../../design-tokens/colors";
import { device, globalContentMaxWidth, sizes } from "../../design-tokens/dimensions";
import { typography } from "../../design-tokens/typography";

export const Root = styled.footer`
  background-color: ${colors.baseDark};
  border-top: ${sizes.s2.rem} solid ${colors.baseMid};
  padding: ${sizes.s48.rem} ${sizes.s16.rem};
  position: relative;
`;

export const Inner = styled.div`
  margin: 0 auto;
  max-width: ${rem(globalContentMaxWidth)};
`;

export const Logos = styled.nav<{ $inCenter: boolean }>`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  gap: ${sizes.s32.rem};
  justify-content: center;
  margin-bottom: ${sizes.s32.rem};
  width: 100%;

  @media (${device.large}) {
    justify-content: ${({ $inCenter }) => ($inCenter ? "center" : "flex-start")};
  }
`;

export const LogoWrapper = styled(Link)`
  display: block;
  max-width: ${rem(200)};
  text-align: center;
  width: auto;

  &:hover {
    filter: brightness(0.65);
  }
`;
LogoWrapper.displayName = "LogoWrapper";

export const PEGIWrapper = styled(LinkExternal)`
  align-items: flex-start;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: ${sizes.s24.rem} 0;
  gap: ${sizes.s32.rem};
`;

export const PEGIIcon = styled(Icon)<{ $isAgeIcon?: boolean }>`
  height: ${({ $isAgeIcon }) => ($isAgeIcon ? sizes.s96.rem : sizes.s80.rem)};
  width: ${sizes.s80.rem};

  &:hover {
    filter: brightness(1.3);
  }
`;

export const Content = styled.div`
  @media (${device.large}) {
    align-items: flex-end;
    display: flex;
    justify-content: space-between;
  }
`;

export const Links = styled.nav`
  margin: 0 auto ${sizes.s48.rem};
  max-width: ${rem(520)};

  @media (${device.large}) {
    margin: 0;
  }
`;

export const LinksRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${sizes.s12.rem} ${sizes.s24.rem};
  justify-content: center;
  margin-bottom: ${sizes.s36.rem};

  &:last-child {
    margin-bottom: 0;
  }

  @media (${device.large}) {
    gap: ${sizes.s8.rem} ${sizes.s16.rem};
    justify-content: flex-start;
    margin-bottom: ${sizes.s8.rem};
  }
`;

export const FooterLink = styled(Link)`
  color: ${colors.white};
  display: flex;
  font-size: ${typography.sizes["16"]};
  letter-spacing: ${typography.letterSpacing["2"]};
  line-height: ${typography.lineHeights["24"]};
  text-align: center;

  &::after {
    content: "|";
    transform: translateX(100%);
  }

  &:last-child::after {
    display: none;
  }

  @media (${device.large}) {
    text-align: left;
  }
`;
FooterLink.displayName = "FooterLink";

export const Copyright = styled.address`
  font-size: ${typography.sizes["16"]};
  font-style: normal;
  line-height: ${typography.lineHeights["20"]};
  overflow: hidden;
  text-align: center;

  @media (${device.large}) {
    white-space: nowrap;
  }
`;
Copyright.displayName = "Copyright";
