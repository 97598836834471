"use client";

import styled from "@emotion/styled";
import { rem, transparentize } from "polished";

import { Icon } from "../../../atoms/Icon";
import { colors } from "../../../design-tokens/colors";
import { sizes } from "../../../design-tokens/dimensions";
import { borderRadii } from "../../../design-tokens/effects";
import { typography } from "../../../design-tokens/typography";

export const Root = styled.div`
  border-radius: ${borderRadii.r4};
  border: 1px ${transparentize(0.94, colors.white)} solid;
  display: flex;
  gap: ${sizes.s16.rem};
  padding: ${sizes.s12.rem};
  width: 100%;
`;

export const PegiIcon = styled(Icon)`
  height: ${rem(56)};
  width: ${rem(46)};
`;

export const PegiDescriptionBlock = styled.div`
  text-align: left;
`;

export const PegiTitle = styled.div`
  font-size: ${typography.sizes["16"]};
  font-weight: ${typography.weights.medium};
  line-height: ${typography.lineHeights["20"]};
`;

export const PegiDescription = styled.div`
  font-size: ${typography.sizes["14"]};
  font-weight: ${typography.weights.normal};
  line-height: ${typography.lineHeights["20"]};
`;
