"use client";

import styled from "@emotion/styled";
import { rem } from "polished";

import { Icon as IconComponent } from "../../../atoms/Icon";
import { Logo as LogoComponent } from "../../../atoms/Logo";
import { device } from "../../../design-tokens/dimensions";
import { mobileNavBreakpoint } from "../styles";

export const StyledJagexLogo = styled(LogoComponent)`
  display: none;

  @media (${device[mobileNavBreakpoint]}) {
    display: block;
  }
`;

export const StyledJagexIcon = styled(IconComponent)`
  height: ${rem(35)};
  width: ${rem(35)};

  @media (${device[mobileNavBreakpoint]}) {
    display: none;
  }
`;
