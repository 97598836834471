"use client";

import styled from "@emotion/styled";

import { Button } from "../../atoms/Button";
import { Icon } from "../../atoms/Icon";
import { colors } from "../../design-tokens/colors";
import { device, sizes } from "../../design-tokens/dimensions";
import { borderRadii, boxShadows } from "../../design-tokens/effects";
import { typography } from "../../design-tokens/typography";
import type { AlertLevel, AlertSize } from "./Alert";

const alertBackground: Record<AlertLevel, string> = {
  error: colors.red,
  success: colors.greenLight,
  info: colors.blue,
};

const alertColor: Record<AlertLevel, string> = {
  error: colors.baseBlack,
  success: colors.baseBlack,
  info: colors.white,
};

export const Root = styled.div<{
  entitySize: AlertSize;
  level: AlertLevel;
}>`
  --mask-icon-color: ${({ level }) => alertColor[level]};

  align-items: flex-start;
  background-color: ${({ level }) => alertBackground[level]};
  box-shadow: ${boxShadows.frame};
  color: ${({ level }) => alertColor[level]};
  display: flex;
  gap: ${sizes.s16.rem};
  padding: ${({ entitySize }) =>
    entitySize === "m" ? `${sizes.s12.rem} ${sizes.s16.rem}` : `${sizes.s4.rem} ${sizes.s16.rem}`};
  width: 100%;

  @media (${device.xsmall}) {
    border-radius: ${({ entitySize }) => (entitySize === "m" ? borderRadii.r8 : 0)};
  }

  a {
    color: ${({ level }) => alertColor[level]};
    font-size: inherit;
    font-weight: ${typography.weights.bold};

    &:hover {
      text-decoration: underline;
    }
  }
`;

export const StatusIcon = styled(Icon)`
  margin-top: ${sizes.s12.rem};
`;

// By default, Button sets its own icon colour in dependence of the button variant. Setting --mask-icon-color to inherit
// makes it inherit colour set in the Root element.
export const CloseButton = styled(Button)`
  --mask-icon-color: inherit;

  margin-left: auto;
  margin-top: ${sizes.s16.rem};
`;
