import { HTMLAttributes, ReactNode } from "react";

import { useTranslate } from "../../../utils/translate";
import { Alert } from "../Alert";
import { ConsentModal } from "./ConsentModal";
import { Frame, Root } from "./styles";
import { translations } from "./translations";

/**
 * iFrame component with generic cookie confirmation.
 */
export type IFrameProps = HTMLAttributes<HTMLDivElement> & {
  /**
   * Source for the IFrame
   */
  src: string;
  /**
   * Name of the frame for accessibility purposes
   */
  title: string;
  /**
   * Props for the IFrame
   */
  iFrameProps?: Omit<HTMLAttributes<HTMLIFrameElement>, "src" | "title">;
  /**
   * Consent confirmation status
   */
  consentConfirmed: boolean;
  /**
   * Consent message modal
   */
  consentModal?: ReactNode;
};

/**
 * Displays an iFrame that will only show the content once the user has given the applicable level of consent
 */
export function IFrame({
  src,
  title,
  iFrameProps,
  consentConfirmed,
  consentModal,
  ...rest
}: IFrameProps) {
  const t = useTranslate(translations);

  // Do not load the frame if the src starts with http
  if (src.startsWith("http://")) {
    return (
      <Root data-testid={IFrame.name} {...rest}>
        <Alert>{t("iFrame.insecure")}</Alert>
      </Root>
    );
  }

  const sourceToRender = consentConfirmed ? src : "";

  return (
    <Root data-testid={IFrame.name} {...rest}>
      <Frame src={sourceToRender} title={title} allowFullScreen={true} {...iFrameProps} />
      {!consentConfirmed && consentModal}
    </Root>
  );
}

IFrame.DefaultConsentModal = ConsentModal;
