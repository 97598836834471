import type { CSSProperties } from "react";

/**
 * z-index values
 */
export const zIndex: Record<string, CSSProperties["zIndex"]> = {
  iframe: 3,
  header: 1100,
  tooltip: 1500,
  modal: 1300,
};
