"use client";

import styled from "@emotion/styled";

import { borderRadii } from "../../design-tokens/effects";
import type { CardOrientation } from "./Card";

export const Root = styled.div<{
  orientation: CardOrientation;
}>`
  border-radius: ${borderRadii.r8};
  display: flex;
  flex-direction: ${({ orientation }) => (orientation === "vertical" ? "column" : "row")};
  overflow: hidden;
`;
