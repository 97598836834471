import type { HTMLAttributes } from "react";

import { Root } from "./styles";

/**
 * A box that can be used to hold content or a dialog.
 */
export function ContentFrame({ children, ...rest }: HTMLAttributes<HTMLDivElement>): JSX.Element {
  return (
    <Root data-testid={ContentFrame.name} {...rest}>
      {children}
    </Root>
  );
}
