"use client";

import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { rem } from "polished";

import { lineClamp } from "../../../utils/line-clamp";
import { colors } from "../../design-tokens/colors";
import { device, globalTextMaxWidth, sizes } from "../../design-tokens/dimensions";
import { FontStyles, typography } from "../../design-tokens/typography";
import type { ParagraphProps, ParagraphSize } from "./Paragraph";

const defaultColor = colors.white;

export const paragraphStyles: Record<ParagraphSize, FontStyles> = {
  m: {
    fontSize: typography.sizes[16],
    fontWeight: typography.weights.normal,
    letterSpacing: typography.letterSpacing["2"],
    lineHeight: typography.lineHeights[24],
  },
  l: {
    fontSize: typography.sizes[18],
    fontWeight: typography.weights.normal,
    letterSpacing: typography.letterSpacing["2"],
    lineHeight: typography.lineHeights[24],
  },
};

export const getFontSizeStyles = (entitySize: ParagraphSize = "m") => `
  font-size: ${entitySize === "m" ? typography.sizes[16] : typography.sizes[18]};
`;

export const getParagraphStyle = (props: ParagraphProps) => {
  return css`
    color: ${props.color ?? defaultColor};
    font-family: ${typography.families.body};
    font-size: ${paragraphStyles["m"].fontSize};
    font-weight: ${paragraphStyles[props.entitySize || "m"].fontWeight};
    letter-spacing: ${paragraphStyles[props.entitySize || "m"].letterSpacing};
    ${lineClamp(`${paragraphStyles[props.entitySize || "m"].lineHeight}`, props.lines)};
    margin-bottom: ${sizes.s12.rem};
    margin-left: ${props.align === "left" ? "0" : "auto"};
    margin-right: ${props.align === "right" ? "0" : "auto"};
    margin-top: ${sizes.s12.rem};
    max-width: ${rem(globalTextMaxWidth)};
    text-align: ${props.align};

    @media (${device.large}) {
      font-size: ${paragraphStyles[props.entitySize || "m"].fontSize};
    }
  `;
};

export const Root = styled.p<ParagraphProps>`
  ${getParagraphStyle}
`;
