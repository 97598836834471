import { HTMLAttributes, useContext, useEffect, useRef } from "react";

import { Icon } from "../../../atoms/Icon";
import { allIcons } from "../../../design-tokens/icons";
import { TabNavContext } from "../context";
import { Root, TabSpan } from "./styles";

export type TabNavItemProps = {
  /**
   * Unique Id
   */
  id: string;
  /**
   * Icon to show at start of tab
   */
  icon?: keyof typeof allIcons;
} & HTMLAttributes<HTMLLIElement>;

export const TabNavItem = ({ children, icon, id, ...rest }: TabNavItemProps) => {
  const ref = useRef<HTMLLIElement>(null);
  const { activeTab, setActive, focusPrevious, focusNext, registerItem, unregisterItem } =
    useContext(TabNavContext)!;

  useEffect(() => {
    if (id === undefined) {
      return undefined;
    }

    registerItem(id, ref);

    return () => unregisterItem(id);
  }, [id, registerItem, unregisterItem]);

  const onKeyDown = (e: React.KeyboardEvent<HTMLLIElement>) => {
    switch (e.key) {
      case "Enter":
      case " ":
        setActive(id);
        break;
      case "ArrowLeft":
      case "ArrowUp":
        focusPrevious(id);
        break;
      case "ArrowRight":
      case "ArrowDown":
        focusNext(id);
        break;
    }
  };

  return (
    <Root
      role="tab"
      tabIndex={0}
      data-testid={TabNavItem.displayName}
      onClick={() => setActive(id)}
      onKeyDown={onKeyDown}
      aria-selected={id === activeTab}
      ref={ref}
      {...rest}
    >
      {icon && <Icon icon={icon} />}
      <TabSpan role="presentation">{children}</TabSpan>
    </Root>
  );
};

TabNavItem.displayName = "TabNavItem";
