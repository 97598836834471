"use client";

import styled from "@emotion/styled";

import { lineClamp } from "../../../../utils/line-clamp";
import { Icon } from "../../../atoms/Icon";
import { colors } from "../../../design-tokens/colors";
import { globalContentMaxWidth, sizes } from "../../../design-tokens/dimensions";
import { typography } from "../../../design-tokens/typography";

export const Root = styled.details`
  display: block;
  margin: 0 auto;
  max-width: ${globalContentMaxWidth};
  position: relative;
`;

export const Summary = styled.summary`
  align-items: center;
  background: ${colors.baseMid};
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  height: ${sizes.s80.rem};
  list-style: none;
  padding: ${sizes.s24.rem};
  width: 100%;

  &::-webkit-details-marker,
  &::marker {
    display: none;
  }
`;

export const PrefixIcon = styled(Icon)`
  margin-right: ${sizes.s24.rem};
`;

export const SummaryText = styled.span`
  color: ${colors.white};
  flex: 1;
  font-size: ${typography.sizes["18"]};
  letter-spacing: ${typography.letterSpacing["2"]};
  ${lineClamp(typography.lineHeights[28], 1)};
`;

export const Content = styled.div<{ isOpen: boolean }>`
  background: ${colors.baseDark};
  box-sizing: border-box;
  height: auto;
  overflow: hidden;
`;
export const ContentInner = styled.div`
  box-sizing: border-box;
  overflow: hidden;
  padding: ${sizes.s24.rem};
`;
