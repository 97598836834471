import type { HTMLAttributes } from "react";

import { Icon } from "../../../atoms/Icon";
import { colors } from "../../../design-tokens/colors";
import { sizes } from "../../../design-tokens/dimensions";
import { BoxJagexLogo, BoxLogos } from "../styles";

type LogoProps = {
  /**
   * Show the RS Franchise logos.
   */
  showFranchiseLogos?: boolean;
} & HTMLAttributes<HTMLDivElement>;

/**
 * Renders the logo for the {@link BoxFlow} interface
 */
export function Logo({ showFranchiseLogos = false }: LogoProps): JSX.Element {
  return (
    <BoxLogos data-testid={`BoxFlow${Logo.name}`}>
      {showFranchiseLogos && (
        <Icon icon="old-school-runescape-flat" color={colors.white} height={sizes.s32.rem} />
      )}
      <BoxJagexLogo icon="jagex-flat" color={colors.white} height={sizes.s48.rem} />
      {showFranchiseLogos && (
        <Icon icon="runescape-lite-flat" color={colors.white} height={sizes.s32.rem} />
      )}
    </BoxLogos>
  );
}
