"use client";

import styled from "@emotion/styled";

import { Link } from "../../../atoms/Link";
import { sizes } from "../../../design-tokens/dimensions";

export const Root = styled(Link)`
  width: ${sizes.s56.rem};
  height: ${sizes.s56.rem};
`;
