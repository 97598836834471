import { css } from "@emotion/react";
import { rem, rgba } from "polished";

import { colors } from "../colors";
import { sizes } from "../dimensions";

export const scrollbar = css`
  // Firefox doesn't support any scrollbar customization except colour and pre-defined width.
  scrollbar-color: ${colors.betaBase} transparent;
  scrollbar-width: thin;

  // Other browsers support the styles beneath.
  &::-webkit-scrollbar-thumb {
    background-color: ${rgba(colors.white, 0.2)};
    border-radius: ${sizes.s16.rem};

    border: ${sizes.s8.rem} solid transparent;
    background-clip: padding-box;

    :active,
    :hover {
      background-color: ${rgba(colors.white, 0.6)};
    }
  }

  &::-webkit-scrollbar {
    background-color: transparent;
    display: block;
  }

  &::-webkit-scrollbar:vertical {
    width: ${rem(20)};
  }

  &::-webkit-scrollbar:horizontal {
    height: ${rem(20)};
  }
`;
