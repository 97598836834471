"use client";

import styled from "@emotion/styled";
import { rem } from "polished";

import { colors } from "../../../design-tokens/colors";
import { sizes } from "../../../design-tokens/dimensions";

export const Root = styled.div<{ fullHeight: boolean }>`
  align-items: flex-start;
  background: ${colors.baseMid};
  display: flex;
  flex-direction: column;
  gap: ${sizes.s8.rem};
  padding: ${sizes.s16.rem};
  min-height: ${({ fullHeight }) => (fullHeight ? "100%" : rem(80))};
  min-width: ${rem(80)};
`;
