import { HTMLAttributes } from "react";

import { useTranslate } from "../../../../utils/translate";
import { Button } from "../../../atoms/Button";
import { Heading } from "../../../atoms/Heading";
import { Icon } from "../../../atoms/Icon";
import { allIcons } from "../../../design-tokens/icons";
import { translations } from "../translations";
import { ModalParagraph, Root } from "./styles";
/**
 * default iFrame consent modal
 */
type ConsentModalProps = HTMLAttributes<HTMLDivElement> & {
  /**
   * Icon to show at top of content
   */
  icon?: keyof typeof allIcons;
  /**
   * Text for the title
   */
  title?: string;
  /**
   * Text for the body
   */
  body?: string;
  /**
   * Button text
   */
  buttonText?: string;
  /**
   * Button action
   */
  buttonAction: () => void;
};
/**
 * Displays a default Consent Component for the IFrame Component
 */
export function ConsentModal({
  body,
  buttonText,
  buttonAction,
  icon = "cookie",
  title,
  ...rest
}: ConsentModalProps) {
  const t = useTranslate(translations);

  return (
    <Root data-testid={ConsentModal.name} {...rest}>
      <Icon icon={icon} height="64px" />
      {title && (
        <Heading as="h3" level="h3">
          {title}
        </Heading>
      )}
      <ModalParagraph>{body ?? t("iFrame.consentModal.body")}</ModalParagraph>
      <Button title={t("iFrame.consentModal.buttonText")} onClick={buttonAction}>
        {buttonText ?? t("iFrame.consentModal.buttonText")}
      </Button>
    </Root>
  );
}
