import { MouseEvent, ReactNode, useCallback, useState } from "react";

import { Icon } from "../../../atoms/Icon";
import type { MenuProps } from "../../../molecules/Menu";
import { Button } from "../Button/Button";
import { HorizontalItem } from "../Horizontal/HorizontalItem";
import { Menu } from "./styles";

export type DropdownProps = {
  /**
   * Define dropdown button contents
   */
  button: ReactNode;
  /**
   * The `Header.DropdownItem(s)` to pass to display
   */
  children: MenuProps["children"];
} & Pick<MenuProps, "id">;

/**
 * Header.Dropdown - defines a submenu that hosts DropdownItem(s). Only works in a Header.Horizontal.
 */
export const Dropdown = ({ id, button, children }: DropdownProps) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const open = Boolean(anchorEl);

  const handlePopoverOpen = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      if (!open) {
        setAnchorEl(event.currentTarget);
      }
    },
    [open]
  );

  const handlePopoverClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  return (
    <HorizontalItem onMouseLeave={handlePopoverClose}>
      <Button
        after={<Icon icon="arrow-down" />}
        onMouseEnter={handlePopoverOpen}
        onClick={handlePopoverOpen}
        aria-controls={open ? id : undefined}
        aria-expanded={open || undefined}
        aria-haspopup="menu"
      >
        {button}
      </Button>
      <Menu
        id={id}
        open={open}
        anchorEl={anchorEl}
        setClose={handlePopoverClose}
        floatingOffset={0}
        width={anchorEl?.offsetWidth}
      >
        {children}
      </Menu>
    </HorizontalItem>
  );
};
