import type { HTMLAttributes } from "react";

import { Content, Root } from "./styles";

/**
 * Multiplex.Main - container for a main content.
 */
export function Main({ children, ...rest }: HTMLAttributes<HTMLDivElement>): JSX.Element {
  return (
    <Root data-testid={`Multiplex${Main.name}`} {...rest}>
      <Content>{children}</Content>
    </Root>
  );
}
