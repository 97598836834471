{
  "footer": {
    "contact": "Contact Us",
    "gender": "Gender Pay Gap Report",
    "legal": "Legal",
    "do-not-sell": {
      "text": "Do Not Sell or Share My Personal Information",
      "title": "Jagex does not sell personal information as the term 'sell' is commonly understood. We do allow service providers to use your personal information for advertising, marketing, and analytics. Please click this link for more information on how to opt out in our Privacy Policy."
    },
    "terms": "Terms & Conditions",
    "privacy": "Privacy Policy",
    "cookie": "Cookie Preferences",
    "copyright": "Copyright © 1999 - {{year}} Jagex Ltd. 220 Science Park, Cambridge, CB4 0WA, UK",
    "logo-nav": "Company websites",
    "logo-title": "Jagex corporate website",
    "contact-title": "Contact Jagex",
    "gender-title": "Gender Pay Gap Report",
    "legal-title": "View Jagex's legal information",
    "terms-title": "View Jagex's terms & conditions",
    "privacy-title": "View Jagex's privacy policy",
    "cookie-title": "View Jagex's cookie policy"
  }
}
