"use client";

import styled from "@emotion/styled";
import { rem } from "polished";
import { CSSProperties } from "react";

import { Container } from "../../atoms/Container";
import { colors } from "../../design-tokens/colors";
import { device, globalDecorationMaxWidth, sizes } from "../../design-tokens/dimensions";

export const Root = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  max-width: ${rem(globalDecorationMaxWidth)};
  min-height: ${sizes.s512.rem};
  overflow: hidden;
  padding: ${sizes.s48.rem} 0;
  position: relative;

  @media (${device.wide}) {
    &::before,
    &::after {
      background: linear-gradient(to right, ${colors.baseDark}, transparent);
      content: "";
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: ${sizes.s80.rem};
      z-index: 1;
    }

    &::after {
      left: auto;
      right: 0;
      transform: scaleX(-1);
    }
  }
`;

export const Background = styled.div`
  bottom: 0;
  height: 100%;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
`;

export const ContentWrapper = styled(Container)<{
  verticalAlignment: CSSProperties["justifyContent"];
}>`
  display: flex;
  flex-direction: column;
  flex: 1 0 100%;
  justify-content: ${({ verticalAlignment }) => verticalAlignment};
  position: relative;
  text-align: center;
`;
