import type { CSSProperties, HTMLAttributes, ReactNode } from "react";

import { CopyBox } from "./CopyBox";
import { ImageBox } from "./ImageBox";
import { BackgroundContainer, contentPlateFlipPoint, PlateContainer, Root } from "./styles";

export type ContentPlateOrientation = "left" | "right";

type ContentPlateProps = {
  /**
   * Container for background graphics such as images and video.
   */
  backgroundAssets?: ReactNode;
  /**
   * Background base colour used to generate a gradient.
   */
  color?: CSSProperties["backgroundColor"];
  /**
   * Where the text is displayed in desktop view.
   */
  orientation?: ContentPlateOrientation;
  /**
   * The ContentPlate subcomponents to use. Whilst it is possible to drop any ReactNode in here we only support the use of ContentPlate subcomponents.
   */
  children: ReactNode;
} & HTMLAttributes<HTMLDivElement>;

/**
 * Small rounded rectangle element for labelling or tagging content.
 */
export function ContentPlate({
  backgroundAssets = null,
  color = "transparent",
  orientation = "left",
  children,
  ...rest
}: ContentPlateProps): JSX.Element {
  return (
    <Root data-testid={ContentPlate.name} color={color} orientation={orientation} {...rest}>
      {backgroundAssets && (
        <BackgroundContainer data-testid={`${ContentPlate.name}Background`}>
          {backgroundAssets}
        </BackgroundContainer>
      )}
      <PlateContainer orientation={orientation} data-testid={`${ContentPlate.name}Container`}>
        {children}
      </PlateContainer>
    </Root>
  );
}

/**
 * Composite component structure
 */
ContentPlate.CopyBox = CopyBox;
ContentPlate.ImageBox = ImageBox;
// This is to make it easier for consumers to adjust their images for when the plate flips to a horizontal view
ContentPlate.contentPlateFlipPoint = contentPlateFlipPoint;
