"use client";

import styled from "@emotion/styled";

import { sizes } from "../../../design-tokens/dimensions";
import { typography } from "../../../design-tokens/typography";

export const Root = styled.dl`
  display: flex;
  font-size: ${typography.sizes["14"]};
  gap: ${sizes.s24.rem};
  line-height: ${typography.lineHeights["20"]};
  text-align: left;
  width: 100%;
`;

export const DescriptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  gap: ${sizes.s8.rem};
`;

export const DescriptionKey = styled.dt`
  font-weight: ${typography.weights.bold};
`;

export const DescriptionValue = styled.dd`
  margin: 0;
`;
