"use client";

import styled from "@emotion/styled";
import { rem } from "polished";

import { lineClamp } from "../../../../utils/line-clamp";
import { colors } from "../../../design-tokens/colors";
import { sizes } from "../../../design-tokens/dimensions";
import { typography } from "../../../design-tokens/typography";

export const Root = styled.li`
  --mask-icon-size: ${sizes.s16.rem};

  align-items: center;
  align-self: stretch;
  cursor: pointer;
  display: flex;
  gap: ${sizes.s8.rem};
  height: ${sizes.s48.rem};
  line-height: ${sizes.s16.rem};
  max-height: ${sizes.s48.rem};
  opacity: 0.5;
  padding: ${sizes.s16.rem};

  &:focus-visible {
    outline-offset: ${rem(-1)};
  }

  &[aria-selected="true"] {
    background: ${colors.blueDeep};
    border-bottom: 3px solid ${colors.blueLight};
    opacity: 1;
    padding-bottom: calc(${sizes.s16.rem} - ${rem(3)});
  }
`;

export const TabSpan = styled.span`
  ${lineClamp(typography.lineHeights[20], 1)};
`;
