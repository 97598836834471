"use client";

import styled from "@emotion/styled";
import { rem } from "polished";

import { colors } from "../../design-tokens/colors";
import { device, sizes } from "../../design-tokens/dimensions";
import { borderRadii, boxShadows } from "../../design-tokens/effects";

export const contentFrameMaxWidth = 520;

export const Root = styled.div`
  background: ${colors.baseDark};
  margin: ${sizes.s8.rem} auto;
  min-height: 100vh;
  overflow: hidden;
  padding: ${sizes.s24.rem} ${sizes.s24.rem} ${sizes.s32.rem};
  position: relative;
  width: 100%;
  word-break: break-word;

  @media (${device.xsmall}) {
    border-radius: ${borderRadii.r8};
    box-shadow: ${boxShadows.frame};
    min-height: initial;
    padding: ${sizes.s24.rem} ${sizes.s48.rem} ${sizes.s32.rem};
    width: ${rem(contentFrameMaxWidth)};
  }
`;
