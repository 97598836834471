"use client";

import styled from "@emotion/styled";

import { globalContentMaxWidth, sizes } from "../../design-tokens/dimensions";

export const Root = styled.div`
  margin: 0 auto;
  max-width: ${globalContentMaxWidth};
  position: relative;
  width: 100%;

  details {
    margin-bottom: ${sizes.s24.rem};

    &:last-child {
      margin-bottom: 0;
    }
  }
`;
