import type { HTMLAttributes } from "react";
import { forwardRef } from "react";

import { Root } from "./styles";

export const ButtonGroup = forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement>>(function (
  { children, ...rest },
  propRef
) {
  return (
    <Root data-testid={ButtonGroup.displayName} ref={propRef} role="group" {...rest}>
      {children}
    </Root>
  );
});

ButtonGroup.displayName = "ButtonGroup";
