"use client";

import styled from "@emotion/styled";

import { Container } from "../../atoms/Container";
import { sizes } from "../../design-tokens/dimensions";
import { zIndex } from "../../design-tokens/z-index";

export const Root = styled(Container)`
  aspect-ratio: 16 / 9;
  margin: 0 auto;
  max-width: ${sizes.globalContentMaxWidth};
  position: relative;
  width: 100%;
`;

export const Frame = styled.iframe`
  border: none;
  height: 100%;
  margin: 0 auto;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: ${zIndex.iframe};

  &[src=""] {
    height: 0;
    overflow: hidden;
    width: 0;
  }
`;
