import { Logo } from "../../../atoms/Logo";
import { LogoProps } from "../../../atoms/Logo/Logo";
import { LogoWrapper as Root } from "../styles";

type FooterLogoProps = {
  /**
   * URL the logo will link to
   */
  url: string;
  /**
   * HTML title for the link
   */
  title: string;
  /**
   * The logo to display
   */
  logo: LogoProps["logo"];
};
export function FooterLogo({ url, logo, ...rest }: FooterLogoProps) {
  return (
    <Root href={url} data-testid={FooterLogo.name} {...rest}>
      <Logo logo={logo} />
    </Root>
  );
}
