import type { HTMLAttributes } from "react";

import { Root } from "./styles";

type BrandProps = HTMLAttributes<HTMLDivElement>;

/**
 * Header.Brand - wrapper for brand content - Jagex logo
 */
export function Brand({ children, ...args }: BrandProps) {
  return (
    <Root data-testid={`Header${Brand.name}`} {...args}>
      {children}
    </Root>
  );
}
